
import React from 'react';
import PropTypes from 'prop-types';

import './Card.css';


const Card = (props) => {

    const { className, variation } = props;

    return (
        <div
            className={('card container ' + className + ' ' + (variation || '')).replace('  ', ' ').trim()}
            id={props.id}
        >
            {props.children}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    variation: PropTypes.string
};


export default Card;
