/* eslint-disable @typescript-eslint/no-unused-vars */
// Common Imports (used in multiple pages)
import React, { useEffect, useState, useRef } from 'react'; // React
import { useLocation, useNavigate } from 'react-router-dom'; // React Router

import i18n from 'locales/i18n';
import $ from 'jquery'; // JQuery

// React-i18next
import { useTranslation } from 'react-i18next';

// Reactour
import { useTour } from '@reactour/tour';

// Ant Design
import { Tooltip } from 'antd';

// React Grid
import RGL, { WidthProvider } from 'react-grid-layout';
const ReactGridLayout = WidthProvider(RGL);

// Base
import {
    Column, MessagePopup, Row, Layout, Loading, Page,
    Panel, SentimentIcon, Title, Link, SwitchableIcon, Label
} from 'components/imports';

// Lists
import { PositionsList } from 'components/imports';

// Page Components
import { AtsOverviewPanel } from 'components/imports';
import ArticlesPanel from './components/ArticlesPanel';
import TradesPanel from './components/TradesPanel';
import TopListPanel from './components/TopListPanel';

// Icons
import { buildCurrencyStorageUrl, InfoSvg, RefreshSvg, ShowSvg, HideSvg } from 'icons/imports'; // Helpers

// API Endpoints
import { trendsScoresGet, balancesMeGet } from 'apis/imports';

// Store 
import useGlobalStore from 'stores/GlobalStore';
import useOverviewStore from './stores/OverviewStore';

// Utils
import { getAssetBase } from 'utils/assets-bases';

import { getTrendsMarketcaps } from 'utils/trends-marketcaps';
import { currencyFormatter } from 'utils/formatters';
import { setGridLayouts } from 'utils/grid-layout';

// Hooks
import usePriceWatcher from 'hooks/usePriceWatcher';

// Constants
import { userServiceHost } from 'constants';

import Skeleton from 'react-loading-skeleton';


// Styles
import 'react-loading-skeleton/dist/skeleton.css';
import './Overview.css';


const Overview = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'overview';

    const layoutRef = useRef(null);

    const {
        savedLayouts,
        isUserAuthenticated,
        setIsUserAuthenticated,
        appLang,
        appTheme,
        appSelectedExchange,
        setAppSelectedExchange,
        appSelectedCurrency,
        setAppSelectedCurrency,
        appSelectedTimeframe,
        setAppSelectedTimeframe,
        appTop100Filter,
        setAppTop100Filter,
        changingFilters,
        setChangingFilters,
        userExchanges,
        setUserExchanges,
        tickers,
        setTickers,
    } = useGlobalStore();

    const {
        layout,
        setLayout,
        loadingAssets,
        setLoadingAssets,
        userAssets,
        setUserAssets,
        userPositionsAux,
        setUserPositionsAux,
        portfolioPositions,
        setPortfolioPositions,
        hideSmallPositions,
        tickerTapeCoins,
        setTickerTapeCoins,
        topGainers,
        seTopGainers,
        topLoosers,
        setTopLoosers,
        topScores,
        setTopScores,
        loadingTickers,
        setLoadingTickers,
        marketSentiment,
        setMarketSentiment
    } = useOverviewStore();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setIsOpen, setSteps, setCurrentStep } = useTour();

    const [popUpDuration,] = useState(3000);
    const [popUpLevel,] = useState('warning');
    const [popUpText,] = useState('-');

    const [showWallet, setShowWallet] = useState(true);
    const [showWalletInfo, setShowWalletInfo] = useState(false);

    const [loadingTopScores, setLoadingTopScores] = useState(true);

    const [showMarketSentimentInfo, setShowMarketSentimentInfo] = useState(false);

    const hideIcon = <HideSvg className='icon-svg' />;
    const infoIcon = <InfoSvg className='icon-svg' />;
    const showIcon = <ShowSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingAssets ? ' spinning' : '')} />;


    usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, appTop100Filter, setTickers);

    // useEffect(() => {
    //     const eventSource = new EventSource(userServiceHost + '/api/v1/exchanges/tickers/sse?exchange_slugs=kucoin&timeframe=24h');

    //     eventSource.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         if ('error' in data) return;
    //         setTickers(data);
    //     };

    //     eventSource.onerror = (err) => eventSource.close();

    //     return () => eventSource.close();
    // }, []);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('overview')} - Smart Trade`;
            window.dispatchEvent(new Event('resize'));

            getPortfolioPositions();
        }
    }, [location.pathname]);


    $('.reactour__close-button').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    $('.reactour__mask').on('click', function () {
        document.getElementById('toolbar-container').classList.remove('stepping');
    });


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        if (!changingFilters) setChangingFilters(true);
        getScores();
    }, [appTop100Filter, appSelectedExchange, appSelectedCurrency, appSelectedTimeframe]);


    useEffect(() => {

        if (!loadingTickers) setLoadingTickers(changingFilters);

    }, [changingFilters]);


    useEffect(() => {
        if (pageId in savedLayouts) setLayout(savedLayouts[pageId]);
    }, [savedLayouts]);


    useEffect(() => {
        if (topScores.length == 0 || tickerTapeCoins.length == 0) return;
        setLoadingTopScores(false);
    }, [topScores, tickerTapeCoins]);


    useEffect(() => {

        if (Object.keys(tickers).length === 0) return;

        setLoadingTickers(false);

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base, _] = pair.split('/');

            if (base.includes('USD')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;

        getTickerTopList(topGainers, seTopGainers, tickerList, 'pnl', 'desc', 10);
        getTickerTopList(topLoosers, setTopLoosers, tickerList, 'pnl', 'asc', 10);

        let exchangeTickers = tickers[appSelectedExchange];

        let updateTickerTapeCoins = tickerTapeCoins.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTickerTapeCoins(updateTickerTapeCoins);

        let updateTopScores = topScores.map((ticker) => {

            if (!(ticker.pair in exchangeTickers)) return ticker;

            ticker.price = exchangeTickers[ticker.pair].price;
            ticker.pnl = exchangeTickers[ticker.pair].pnls[appSelectedTimeframe][1];

            return ticker;
        });

        setTopScores(updateTopScores);
    }, [tickers]);


    useEffect(() => {
        /*
            Regras para determinar a tendência do mercado:
            - Se Houver 100% ou mais de criptos com PnL positivo a mais do que criptos com PnL negativo, a tendência do mercado é Bullish
            - Se Houver 50% ou mais de criptos com PnL negativo a mais do que criptos com PnL positivo, a tendência do mercado é Somewhat-Bullish
            - Se houver menos de 50% de criptos com PnL negativo a mais do que criptos com PnL positivo, a tendência do mercado é Neutral
            - Se houver 50% ou mais de criptos com PnL negativo a mais do que criptos com PnL positivo, a tendência do mercado é Somewhat-Bearish
            - Se Houver 100% ou mais de criptos com PnL negativo a mais do que criptos com PnL positivo, a tendência do mercado é Bearish
        */

        if (topGainers.lenght == 0 || topLoosers.length == 0) return;

        let positivePnls = 0;
        let negativePnls = 0;

        // Contar PnLs positivos e negativos
        topGainers.forEach((ticker) => {
            if (ticker.pnl > 0) positivePnls++;
            else negativePnls++;
        });

        topLoosers.forEach((ticker) => {
            if (ticker.pnl > 0) positivePnls++;
            else negativePnls++;
        });

        const difference = positivePnls - negativePnls;

        let marketTrend = 'Neutral';

        if (difference > negativePnls) marketTrend = 'Bullish';
        else if (difference > -negativePnls * 0.5) marketTrend = 'Somewhat-Bullish';
        else if (difference < -positivePnls * 0.5) marketTrend = 'Somewhat-Bearish';
        else if (difference < -positivePnls) marketTrend = 'Bearish';

        setMarketSentiment(marketTrend);

    }, [topGainers, topLoosers]);


    useEffect(() => {
        let positions = portfolioPositions;
        let positionsAux = { ...userPositionsAux };

        if (!positions || !positions.length || !tickers) return;

        positions.forEach((asset) => {
            const pair = `${asset.symbol}/${appSelectedCurrency}`;

            if (asset.symbol === appSelectedCurrency) asset.selExcLast = 1;

            else if (appSelectedExchange in tickers && pair in tickers[appSelectedExchange])
                asset.selExcLast = tickers[appSelectedExchange][pair].price;

            else asset.selExcLast = positionsAux[asset.symbol]?.selExcLast || 0;

            asset.perExchange = Object.entries(asset.perExchange).reduce(
                (acc, [exchange, values]) => {

                    let { total } = values;

                    acc[exchange] = {
                        balance: 0,
                        price: null,
                        pnl: null,
                        total
                    };

                    if (asset.symbol === appSelectedCurrency) {
                        acc[exchange].price = 1;
                        acc[exchange].balance = total;
                        acc[exchange].total = total;
                    } else if (exchange in tickers && pair in tickers[exchange]) {
                        let { price, pnls } = tickers[exchange][pair];
                        let balance = price * total;

                        let pnl = pnls[appSelectedTimeframe]?.[1];

                        acc[exchange].price = price;
                        acc[exchange].pnl = pnl;
                        acc[exchange].balance = balance;
                    }

                    return acc;
                }, {});

            asset.maxPnl = Math.max(
                ...Object.values(asset.perExchange).map((e) => e.pnl || 0)
            );

            positionsAux[asset.symbol] = asset;
        });

        setUserPositionsAux(positionsAux);

        let positionsList = Object.values(positionsAux);
        let totalBalances = { total: 0, perExchange: {} };

        positionsList = positionsList.filter(asset => asset.total > 0);

        positionsList.forEach(asset => {

            asset.balance = asset.selExcLast * asset.total;

            Object.entries(asset.perExchange).forEach(([exchange, values]) => {
                if (!(exchange in totalBalances.perExchange))
                    totalBalances.perExchange[exchange] = 0;
                totalBalances.perExchange[exchange] += values.balance;

                totalBalances.total += values.balance;
            });
        });

        positions = positionsList.sort((a, b) => b.balance - a.balance);

        let filteredPositions = positions;

        if (hideSmallPositions) filteredPositions = positions.filter((asset) => asset.balance >= 1);

        setUserAssets({
            totalBalances,
            positions,
            filteredPositions
        });

    }, [tickers, portfolioPositions]);


    useEffect(() => {

        const { positions } = userAssets;

        if (!positions) return;

        let filteredPositions;

        if (hideSmallPositions) filteredPositions = positions.filter((asset) => asset.balance >= 1);
        else filteredPositions = positions;

        setUserAssets({
            ...userAssets,
            filteredPositions
        });

    }, [hideSmallPositions]);


    const handleLayoutChange = (newLayout) => {
        setGridLayouts({ ...savedLayouts, ...{ [pageId]: newLayout } });
    };


    const getPortfolioPositions = async () => {

        setLoadingAssets(true);

        const result = await balancesMeGet();

        if (!result.isSuccess) return;

        var values = result.response;

        const positions = values.map((value) => {
            const assetBase = getAssetBase({ symbol: value.symbol });
            return ({
                ...value,
                name: assetBase.name,
                icon: buildCurrencyStorageUrl(assetBase.slug)
            });
        });

        setPortfolioPositions(positions);
        setLoadingAssets(false);
    };


    const adaptPerExchangeBalance = (obj) => {
        let list = Object.entries(obj);
        list.sort((a, b) => b[1] - a[1]);
        return list;
    };


    const getTickerTopList = (getter, setter, list, sortBy, sortDirection, limit) => {
        let getterAux = [...getter];
        let listAux = [...list];

        if (changingFilters) getterAux = [];
        setChangingFilters(false);

        // Lógica para manter valores que por algum motivo não são retornados
        // em alguns casos durante o loop de atualização
        getterAux.forEach((item) => {
            let { base } = item;
            let found = listAux.find((item) => item.base === base);
            if (!found) {
                listAux.push(item);
            }
        });

        if (sortDirection === 'asc') listAux.sort((a, b) => a[sortBy] - b[sortBy]);
        else listAux.sort((a, b) => b[sortBy] - a[sortBy]);

        listAux = listAux.slice(0, limit);
        listAux = listAux.map((ticker) => {
            const { symbol } = ticker;

            const assetBase = getAssetBase({ symbol });

            return {
                icon: buildCurrencyStorageUrl(assetBase.slug),
                name: assetBase.name,
                ...ticker
            };
        });

        setter(listAux);
    };


    const getScores = async () => {

        const result = await trendsScoresGet({ exchange_slug: appSelectedExchange, timeframe: appSelectedTimeframe });

        if (result.isSuccess) {
            let scores = Object.entries(result.response.items).reduce((scores, [pair, score]) => {
                const symbol = pair.split('/')[0];

                if (symbol.includes('USD')) return scores;

                const base = getAssetBase({ symbol });

                let ticker = {
                    pair,
                    symbol,
                    name: base.name,
                    icon: buildCurrencyStorageUrl(base.slug),
                    price: 0,
                    pnl: 0,
                    score: score
                };

                scores.push(ticker);

                return scores;
            }, []);

            scores = scores.sort((a, b) => b.score - a.score);

            setTickerTapeCoins(scores);
            setTopScores(scores);
        }
    };


    return (
        <Page id={pageId} >
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setTop100Filter={setAppTop100Filter}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
                tickerTapeCoins={tickerTapeCoins.filter((ticker) => ticker.price > 0).slice(0, 10)}
            >
                <Row
                    className='main-row'
                    fill='all'
                >
                    <Column id='main-column' fill='all' scroll>
                        <ReactGridLayout
                            ref={layoutRef}
                            className='grid-layout fill-all'
                            layout={layout}
                            onLayoutChange={handleLayoutChange}
                            margin={[6, 6]}
                            rowHeight={26}
                            cols={15}
                        >
                            <div key='balances'>
                                <Panel id='balances'
                                    fill='all'
                                    g='0'
                                >
                                    <Row
                                        className='title'
                                        fill='width'
                                        j='between'
                                    >
                                        <Row
                                            a='center'
                                            fit='width'
                                            j='start'
                                        >
                                            <Title variation='tertiary' txt={t('balance.p')} />

                                            <Tooltip
                                                open={showWalletInfo}
                                                title={
                                                    <>
                                                        <label><b>{t('your-balance.p')}</b></label>

                                                        <p>{t('balances-info')}</p>
                                                    </>
                                                }
                                            >
                                                <Row id='icon-container' onClick={() => setShowWalletInfo(!showWalletInfo)}>
                                                    {infoIcon}
                                                </Row>
                                            </Tooltip>
                                        </Row>

                                        <Row
                                            a='center'
                                            fit='width'
                                            j='end'
                                        >
                                            <SwitchableIcon
                                                iconOn={showIcon}
                                                onToggle={() => setShowWallet(!showWallet)}
                                                iconOff={hideIcon}
                                                isOn={showWallet}
                                            />

                                            <SwitchableIcon
                                                id='refresh-balances'
                                                onToggle={getPortfolioPositions}
                                                staticIcon={refreshIcon}
                                            />
                                        </Row>
                                    </Row>

                                    <hr />

                                    <Row fill='width'>
                                        <p><b>{t('total')} </b></p>

                                        {loadingAssets ? (
                                            <Skeleton
                                                className='skeleton'
                                                height={'1.5rem'}
                                                width={'5rem'}
                                            />
                                        ) : <>
                                            <p className={'fill-width money-value pri ' + (showWallet ? 'occult' : '')}>
                                                ≈ <b>{currencyFormatter(userAssets?.totalBalances?.total || 0)}</b>
                                            </p>
                                            <p className='fill-width money-currency'>
                                                {appSelectedCurrency}
                                            </p>
                                        </>
                                        }
                                    </Row>

                                    <hr />

                                    {loadingAssets ? (
                                        <Column>
                                            <Row>
                                                <Skeleton
                                                    circle
                                                    className='skeleton'
                                                    height={'1.75rem'}
                                                    width={'1.75rem'}
                                                />

                                                <Skeleton
                                                    className='skeleton'
                                                    height={'1.5rem'}
                                                    width={'10rem'}
                                                />
                                            </Row>
                                        </Column>
                                    ) : (
                                        <Column
                                            scroll
                                            style={{ maxHeight: '10rem' }}
                                        >
                                            {userAssets?.totalBalances?.perExchange && (
                                                <>
                                                    {adaptPerExchangeBalance(userAssets.totalBalances.perExchange).map(
                                                        ([exchangeSlug, total], index) => {

                                                            let exchange = userExchanges.find(e => e.slug === exchangeSlug);

                                                            return (
                                                                <Row key={index}
                                                                    fill='width'
                                                                >
                                                                    {exchange ?
                                                                        (<Label
                                                                            value={{ exchange }}
                                                                            variation='exchange'
                                                                        />)
                                                                        : exchangeSlug
                                                                    }

                                                                    <p className={'fill-width money-value sec ' + (showWallet ? 'occult' : '')}>
                                                                        ≈ <b>{currencyFormatter(total)}</b>
                                                                    </p>

                                                                    <p className='fill-width money-currency'>
                                                                        {appSelectedCurrency}
                                                                    </p>
                                                                </Row>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ) || (<></>)}
                                        </Column>
                                    )}
                                </Panel>
                            </div>

                            <div key='positions'>
                                <Panel
                                    fill='all'
                                    id='positions'
                                    g='0'
                                >
                                    <Row
                                        fill='width'
                                    >
                                        <Column g='0'>
                                            <Title variation='tertiary' txt={t('position.p')} />

                                            <Link
                                                id='go-to-wallet-page'
                                                onClick={() => {
                                                    let result = confirm(t('confirm-redirect-to-wallet'));

                                                    if (!result) { return; }
                                                    navigate('/wallet');
                                                }}
                                                variation='primary redirect'
                                            >
                                                <p>{t('see-portfolio')}</p>
                                            </Link>
                                        </Column>

                                        {/* <Row>
                                            <Switch
                                                checked={hideSmallPositions}
                                                onChange={() => setHideSmallPositions(!hideSmallPositions)}
                                            />

                                            <p>{t('hide-small-positions').replace('{currency}', appSelectedCurrency)}</p>
                                        </Row> */}
                                    </Row>

                                    {loadingAssets || !isUserAuthenticated ? (
                                        <div id='balances-assets-skeleton'>
                                            <hr />

                                            {
                                                [0, 1, 2, 3, 4].map((value, index) => (
                                                    <Row
                                                        fill='width'
                                                        j='between'
                                                        key={'balances-assets-skeleton-' + index}
                                                    >
                                                        <Row>
                                                            <Skeleton
                                                                className='skeleton'
                                                                circle
                                                                height={'35px'}
                                                                style={{ marginBottom: '1rem' }}
                                                                width={'35px'}
                                                            />

                                                            <Skeleton
                                                                className='skeleton'
                                                                height={'35px'}
                                                                style={{ marginBottom: '1rem' }}
                                                                width={'5rem'}
                                                            />
                                                        </Row>

                                                        <Column
                                                            a='end'
                                                            g='0'
                                                        >
                                                            <Skeleton
                                                                className='skeleton'
                                                                height={'15px'}
                                                                width={'5rem'}
                                                            />

                                                            <Skeleton
                                                                className='skeleton'
                                                                height={'15px'}
                                                                style={{ marginBottom: '1rem' }}
                                                                width={'6rem'}
                                                            />
                                                        </Column>
                                                    </Row>
                                                ))
                                            }
                                        </div>
                                    ) : (!userAssets?.filteredPositions?.length
                                        ? <Column
                                            a='center'
                                            fill='width'
                                            h='24'
                                            j='center'
                                        >
                                            <p>{t('no-results')}</p>
                                        </Column>
                                        : <PositionsList id='assets-list'
                                            hideValues={showWallet}
                                            items={userAssets.filteredPositions}
                                            selectedCurrency={appSelectedCurrency}
                                            selectedTimeframe={appSelectedTimeframe}
                                        />
                                    )}
                                </Panel>
                            </div>

                            <div key='ats-overview'>
                                <AtsOverviewPanel
                                    appLang={appLang}
                                    isUserAuthenticated={isUserAuthenticated}
                                    pageId={pageId}
                                    showLink={true}
                                />
                            </div>

                            <div key='top-gainers'>
                                <TopListPanel
                                    id='top-gainers'
                                    isUserAuthenticated={isUserAuthenticated}
                                    list={topGainers}
                                    loadingTickers={loadingTickers}
                                    selectedExchange={appSelectedExchange}
                                    selectedTimeframe={appSelectedTimeframe}
                                    selectedCurrency={appSelectedCurrency}
                                    title='top-gainers'
                                />
                            </div>

                            <div key='top-loosers'>
                                <TopListPanel
                                    id='top-loosers'
                                    isUserAuthenticated={isUserAuthenticated}
                                    list={topLoosers}
                                    loadingTickers={loadingTickers}
                                    selectedExchange={appSelectedExchange}
                                    selectedTimeframe={appSelectedTimeframe}
                                    selectedCurrency={appSelectedCurrency}
                                    title='top-loosers'
                                />
                            </div>

                            <div key='top-bnx-score'>
                                <TopListPanel
                                    id='top-bnx-score'
                                    isUserAuthenticated={isUserAuthenticated}
                                    list={topScores}
                                    loadingTickers={loadingTopScores || loadingTickers}
                                    selectedExchange={appSelectedExchange}
                                    selectedTimeframe={appSelectedTimeframe}
                                    selectedCurrency={appSelectedCurrency}
                                    title='top-bnx-score'
                                />
                            </div>

                            <div key='trades-overview'>
                                <TradesPanel
                                    appLang={appLang}
                                    selectedExchange={appSelectedExchange}
                                    selectedTimeframe={appSelectedTimeframe}
                                    exchanges={userExchanges}
                                    isUserAuthenticated={isUserAuthenticated}
                                    pageId={pageId}
                                    tickers={tickers}
                                />
                            </div>

                            <div key='market-sentiment'>
                                <Panel
                                    id='market-sentiment-panel'
                                    fill='all'
                                    g='0'
                                >
                                    <Column g='0'>
                                        <Row>
                                            <Title variation='tertiary' txt={t('market-sentiment')} />

                                            <Tooltip
                                                open={showMarketSentimentInfo}
                                                title={
                                                    <p>{t('market-sentiment-info')}</p>
                                                }
                                            >
                                                <Row id='icon-container' onClick={() => setShowMarketSentimentInfo(!showMarketSentimentInfo)}>
                                                    {infoIcon}
                                                </Row>
                                            </Tooltip>
                                        </Row>

                                        <Link
                                            id='go-to-trends-page'
                                            onClick={() => {
                                                let result = confirm(t('confirm-redirect-to-trades'));

                                                if (!result) { return; }
                                                navigate('/trends');
                                            }}
                                            variation='primary redirect'
                                        >
                                            <p>{t('see-trends')}</p>
                                        </Link>
                                    </Column>

                                    <hr />

                                    <Column fill='width' a='center'>
                                        {loadingTickers && marketSentiment == null
                                            ? <>
                                                <SentimentIcon />

                                                <Skeleton width='10rem' height='1rem' />
                                            </>
                                            : <>
                                                <SentimentIcon variation={marketSentiment} fill='gradient' />

                                                <p><b>{t(marketSentiment?.toLowerCase())}</b></p>
                                            </>}
                                    </Column>
                                </Panel>
                            </div>

                            <div key='articles'>
                                <ArticlesPanel
                                    appLang={appLang}
                                    isUserAuthenticated={isUserAuthenticated}
                                    pageId={pageId}
                                />
                            </div>
                        </ReactGridLayout>
                    </Column>
                </Row>
            </Layout>
        </Page >
    );
};

export default Overview;
