import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Column, Row, Badge, Title, Button } from 'components/imports';
import { AssetsPill } from 'components/imports';

import { UserSvg, TrendUpSvg } from 'icons/imports';

import { scoreFormatter, percentageFormatter } from 'utils/formatters';

import './StrategyOverviewCard.css';


const StrategyOverviewCard = (props) => {

    const { t } = useTranslation();

    const { key, strategy, basic, setStrategiesModalMode, isSelected } = props;

    const { name, level, bases, quotes, timeframes, stats } = strategy;

    const userIcon = <UserSvg className='icon-svg' />;
    const trendUpIcon = <TrendUpSvg className='icon-svg trend-up-text' />;

    const copiers = stats?.copiers ?? '-';
    const diaryReturn = stats?.general?.avg?.diary_return ?? '-';


    return (
        <Column
            fill='width'
            className={'card strategy-overview' + (isSelected ? ' selected' : '')}
            key={key}
        >
            {isSelected && <p className='side-label'>{t('selected')}</p>}

            <Row
                fill='width'
                j='between'
            >
                <Title variation='tertiary' txt={name} />

                <Button
                    variation='tertiary'
                    onClick={() => setStrategiesModalMode('selected')}
                >
                    {t('detail.p')}
                </Button>
            </Row>

            <Row fill='width' j='between'>
                <Badge variation={level == 'aggressive' ? 'warning' : 'primary'}>
                    <p>{t(level)}</p>
                </Badge>

                <Row g='2'>
                    <Row>
                        {userIcon}

                        <Column g='0'>
                            <p className='emphasis'>{copiers !== '-' ? scoreFormatter(copiers) : copiers}</p>

                            <p style={{ fontSize: 'x-small' }}>{t('user.p')}</p>
                        </Column>
                    </Row>

                    <Row>
                        {trendUpIcon}

                        <Column g='0'>
                            <p className='emphasis'>{diaryReturn !== '-' ? percentageFormatter({ v: diaryReturn, multiply: true }) : diaryReturn}</p>

                            <p style={{ fontSize: 'x-small' }}>{t('avg-diary-return')}</p>
                        </Column>
                    </Row>
                </Row>
            </Row>

            {!basic &&
                <>
                    <hr />

                    <Row fill='width' j='between' g='1'>
                        <Column fill='width'>
                            <p>{t('base.p')}</p>

                            <AssetsPill symbols={bases} type='bases' />
                        </Column>

                        <Column fill='width'>
                            <p>{t('quote.p')}</p>

                            <AssetsPill symbols={quotes} type='quotes' />
                        </Column>

                        <Column fill='width'>
                            <p>{t('timeframe.p')}</p>

                            <Row
                                fill='width'
                                j='between'
                                flex='wrap'
                            >
                                {(timeframes ?? []).map((timeframe) => <Badge key={timeframe}><p>{timeframe}</p></Badge>)}
                            </Row>
                        </Column>
                    </Row>
                </>
            }
        </Column>
    );
};


StrategyOverviewCard.propTypes = {
    key: PropTypes.number,
    strategy: PropTypes.object.isRequired,
    basic: PropTypes.bool,
    setStrategiesModalMode: PropTypes.func,
    isSelected: PropTypes.bool
};


export default StrategyOverviewCard;
