import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Line } from '@antv/g2plot';

import moment from 'moment';


// eslint-disable-next-line react/prop-types
const LineChart = (props) => {

    const { t } = useTranslation();

    const {
        values,
        isLoading,
        isUserAuthenticated
    } = props;

    const chartRef = useRef(null);

    var linePlot;


    useEffect(() => {

        const renderPlot = () => {

            if (values && values.length > 0 &&
                !isLoading && isUserAuthenticated) {

                const lastData = values[values.length - 1];

                linePlot = new Line(chartRef.current, {
                    data: values,
                    xField: 'timestamp',
                    yField: 'value',
                    seriesField: 'type',
                    padding: [10, -75, 0, -100],
                    autoFit: true,
                    meta: {
                        value: {
                            alias: 'Value',
                            formatter: (val) => `${val}${lastData.percent ? '%' : ''}`,
                        },
                        timestamp: {
                            alias: 'Timestamp',
                            formatter: (val) => moment.unix(val).format('DD/MM'),
                        },
                    },
                    xAxis: {
                        label: null,
                        grid: null,
                        line: null,
                    },
                    yAxis: {
                        label: null,
                        grid: null,
                        min: Math.min(...values.map((d) => d.value)),
                        max: Math.max(...values.map((d) => d.value)),
                    },
                    tooltip: {
                        formatter: (datum) => {
                            return {
                                name: datum.type === 'target' ? t('target.s') : t('values'),
                                value: `${datum.value}${lastData.percent ? '%' : ''}`,
                            };
                        },
                    },
                    lineStyle: ({ type }) => {
                        return type === 'target'
                            ? { lineDash: [4, 4], lineWidth: 2 }
                            : { lineWidth: 2 };
                    },
                    annotations: [
                        {
                            type: 'text',
                            position: [lastData.timestamp, lastData.value],
                            content: `${lastData.value}${lastData.percent ? '%' : ''}`,
                            style: {
                                fill: '#858585',
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            },
                            offsetX: 25,
                        },
                    ],
                    legend: false,
                    interactions: [
                        {
                            type: 'marker-active',
                        },
                    ],
                });

                linePlot.render();

                linePlot.on('plot:mousemove', (evt) => {
                    const { x, y } = evt;
                    // Converte as coordenadas do mouse para os valores dos eixos
                    const tooltipData = linePlot.chart.getTooltipItems({ x, y });
                    if (tooltipData.length) {
                        const { data: pointData } = tooltipData[1];
                        props.onCrosshairMove(pointData.timestamp);
                    }
                });

                return () => linePlot.destroy();
            }
        };

        // Initial render
        renderPlot();

        // Resize listener
        const handleResize = () => {
            if (linePlot) linePlot.destroy();
            renderPlot();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup
            if (linePlot) linePlot.destroy();
            window.removeEventListener('resize', handleResize);
        };

    }, [values, isLoading, isUserAuthenticated]);


    return <div ref={chartRef} className='line-chart fill-width' style={{ height: '70%' }} />;
};


LineChart.propTypes = {
    values: PropTypes.array,
    isLoading: PropTypes.bool,
    isUserAuthenticated: PropTypes.bool,
    onCrosshairMove: PropTypes.func
};


export default LineChart;
