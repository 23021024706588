/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Column, Button, Title } from 'components/imports';

import ReadDoc from '../read-doc/ReadDoc';

import { ReactComponent as TermOfKnowledge } from 'term-of-knoledge.svg';

import './TermsPoliciesStep.css';

const TermsPoliciesStep = ({
    appLang,
    onContinue
}) => {

    const { t } = useTranslation();

    const [isTermsOfServiceAccepted, setIsTermsOfServiceAccepted] = useState(false);
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);


    useEffect(() => {
        console.log(isTermsOfServiceAccepted, isPrivacyPolicyAccepted);
    }, [isTermsOfServiceAccepted, isPrivacyPolicyAccepted]);


    return (
        <Column
            className='step-terms-policies'
            id='step-3-0'
            g='2'
            m='sec-ver'
            a='center'
            scroll
        >
            <Title
                txt={t('terms-and-policies')}
                variation='primary'
            />

            <Column fill='all' g='1'>
                <p>{t('terms-and-policies-description')}</p>

                <Column
                    fill='all'
                >
                    {<TermOfKnowledge
                        style={{
                            width: '100%', // Preenche toda a largura disponível
                            height: 'auto'
                        }}
                    />}
                </Column>
                
                <p className='side-label'>{t('terms-and-policies-role')}</p>

                <ReadDoc
                    label={t('terms-of-service.p')}
                    href={'https://www.we-bronx.io/docs/en-US/terms-of-service.pdf'}
                    isRead={isTermsOfServiceAccepted}
                    onOpenDoc={() => { setIsTermsOfServiceAccepted(true); }}
                />

                <ReadDoc
                    label={t('privacy-policy')}
                    href={'https://www.we-bronx.io/docs/en-US/privacy-policy.pdf'}
                    isRead={isPrivacyPolicyAccepted}
                    onOpenDoc={() => { setIsPrivacyPolicyAccepted(true); }}
                />
            </Column>

            <Button
                variation='primary'
                onClick={onContinue}
                className='continue-button w-12'
                state={!isTermsOfServiceAccepted || !isPrivacyPolicyAccepted ? 'disabled' : 'enabled'}
            >
                {t('continue')}
            </Button>
        </Column>
    );
};

TermsPoliciesStep.propTypes = {
    appLang: PropTypes.string,
    onContinue: PropTypes.func.isRequired
};

export default TermsPoliciesStep;