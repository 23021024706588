
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Column, Row } from 'components/imports';
import { ArticlePreviewCard } from 'components/imports';


const NewsArticles = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const className = props.className || '';


    const redirectToTrends = (id) => {
        localStorage.setItem('article', id);

        let result = confirm(t('ask-confirm-news-redirect'));

        if (!result) { return; }

        navigate('/news');
    };


    return (
        <Column
            className={('list scroll ' + className).trim()}
            fill='width'
            g='0'
            key={'list-news-articles-' + props.id}
            id={props.id}
        >
            {
                props.items.length > 0 ?
                    props.items.map((item, index) => {
                        let { fromNow, source, sourceIcon, timestamp, title, url, banner } = item;

                        return (
                            <Column
                                fill='width'
                                g='0'
                                id={'article-' + index}
                                key={'article-' + index}
                            >
                                <hr />

                                <ArticlePreviewCard
                                    banner={banner}
                                    date={timestamp}
                                    fromNow={fromNow}
                                    id={'article-card-' + index}
                                    key={index}
                                    lang={props.lang}
                                    onClick={() => props.onItemClick ? props.onItemClick(item.id) : redirectToTrends(item.id)}
                                    showAllTitle={true}
                                    source={source}
                                    sourceIcon={sourceIcon}
                                    title={title}
                                    url={url}
                                />
                            </Column>
                        );
                    }) :
                    <Row className='list-message' fill='width' j='center'>
                        <b>{t('no-results')}</b>
                    </Row>
            }
        </Column>
    );
};


NewsArticles.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.array,
    lang: PropTypes.string,
    onItemClick: PropTypes.func
};


export default NewsArticles;
