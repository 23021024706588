
import React from 'react';
import PropTypes from 'prop-types';

import { Column, Date, Row } from 'components/imports';

import './ArticlePreviewCard.css';


const ArticlePreviewCard = (props) => {

    const {
        banner,
        date,
        id,
        lang,
        onClick,
        showAllTitle,
        source,
        sourceIcon,
        title
    } = props;

    const maxLen = showAllTitle ? 500 : props.banner ? 50 : 75;
    const titleToShow = (title ?? '').length >= maxLen ? title.substring(0, maxLen - 3) + '...' : title;

    return (
        <div
            className='card container article-preview fill-width'
            id={id}
            onClick={onClick}
        >
            <Column fill='all'>
                <Row className='banner-container' fill='width'>
                    <img className='banner' src={banner} />
                </Row>

                <Row
                    a='center'
                    className='card-header'
                    fill='width'
                    j='between'
                >
                    <img
                        className="source-icon"
                        src={sourceIcon}
                        title={source}
                    />

                    <Date
                        timestamp={date}
                        lang={lang}
                        variation='dynamic'
                    />
                </Row>

                <Row fill='width'>
                    <h2 title={titleToShow}>{titleToShow}</h2>
                </Row>
            </Column>
        </div>
    );
};

ArticlePreviewCard.propTypes = {
    banner: PropTypes.string,
    date: PropTypes.number,
    id: PropTypes.string.isRequired,
    lang: PropTypes.string,
    onClick: PropTypes.func,
    showAllTitle: PropTypes.bool,
    source: PropTypes.string,
    sourceIcon: PropTypes.string,
    title: PropTypes.string
};


export default ArticlePreviewCard;
