import PropTypes from 'prop-types';
import $ from 'jquery';

import { appEnv, userService as users } from 'constants';
import { getAuthorization } from 'utils/cookies';


const atsModesPairsDelete = async (props) => {

    const { mode, pairId } = props;

    const method = 'delete';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/ats/me/modes/${mode}/pairs/${pairId}`;

    const auth = await getAuthorization();

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);

        return { isSuccess: true, response };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {
            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) {
                errorType = 'InternalServerError';
            }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};


atsModesPairsDelete.propTypes = {
    mode: PropTypes.string.isRequired,
    pairId: PropTypes.string.isRequired,
};


export { atsModesPairsDelete };
