import $ from 'jquery';
import Proptypes from 'prop-types';

import qs from 'qs';

import { appEnv, userService as users } from 'constants';
import { getAccessToken, getAuthorization } from 'utils/cookies';


const atsModesPairsPatch = async (props) => {

    const method = 'patch';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const { mode, pairId, data } = props;

    const url = `${host}/api/${version}/ats/me/modes/${mode}/pairs/${pairId}?${qs.stringify({
        sells_only: data?.sells_only,
        enabled: data?.enabled
    })}`;

    var auth = await getAccessToken();

    if (auth == 'undefined') auth = await getAuthorization();

    if (auth == 'undefined') return { isSucess: false, response: {}, errorType: 'Unauthorized', status: 401 };

    var settings = {
        url: url,
        method: method,
        headers: {
            'Authorization': auth,
            'Content-Type': 'application/json',
        },
        processData: false,
        data: JSON.stringify(data),
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = response instanceof String ? JSON.parse(response) : response;
        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {
            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};


atsModesPairsPatch.propTypes = {
    mode: Proptypes.string,
    pairId: Proptypes.string,
    data: Proptypes.object
};


export { atsModesPairsPatch };
