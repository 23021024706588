/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Slider } from 'antd';

import { Panel, Column, Row, Title, SwitchableIcon, Date } from 'components/imports';
import { StockChart, LineChart, ScatterChart } from 'components/imports';

import { RefreshSvg } from 'icons/imports';

import { DateFormatter } from 'utils/formatters';


const PnLPerOperationPanel = (props) => {

    const { t } = useTranslation();

    const {
        isUserAuthenticated,
        candlesData,
        signalsData,
        ordersData,
        areFiltersValid,
        isLoading,
        timeframeMilliseconds
    } = props;

    const [pnlSeries, setPnlSeries] = useState([]);

    const [stockChartData, setStockChartData] = useState([]);
    const [stockChartAnnotationsData, setStockChartAnnotationsData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [scatterChartData, setScatterChartData] = useState([]);

    const [sliderValues, setSliderValues] = useState([]);
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);

    const [candlesDataWindow, setCandlesDataWindow] = useState([]);
    const [signalsDataWindow, setSignalsDataWindow] = useState([]);
    const [ordersDataWindow, setOrdersDataWindow] = useState([]);

    const [isLoadingStockChart, setIsLoadingStockChart] = useState(false);
    const [isLoadingLineChart, setIsLoadingLineChart] = useState(false);
    const [isLoadingScatterChart, setIsLoadingScatterChart] = useState(false);

    const refreshChartsIcon = <RefreshSvg className={'icon-svg refresh'} />;


    useEffect(() => {
        setCandlesDataWindow(filterBasedOnSlider(candlesData));
        setSignalsDataWindow(filterBasedOnSlider(signalsData));
        setOrdersDataWindow(filterBasedOnSlider(ordersData));
    }, [sliderValues]);


    useEffect(() => {
        if (candlesData && candlesData.length > 0)
            setSliderValues([candlesData[0].timestamp, candlesData[candlesData.length - 1].timestamp]);
    }, [candlesData]);


    useEffect(() => {

        let lastSignalAux;

        setIsLoadingStockChart(true);

        setStockChartData(candlesDataWindow);

        let isProfit = false;

        setStockChartAnnotationsData([
            ...signalsData.map((signal) => {
                const { timestamp, price, side } = signal;

                const ohlcv = candlesDataWindow.find((candle) => candle.timestamp === timestamp);

                if (timestamp >= sliderValues[0] && timestamp <= sliderValues[1] && ohlcv) {
                    return {
                        type: 'dataMarker',
                        timestamp,
                        annotationPrice: side === 'buy' ? ohlcv.low : ohlcv.high,
                        predictedPrice: price,
                        realizedPrice: price,
                        side
                    };
                }
                else return null;
            }).filter((signal) => signal),
            ...signalsDataWindow.reduce((annotations, signal, index) => {

                const { timestamp, price, side, pnl } = signal;

                if (side === 'sell') {
                    isProfit = pnl > 0;
                    
                    if (index === signalsDataWindow.length - 1) {

                        // Filter OHLCV between last buy signal and current sell signal
                        let periodCandlesWindow = candlesDataWindow.filter(candle => candle.timestamp <= timestamp);

                        // Highest and lowest price in OHLCV
                        const highestPrice = Math.max(...periodCandlesWindow.map(candle => candle.high));
                        const lowestPrice = Math.min(...periodCandlesWindow.map(candle => candle.low));

                        if (periodCandlesWindow.length > 0) {
                            // Potential area
                            annotations.push({
                                type: 'region',
                                start: [timestamp, lowestPrice],
                                end: [periodCandlesWindow[0].timestamp, highestPrice],
                                isRealized: false,
                                isProfit
                            });

                            // Realized area
                            annotations.push({
                                type: 'region',
                                start: [timestamp, price],
                                end: [periodCandlesWindow[0].timestamp, lowestPrice],
                                isRealized: true,
                                isProfit
                            });
                        }
                    }

                    lastSignalAux = {
                        timestamp,
                        price,
                        side
                    };
                }

                else {
                    if (!lastSignalAux) {
                        const firstCandle = candlesDataWindow[candlesDataWindow.length - 1];
                        lastSignalAux = {
                            timestamp: firstCandle.timestamp,
                            side: 'sell'
                        };
                    }

                    // Filter OHLCV between last buy signal and current sell signal
                    let periodCandlesWindow = candlesDataWindow.filter(candle => candle.timestamp >= timestamp && candle.timestamp <= lastSignalAux.timestamp);

                    // Highest and lowest price in OHLCV
                    const highestPrice = Math.max(...periodCandlesWindow.map(candle => candle.high));
                    const lowestPrice = Math.min(...periodCandlesWindow.map(candle => candle.low));

                    if (!lastSignalAux.price) lastSignalAux.price = highestPrice;

                    // Potential area
                    annotations.push({
                        type: 'region',
                        start: [timestamp, lowestPrice],
                        end: [lastSignalAux.timestamp, highestPrice],
                        isRealized: false,
                        isProfit
                    });

                    // Realized area

                    annotations.push({
                        type: 'region',
                        start: [timestamp, price],
                        end: [lastSignalAux.timestamp, lastSignalAux.price],
                        isRealized: true,
                        isProfit
                    });
                }

                return annotations;
            }, [])
        ]);

        setIsLoadingStockChart(false);

    }, [candlesDataWindow, signalsDataWindow]);


    useEffect(() => {

        setIsLoadingLineChart(true);
        setIsLoadingScatterChart(true);

        setLineChartData(signalsDataWindow.map((signal) => ({
            ...signal,
            pnl: (signal.pnl * 100).toFixed(2),
        })));

        setIsLoadingLineChart(false);

        setScatterChartData([
            {
                timestamp: 1736305200,
                buyPrice: 95000,
                sellPrice: 96000,
                pnl: 1000,
                pnlPercentage: 1.05,
                tradeSize: 1.2
            },
            {
                timestamp: 1736391600,
                buyPrice: 96000,
                sellPrice: 95000,
                pnl: -1000,
                pnlPercentage: -1.04,
                tradeSize: 0.8
            },
        ]);

        setIsLoadingScatterChart(false);

    }, [signalsDataWindow, ordersDataWindow]);


    const handleSelectedTimestamp = (position) => setSelectedTimestamp(position);


    const buildSliderMarks = () => {
        let marks = {};

        signalsData.forEach((signal) => {
            const { timestamp, side } = signal;
            marks[timestamp] = {
                style: {
                    top: side == 'buy' ? '0' : '-2rem',
                    color: side == 'buy' ? '#52C41A' : '#EB5353'
                },
                label: side == 'buy' ? 'B' : 'S'
            };
        });

        return marks;
    };


    const filterBasedOnSlider = (array) => {
        if (sliderValues.length < 2) return [...array];

        return [...array].filter(item => {
            let ts = item.timestamp;
            if (`${ts}`.length == 10) ts *= 1000;
            return ts >= sliderValues[0] && ts <= sliderValues[1];
        });
    };


    return (
        <Panel id='pnl-per-operation-panel'
            fill='width'
            fit='height'
            g='0'
        >
            <Row fill='width' j='between'>
                <Row w='6' j='start'>
                    <Title
                        id='pnl-plots-title'
                        txt={t('pnl-by-operation')}
                        variation='tertiary'
                    />
                </Row>

                <Row w='12' fill='height' a='center' j='center'>
                    {candlesData.length > 0 &&
                        <>
                            <p>{selectedTimestamp}</p>

                            <Date
                                className='w-4'
                                timestamp={selectedTimestamp}
                                seconds={false}
                            />
                        </>
                    }
                </Row>

                <Row w='6' j='end'>
                    <SwitchableIcon
                        id='refresh-scatter-plot'
                        onToggle={() => { }}
                        staticIcon={refreshChartsIcon}
                    />
                </Row>
            </Row>

            <hr />

            {!areFiltersValid &&
                <Column
                    fill='width'
                    style={{ height: '5rem' }}
                    a='center'
                    j='center'
                >
                    <p>Selecione somente uma corretora, uma criptomoeda e um timeframe para ver os gráficos</p>
                </Column>
            }

            {areFiltersValid &&
                isLoading &&
                <Column
                    fill='width'
                    style={{ height: '5rem' }}
                    a='center'
                    j='center'
                >
                    <p>Carregando gráficos...</p>
                </Column>
            }

            {areFiltersValid &&
                !isLoading &&
                candlesData.length > 0 &&
                timeframeMilliseconds &&
                <>
                    <Slider
                        range
                        className='fill-width'
                        min={candlesData[0].timestamp}
                        max={candlesData[candlesData.length - 1].timestamp}
                        marks={buildSliderMarks()}
                        onChange={setSliderValues}
                        value={sliderValues}
                        defaultValue={sliderValues}
                        tooltip={{
                            formatter: (value) => DateFormatter(value, 'lll')
                        }}
                        step={timeframeMilliseconds}
                    />

                    <Row fill='width' style={{ height: '20rem' }}>
                        <StockChart
                            values={stockChartData}
                            annotations={stockChartAnnotationsData}
                            isLoading={isLoadingStockChart}
                            onCrosshairMove={handleSelectedTimestamp}
                        />
                    </Row>

                    {/* <Row fill='width' style={{ height: '10rem' }}>
                        <LineChart
                            values={lineChartData}
                            isLoading={isLoadingLineChart}
                            isUserAuthenticated={isUserAuthenticated}
                            onCrosshairMove={handleSelectedTimestamp}
                        />
                    </Row>

                    <Row fill='width' style={{ height: '10rem' }}>
                        <ScatterChart
                            values={scatterChartData}
                            isLoading={isLoadingScatterChart}
                            isUserAuthenticated={isUserAuthenticated}
                            onCrosshairMove={handleSelectedTimestamp}
                        />
                    </Row> */}
                </>
            }
        </Panel >
    );
};


PnLPerOperationPanel.propTypes = {
    isUserAuthenticated: PropTypes.bool,
    candlesData: PropTypes.array,
    signalsData: PropTypes.array,
    ordersData: PropTypes.array,
    areFiltersValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    timeframeMilliseconds: PropTypes.number
};




export default PnLPerOperationPanel;
