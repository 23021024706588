/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Popover } from 'antd';

import { Row, Column, Label } from 'components/imports';

import { buildCurrencyStorageUrl } from 'icons/imports';

import { getGlobalAssets } from 'utils/assets';

import './AssetsPill.css';


const AssetsPill = (props) => {

    const { t } = useTranslation();

    const { symbols, type } = props;

    const [assets, setAssets] = useState([]);

    const maxAssets = 2;

    const [content, setContent] = useState(null);


    useEffect(() => {
        const allAssets = Object.values(getGlobalAssets({})?.[type] || {});
        setAssets(allAssets
            .filter((asset) => symbols && symbols.includes(asset.symbol))
            .sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [symbols, type]);


    useEffect(() => {
        setContent(<Column style={{ maxHeight: '20rem' }} scroll>
            {symbols && symbols.map((symbol) => <div key={symbol}>
                <hr />

                <Label variation='symbol-and-name' value={{ symbol }} key={symbol} />
            </div>)}
        </Column>);
    }, [symbols]);


    return (
        <Popover
            title={
                <Row fill='width' j='between'>
                    <p>{t(type === 'bases' ? 'base-asset.p' : 'quote-asset.p')}</p>

                    <p className='side-label'>({symbols?.length ?? '-'})</p>
                </Row>
            }
            content={content}
            trigger='click'
        >
            <Row
                fit='width'
                className='assets-pill'
            >
                {
                    assets.slice(0, maxAssets).map((asset) => {
                        const { slug } = asset;

                        return (<img
                            alt={slug + ' logo'}
                            className='asset-icon mini-icon'
                            key={slug}
                            src={buildCurrencyStorageUrl(slug)}
                        />);
                    })
                }

                {
                    symbols?.length > maxAssets && (
                        <p>+{symbols.length - maxAssets}</p>
                    )
                }
            </Row>
        </Popover>
    );
};


AssetsPill.propTypes = {
    symbols: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
};


export default AssetsPill;