
import { create } from 'zustand';

import {
    getSelectedCurrency,
    getSelectedExchange,
    getSelectedTimeframe,
    getLanguage,
    getTop100Filter,
    getTheme
} from 'utils/cookies';

import { getUserConnectedExchanges } from 'utils/exchanges';

import { getGridLayouts } from 'utils/grid-layout';


const useGlobalStore = create((set) => ({
    savedLayouts: getGridLayouts(),

    isUserAuthenticated: true,
    setIsUserAuthenticated: (status) => set({ isUserAuthenticated: status }),

    appLang: getLanguage(),
    setAppLang: (lang) => set({ appLang: lang }),

    appTheme: getTheme(),
    setAppTheme: (theme) => set({ appTheme: theme }),

    appSelectedExchange: getSelectedExchange(),
    setAppSelectedExchange: (exchange) => set({ appSelectedExchange: exchange }),

    appSelectedCurrency: getSelectedCurrency(),
    setAppSelectedCurrency: (currency) => set({ appSelectedCurrency: currency }),

    appSelectedTimeframe: getSelectedTimeframe(),
    setAppSelectedTimeframe: (timeframe) => set({ appSelectedTimeframe: timeframe }),

    appTop100Filter: getTop100Filter(),
    setAppTop100Filter: (filter) => set({ appTop100Filter: filter }),

    changingFilters: false,
    setChangingFilters: (status) => set({ changingFilters: status }),

    userExchanges: getUserConnectedExchanges({}),
    setUserExchanges: (exchanges) => set({ userExchanges: exchanges }),

    tickers: {},
    setTickers: (tickers) => set({ tickers })
}));


export default useGlobalStore;
