
import { storageBucket } from 'constants';

// PNG icons --------------------------------------------------------

// News
import BenzingaPng from 'icons/png/benzinga-icon.png';
import BitcoinistPng from 'icons/png/bitcoinist-icon.png';
import BiztocPng from 'icons/png/biztoc-icon.png';
import BusinessInsiderPng from 'icons/png/business-insider-icon.png';
import BusinessStandardPng from 'icons/png/business-standard-icon.png';

import CnbcPng from 'icons/png/cnbc-icon.png';
import CnetPng from 'icons/png/cnet-icon.png';
import CnnPng from 'icons/png/cnn-icon.png';
import CointelegraphPng from 'icons/png/cointelegraph-icon.png';
import CryptoSlatePng from 'icons/png/cryptoslate-icon.png';

import DecryptPng from 'icons/png/decrypt-icon.png';
import DefaultPng from 'icons/png/default-icon.png';

import FinancialExpressPng from 'icons/png/financial-express-icon.png';
import FinancialNewsLondonPng from 'icons/png/financial-news-london-icon.png';
import ForbesPng from 'icons/png/forbes-icon.png';
import FoxBusinessNewsPng from 'icons/png/fox-business-news-icon.png';

import GlobeNewswirePng from 'icons/png/globenewswire-icon.png';

import InvestorsBusinessDailyPng from 'icons/png/investors-business-daily-icon.png';
import InvestorsObserverPng from 'icons/png/investors-observer-icon.png';
import InvestorIdeasPng from 'icons/png/investor-ideas-icon.png';

import KiplingerPng from 'icons/png/kiplinger-icon.png';

import MarketWatchPng from 'icons/png/marketwatch-icon.png';
import MoneyControlPng from 'icons/png/money-control-icon.png';
import MoneyMorningPng from 'icons/png/money-morning-icon.png';

import NewsBTCPng from 'icons/png/news-btc-icon.png';

import PrNewswirePng from 'icons/png/pr-newswire-icon.png';
import PymntsPng from 'icons/png/pymnts-icon.png';

import SouthChinaMorningPostPng from 'icons/png/south-china-morning-post-icon.png';

import TechwebPng from 'icons/png/techweb-icon.png';
import TheDailyHodlPng from 'icons/png/the-daily-hodl-icon.png';
import TheAtlanticPng from 'icons/png/the-atlantic-icon.png';
import TheMotleyFoolPng from 'icons/png/the-motley-fool-icon.png';
import TheWeekNewsPng from 'icons/png/the-week-news-icon.png';

import ZacksPng from 'icons/png/zacks-icon.png';

// Exchanges

import BinancePng from 'icons/png/binance-icon.png';
import BybitPng from 'icons/png/bybit-icon.png';
import CryptoComPng from 'icons/png/crypto-com-icon.png';
import FoxbitPng from 'icons/png/foxbit-icon.png';
import GeminiPng from 'icons/png/gemini-icon.png';
import KrakenPng from 'icons/png/kraken-icon.png';
import KucoinPng from 'icons/png/kucoin-icon.png';

// SVG icons --------------------------------------------------------

import { ReactComponent as AddSvg } from 'icons/svg/add.svg'; export { AddSvg };
import { ReactComponent as AppleSvg } from 'icons/svg/apple.svg'; export { AppleSvg };
import { ReactComponent as AtsSvg } from 'icons/svg/ats.svg'; export { AtsSvg };
import { ReactComponent as AtsReportsSvg } from 'icons/svg/ats-reports.svg'; export { AtsReportsSvg };
import { ReactComponent as AtsSettingsSvg } from 'icons/svg/ats-settings.svg'; export { AtsSettingsSvg };

import { ReactComponent as BearishSvg } from 'icons/svg/bearish.svg'; export { BearishSvg };
import { ReactComponent as BnxLogoSvg } from 'icons/svg/bnx-logo.svg'; export { BnxLogoSvg };
import { ReactComponent as BullishSvg } from 'icons/svg/bullish.svg'; export { BullishSvg };

import { ReactComponent as ClockRegularSvg } from 'icons/svg/clock-regular.svg'; export { ClockRegularSvg };
import { ReactComponent as CloseSvg } from 'icons/svg/close.svg'; export { CloseSvg };
import { ReactComponent as ConfirmSvg } from 'icons/svg/confirm.svg'; export { ConfirmSvg };

import { ReactComponent as DashboardSvg } from 'icons/svg/dashboard.svg'; export { DashboardSvg };
import { ReactComponent as DeleteSvg } from 'icons/svg/delete.svg'; export { DeleteSvg };
import { ReactComponent as DocumentSvg } from 'icons/svg/document.svg'; export { DocumentSvg };
import { ReactComponent as DraggableSvg } from 'icons/svg/draggable.svg'; export { DraggableSvg };

import { ReactComponent as EditSvg } from 'icons/svg/edit.svg'; export { EditSvg };
import { ReactComponent as ExpandSvg } from 'icons/svg/expand.svg'; export { ExpandSvg };
import { ReactComponent as EventsSvg } from 'icons/svg/events.svg'; export { EventsSvg };

import { ReactComponent as Feedback0Svg } from 'icons/svg/feedback-0.svg'; export { Feedback0Svg };
import { ReactComponent as Feedback1Svg } from 'icons/svg/feedback-1.svg'; export { Feedback1Svg };
import { ReactComponent as Feedback2Svg } from 'icons/svg/feedback-2.svg'; export { Feedback2Svg };
import { ReactComponent as Feedback3Svg } from 'icons/svg/feedback-3.svg'; export { Feedback3Svg };
import { ReactComponent as Feedback4Svg } from 'icons/svg/feedback-4.svg'; export { Feedback4Svg };
import { ReactComponent as FileCsvSvg } from 'icons/svg/file-csv.svg'; export { FileCsvSvg };
import { ReactComponent as FileJsonSvg } from 'icons/svg/file-json.svg'; export { FileJsonSvg };
import { ReactComponent as FileXlsxSvg } from 'icons/svg/file-xlsx.svg'; export { FileXlsxSvg };
import { ReactComponent as FileXmlSvg } from 'icons/svg/file-csv.svg'; export { FileXmlSvg };
import { ReactComponent as FilterSvg } from 'icons/svg/filter.svg'; export { FilterSvg };

import { ReactComponent as GoogleSvg } from 'icons/svg/google.svg'; export { GoogleSvg };

import { ReactComponent as HideSvg } from 'icons/svg/hide.svg'; export { HideSvg };
import { ReactComponent as HomeSvg } from 'icons/svg/home.svg'; export { HomeSvg };

import { ReactComponent as InfoSvg } from 'icons/svg/info-icon.svg'; export { InfoSvg };

import { ReactComponent as LanguageSolidSvg } from 'icons/svg/language-solid.svg'; export { LanguageSolidSvg };
import { ReactComponent as LanguageSvg } from 'icons/svg/language.svg'; export { LanguageSvg };
import { ReactComponent as LinkSvg } from 'icons/svg/link.svg'; export { LinkSvg };
import { ReactComponent as ListSvg } from 'icons/svg/list.svg'; export { ListSvg };
import { ReactComponent as LoadingSvg } from 'icons/svg/loading.svg'; export { LoadingSvg };
import { ReactComponent as LockSvg } from 'icons/svg/lock.svg'; export { LockSvg };

import { ReactComponent as MaximizeSvg } from 'icons/svg/maximize.svg'; export { MaximizeSvg };
import { ReactComponent as MicrosoftSvg } from 'icons/svg/microsoft.svg'; export { MicrosoftSvg };
import { ReactComponent as MinimalFilterSolidSvg } from 'icons/svg/minimal-filter-solid.svg'; export { MinimalFilterSolidSvg };
import { ReactComponent as MinimizeSvg } from 'icons/svg/minimize.svg'; export { MinimizeSvg };
import { ReactComponent as MinusSvg } from 'icons/svg/minus.svg'; export { MinusSvg };
import { ReactComponent as MoonSvg } from 'icons/svg/moon.svg'; export { MoonSvg };
import { ReactComponent as MoveLeftArrowSvg } from 'icons/svg/move-left-arrow.svg'; export { MoveLeftArrowSvg };
import { ReactComponent as MoveRightArrowSvg } from 'icons/svg/move-right-arrow.svg'; export { MoveRightArrowSvg };

import { ReactComponent as NeutralSvg } from 'icons/svg/neutral.svg'; export { NeutralSvg };
import { ReactComponent as NewsSvg } from 'icons/svg/news.svg'; export { NewsSvg };

import { ReactComponent as OperationsSvg } from 'icons/svg/operations.svg'; export { OperationsSvg };
import { ReactComponent as OverviewSvg } from 'icons/svg/overview.svg'; export { OverviewSvg };

import { ReactComponent as PencilSvg } from 'icons/svg/pencil.svg'; export { PencilSvg };
import { ReactComponent as Plan0Svg } from 'icons/svg/plan-0.svg'; export { Plan0Svg };
import { ReactComponent as Plan1Svg } from 'icons/svg/plan-1.svg'; export { Plan1Svg };
import { ReactComponent as Plan2Svg } from 'icons/svg/plan-2.svg'; export { Plan2Svg };
import { ReactComponent as Plan3Svg } from 'icons/svg/plan-3.svg'; export { Plan3Svg };
import { ReactComponent as PlanAdminSvg } from 'icons/svg/plan-admin.svg'; export { PlanAdminSvg };
import { ReactComponent as PointSvg } from 'icons/svg/point.svg'; export { PointSvg };

import { ReactComponent as QrCodeSvg } from 'icons/svg/qr-code.svg'; export { QrCodeSvg };
import { ReactComponent as QuestionSvg } from 'icons/svg/question.svg'; export { QuestionSvg };

import { ReactComponent as RefreshSvg } from 'icons/svg/refresh.svg'; export { RefreshSvg };

import { ReactComponent as SearchSvg } from 'icons/svg/search.svg'; export { SearchSvg };
import { ReactComponent as Sentiment0Svg } from 'icons/svg/sentiment-0.svg'; export { Sentiment0Svg };
import { ReactComponent as Sentiment1Svg } from 'icons/svg/sentiment-1.svg'; export { Sentiment1Svg };
import { ReactComponent as Sentiment2Svg } from 'icons/svg/sentiment-2.svg'; export { Sentiment2Svg };
import { ReactComponent as Sentiment3Svg } from 'icons/svg/sentiment-3.svg'; export { Sentiment3Svg };
import { ReactComponent as Sentiment4Svg } from 'icons/svg/sentiment-4.svg'; export { Sentiment4Svg };
import { ReactComponent as SentimentGradient0Svg } from 'icons/svg/sentiment-gradient-1.svg'; export { SentimentGradient0Svg };
import { ReactComponent as SentimentGradient1Svg } from 'icons/svg/sentiment-gradient-1.svg'; export { SentimentGradient1Svg };
import { ReactComponent as SentimentGradient2Svg } from 'icons/svg/sentiment-gradient-2.svg'; export { SentimentGradient2Svg };
import { ReactComponent as SentimentGradient3Svg } from 'icons/svg/sentiment-gradient-3.svg'; export { SentimentGradient3Svg };
import { ReactComponent as SentimentGradient4Svg } from 'icons/svg/sentiment-gradient-4.svg'; export { SentimentGradient4Svg };
import { ReactComponent as SettingsSvg } from 'icons/svg/settings.svg'; export { SettingsSvg };
import { ReactComponent as ShowSvg } from 'icons/svg/show.svg'; export { ShowSvg };
import { ReactComponent as SignalsSvg } from 'icons/svg/signals.svg'; export { SignalsSvg };
import { ReactComponent as SignOutSvg } from 'icons/svg/sign-out.svg'; export { SignOutSvg };
import { ReactComponent as StepFlowOffSvg } from 'icons/svg/step-flow-off.svg'; export { StepFlowOffSvg };
import { ReactComponent as StepFlowOnSvg } from 'icons/svg/step-flow-on.svg'; export { StepFlowOnSvg };
import { ReactComponent as SunSvg } from 'icons/svg/sun.svg'; export { SunSvg };

import { ReactComponent as TelegramSvg } from 'icons/svg/telegram-logo.svg'; export { TelegramSvg };
import { ReactComponent as TranslationSvg } from 'icons/svg/translation.svg'; export { TranslationSvg };
import { ReactComponent as TrendDownSvg } from 'icons/svg/trend-down.svg'; export { TrendDownSvg };
import { ReactComponent as TrendUpSvg } from 'icons/svg/trend-up.svg'; export { TrendUpSvg };

import { ReactComponent as UnlockSvg } from 'icons/svg/unlock.svg'; export { UnlockSvg };
import { ReactComponent as UserSvg } from 'icons/svg/user.svg'; export { UserSvg };

import { ReactComponent as WalletSvg } from 'icons/svg/wallet.svg'; export { WalletSvg };
import { ReactComponent as WarningSvg } from 'icons/svg/warning.svg'; export { WarningSvg };
import { ReactComponent as WhatsAppSvg } from 'icons/svg/whatsapp-logo.svg'; export { WhatsAppSvg };

import { ReactComponent as XSvg } from 'icons/svg/x.svg'; export { XSvg };


// SVG Simple icons -------------------------------------------------
import BnxIconLogoSvg from 'icons/svg/bnx-logo.svg'; export { BnxIconLogoSvg };
import BnxIconLogoMonoSvg from 'icons/svg/bnx-logo-mono.svg'; export { BnxIconLogoMonoSvg };
import CloseImg from 'icons/svg/close.svg'; export { CloseImg };
import ConfirmImg from 'icons/svg/confirm.svg'; export { ConfirmImg };
import LoadingImg from 'icons/svg/loading.svg'; export { LoadingImg };


// 128px icons ------------------------------------------------------

import BnxIconLogo128Png from 'icons/128px/bnx-logo.png'; export { BnxIconLogo128Png };
import UserDefaultPic128Png from 'icons/128px/user-default-pic.png'; export { UserDefaultPic128Png };


export const getIcon = (name, type = 'PNG') => {

    if (!name) return null;

    name = name.toLowerCase();

    switch (type.toLowerCase()) {
        case 'png':
            switch (name) {
                case 'benzinga':
                    return BenzingaPng;
                case 'binance':
                    return BinancePng;
                case 'bitcoinist':
                    return BitcoinistPng;
                case 'biztoc.com':
                    return BiztocPng;
                case 'business insider':
                    return BusinessInsiderPng;
                case 'business standard':
                    return BusinessStandardPng;
                case 'Bybit':
                    return BybitPng;
                case 'cnbc':
                    return CnbcPng;
                case 'cnet':
                    return CnetPng;
                case 'cnn':
                    return CnnPng;
                case 'cointelegraph':
                    return CointelegraphPng;
                case 'cryptoslate':
                    return CryptoSlatePng;
                case 'crypto.com':
                    return CryptoComPng;
                case 'decrypt':
                    return DecryptPng;
                case 'decrypt.co':
                    return DecryptPng;
                case 'financial news london':
                    return FinancialNewsLondonPng;
                case 'forbes':
                    return ForbesPng;
                case 'foxbit':
                    return FoxbitPng;
                case 'fox business news':
                    return FoxBusinessNewsPng;
                case 'gemini':
                    return GeminiPng;
                case 'globenewswire':
                    return GlobeNewswirePng;
                case 'investors business daily':
                    return InvestorsBusinessDailyPng;
                case 'investorsobserver':
                    return InvestorsObserverPng;
                case 'investor ideas':
                    return InvestorIdeasPng;
                case 'kiplinger':
                    return KiplingerPng;
                case 'kraken':
                    return KrakenPng;
                case 'kucoin':
                    return KucoinPng;
                case 'marketwatch':
                    return MarketWatchPng;
                case 'money control':
                    return MoneyControlPng;
                case 'money morning':
                    return MoneyMorningPng;
                case 'motley fool':
                    return TheMotleyFoolPng;
                case 'newsbtc':
                    return NewsBTCPng;
                case 'pr newswire':
                    return PrNewswirePng;
                case 'pymnts.com':
                    return PymntsPng;
                case 'south china morning post':
                    return SouthChinaMorningPostPng;
                case 'techweb.com.cn':
                    return TechwebPng;
                case 'the atlantic':
                    return TheAtlanticPng;
                case 'the daily hodl':
                    return TheDailyHodlPng;
                case 'the financial express':
                    return FinancialExpressPng;
                case 'the week news':
                    return TheWeekNewsPng;
                case 'zacks commentary':
                    return ZacksPng;
                default:
                    return DefaultPng;
            }
        default:
            return name;
    }
};


export const buildCurrencyStorageUrl = (currency) => {
    return `${storageBucket}/currencies/png/32x32/${currency}.png`;
};


export const buildExchangesStorageUrl = (exchange) => {
    return `${storageBucket}/exchanges/png/128x128/${exchange}.png`;
};
