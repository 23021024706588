import { signInWithPopup } from 'firebase/auth';

import { firebaseAuth, firestoreDB, googleProvider, microsoftProvider } from 'App';
import { doc, getDoc, setDoc, serverTimestamp } from 'App';

import { setAccessToken } from 'utils/cookies';

import { sessionsMePost } from 'apis/imports';



export const handleProviderSignIn = async (provider, t, navigate, setPopUpLevel, setPopUpDuration, setPopUpText) => {
    try {
        var user;

        if (provider === 'GOOGLE') {
            const signInPopupResult = await signInWithPopup(firebaseAuth, googleProvider);
            user = signInPopupResult.user;
        }

        if (provider === 'MICROSOFT') {
            const signInPopupResult = await signInWithPopup(firebaseAuth, microsoftProvider);
            user = signInPopupResult.user;
        }

        const username = user.email;
        const fullname = user.displayName;
        const accessToken = 'Bearer ' + user.accessToken;
        const email_verified = user.emailVerified;

        setAccessToken(accessToken);

        const userRef = doc(firestoreDB, 'users', user.uid);
        const userSnapshot = await getDoc(userRef);

        setPopUpLevel('success');
        setPopUpDuration(1500);

        if (userSnapshot.exists()) setPopUpText(t('api_codes.sign-in.200_0'));
        else {
            // Criar o registro do usuário
            const userData = {
                username,
                fullname,
                email_verified,
                disabled: false,
                role: 'FREE',
                onboarding: true,
                auth_provider: provider,
                last_login: serverTimestamp(),
                created_at: serverTimestamp(),
                updated_at: serverTimestamp(),
            };

            await setDoc(userRef, userData);

            setPopUpText(t('api_codes.sign-up.201_0'));
            setPopUpDuration(1500);

            navigate('/onboarding');
        }

        const sessionPostResult = await sessionsMePost({
            username,
            accessToken
        });

        if (sessionPostResult.isSuccess) {
            setPopUpText(t('api_codes.sign-in.200_0'));
            setPopUpLevel('success');
            setPopUpDuration(1500);

            if (userSnapshot.data().onboarding)
                navigate('/onboarding');
            else
                navigate('/integrity', {
                    state: {
                        ...sessionPostResult.response,
                        username
                    }
                });
        }

    } catch (error) {
        setPopUpDuration(3000);

        if (error.message.includes('account-exists-with-different-credential')) {
            setPopUpLevel('warning');
            setPopUpText(t('user-exists-with-different-credential'));
        }
        else {
            setPopUpLevel('error');
            setPopUpText(t('api_codes.default.500_0'));
        }

        throw error;
    }
};