/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Popover } from 'antd';

import { Row, Column, Label } from 'components/imports';

import { buildExchangesStorageUrl } from 'icons/imports';

import { getGlobalExchanges } from 'utils/exchanges';

import './ExchangesPill.css';


const ExchangesPill = (props) => {

    const { t } = useTranslation();

    const { exchanges } = props;

    const maxExchanges = 2;

    const content = (
        <Column>
            {exchanges.map(exchange => {
                exchange = getGlobalExchanges({}).find(exc => exc.slug === exchange);
                return <Label variation='exchange' value={{ exchange }} key={exchange} />;
            })}
        </Column>
    );

    return (
        <Popover
            title={<Row fill='width' j='between'>
                <p>{t('exchange.p')}</p>

                <p className='side-label'>({exchanges.length})</p>
            </Row>}
            content={content}
            trigger='click'
        >
            <Row
                fit='width'
                className='exchanges-pill'
                g='0'
            >
                {
                    exchanges.slice(0, maxExchanges).map((exchange) => (
                        <img
                            alt={exchange + ' logo'}
                            className='asset-icon mini-icon'
                            key={exchange}
                            src={buildExchangesStorageUrl(exchange)}
                        />
                    ))
                }
                {
                    exchanges.length > maxExchanges && (
                        <p>+{exchanges.length - maxExchanges}</p>
                    )
                }
            </Row>
        </Popover>
    );
};


ExchangesPill.propTypes = {
    exchanges: PropTypes.array
};


export default ExchangesPill;