import $ from 'jquery';
import { appEnv, userService as users } from 'constants';
import { getAccessToken, getAuthorization } from 'utils/cookies';

const buildQueryString = (params) => {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
};

const portfoliosMePost = async (data, queryParams = {}) => {
    const method = 'post';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const queryString = buildQueryString(queryParams);
    const url = `${host}/api/${version}/portfolios/me/assets/monitored${queryString ? `?${queryString}` : ''}`;

    var auth = await getAccessToken();

    if (auth == 'undefined') auth = await getAuthorization();

    if (auth == 'undefined') return { isSucess: false, response: {}, errorType: 'Unauthorized', status: 401 };

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        contentType: 'application/json',
        data: JSON.stringify(data),
        headers: {
            Authorization: auth,
        },
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = response instanceof Object ? response : JSON.parse(response);

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {
            status = error.status;

            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};

export { portfoliosMePost };
