import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Column, Input, Row, Title, Label, ExchangesPill, Button } from 'components/imports';
import { MoveLeftArrowSvg, MoveRightArrowSvg } from 'icons/imports';
import { SwitchableIcon } from 'components/imports';
import { portfoliosMePost } from 'apis/imports';

import './WalletStep.css';


const WalletStep = ({
    coins,
    setCoins,
    fetchPortfolio,
    currentStep,
    onContinue,
    hidden,
    setPopUpLevel,
    setPopUpText,
    setPopUpDuration
}) => {
    const { t } = useTranslation();

    const [coinsSearched, setCoinsSearched] = useState([]);
    const [searchCoins, setSearchCoins] = useState('');
    const [allInWalletSelect, setAllInWalletSelect] = useState('');
    const [allOutWalletSelect, setAllOutWalletSelect] = useState('');
    const [processingAction, setProcessingAction] = useState(false);

    const [inWalletCheckedItems, setInWalletCheckedItems] = useState([]);
    const [outWalletCheckedItems, setOutWalletCheckedItems] = useState([]);

    const [inWalletCount, setInWalletCount] = useState(0);
    const [outWalletCount, setOutWalletCount] = useState(0);

    const [inWalletLimit, ] = useState(20);


    useEffect(() => {
        if (currentStep === 3) fetchPortfolio();
    }, [currentStep]);

    useEffect(() => {
        const txt = searchCoins.trim().toLowerCase();
        if (txt === '') {
            setCoinsSearched([...coins]);
        } else {
            const found = coins.filter(coin => {
                const symbol = coin.symbol.toLowerCase();
                const name = coin.name ? coin.name.toLowerCase() : '';
                return symbol.includes(txt) || name.includes(txt);
            });
            setCoinsSearched(found);
        }
    }, [searchCoins, coins]);


    useEffect(() => {
        const inWalletChecked = coinsSearched.filter(coin => coin.checked && coin.inWallet);
        const outWalletChecked = coinsSearched.filter(coin => coin.checked && !coin.inWallet);

        setInWalletCheckedItems(inWalletChecked.map(coin => coin.symbol));
        setOutWalletCheckedItems(outWalletChecked.map(coin => coin.symbol));

        setInWalletCount(coinsSearched.filter(coin => coin.inWallet).length);
        setOutWalletCount(coinsSearched.filter(coin => !coin.inWallet).length);

        const allInSelected = coinsSearched.filter(coin => coin.inWallet).length > 0 &&
            coinsSearched.filter(coin => coin.inWallet).every(coin => coin.checked);
        const allOutSelected = coinsSearched.filter(coin => !coin.inWallet).length > 0 &&
            coinsSearched.filter(coin => !coin.inWallet).every(coin => coin.checked);

        setAllInWalletSelect(allInSelected ? 'checked' : (inWalletChecked.length > 0 ? 'indeterminate' : ''));
        setAllOutWalletSelect(allOutSelected ? 'checked' : (outWalletChecked.length > 0 ? 'indeterminate' : ''));
    }, [coinsSearched]);


    const handleSearchChange = (event) => {
        setSearchCoins(event.target.value);
    };


    const handleToggleSelectAllOutWallet = () => {
        const newBool = allOutWalletSelect !== 'checked';
        setCoins(coins.map(coin => !coin.inWallet ? { ...coin, checked: newBool } : coin));
    };


    const handleToggleSelectAllInWallet = () => {
        const newBool = allInWalletSelect !== 'checked';
        setCoins(coins.map(coin => coin.inWallet ? { ...coin, checked: newBool } : coin));
    };


    const handleToggleCoinCheck = (symbol) => {
        setCoins(coins.map(coin =>
            coin.symbol === symbol ? { ...coin, checked: !coin.checked } : coin
        ));
    };


    const handleMoveToWallet = async () => {
        const currentMonitoredCount = coins.filter(coin => coin.inWallet).length;
        const selectedCount = outWalletCheckedItems.length;
        const totalAfterAdd = currentMonitoredCount + selectedCount;

        if (totalAfterAdd > 10) {
            setPopUpLevel('warning');
            setPopUpText(t('max-cryptocurrencies-warning'));
            setPopUpDuration(3000);
            return;
        }

        setProcessingAction(true);
        const result = await portfoliosMePost(outWalletCheckedItems, { remove: false });

        if (result.isSuccess) {
            setPopUpLevel('success');
            setPopUpText(t('cryptocurrencies-add-success'));
            setPopUpDuration(2000);
            fetchPortfolio();
        } else {
            setPopUpLevel('error');
            setPopUpText(t('cryptocurrencies-add-error'));
            setPopUpDuration(3000);
        }

        setProcessingAction(false);
    };


    const handleRemoveFromWallet = async () => {
        setProcessingAction(true);
        const result = await portfoliosMePost(inWalletCheckedItems, { remove: true });
        if (result.isSuccess) {
            fetchPortfolio();
        }
        setProcessingAction(false);
    };


    return (
        <>
            <Column
                className={`wallet-step ${hidden ? 'hidden' : ''}`}
                g='1'
                fill='width'
                m='sec-ver'
                scroll
            >
                <Title
                    txt={t('smart-trade-wallet')}
                    variation='primary'
                />

                <p className='description'>{t('wallet-description')}</p>

                <Row fill='width' g='2'>
                    <p className='notice'>{t('wallet-notice')}</p>

                    <Input
                        variation='searchbar'
                        placeholder={t('search-placeholder')}
                        value={searchCoins}
                        onChange={handleSearchChange}
                    />
                </Row>

                <Row
                    className='cryptocurrencies-container'
                    fill='width'
                >
                    <Column
                        className='available-cryptos'
                        fill='width'
                    >
                        <Title txt={t('available-cryptocurrencies')} variation='tertiary' />

                        <Row
                            className='header'
                            fill='width'
                            j='between'
                        >
                            <Row g='1'>
                                <Checkbox
                                    checked={allOutWalletSelect === 'checked'}
                                    indeterminate={allOutWalletSelect === 'indeterminate'}
                                    onChange={handleToggleSelectAllOutWallet}
                                >
                                    <p><b>{t('cryptocurrency.s')}</b></p>
                                </Checkbox>
                            </Row>
                            <p><b>{t('exchanges')}</b></p>
                        </Row>

                        <Column
                            className='coins-list'
                            fill='width'
                            scroll
                        >
                            {coinsSearched
                                .filter(coin => !coin.inWallet)
                                .map(coin => (
                                    <Row key={coin.symbol} className='coin-item' fill='width' j='between'>
                                        <Row g='1'>
                                            <Checkbox
                                                checked={coin.checked}
                                                onChange={() => handleToggleCoinCheck(coin.symbol)}
                                            />

                                            <Label
                                                variation='symbol-and-name'
                                                value={{ symbol: coin.symbol, name: coin.name }}
                                            />
                                        </Row>

                                        <ExchangesPill exchanges={coin.exchanges} />
                                    </Row>
                                ))}
                        </Column>

                        <Row g='1' className='selected-count'>
                            <p className='side-label'>{t('selected')}</p>
                            <p className='b'>{t('x-of-y').replace('{x}', outWalletCheckedItems.length).replace('{y}', outWalletCount)}</p>
                        </Row>
                    </Column>

                    <Column j='center' g='2'>
                        <SwitchableIcon
                            className='transfer-button'
                            staticIcon={<MoveLeftArrowSvg className='icon-svg' />}
                            onToggle={handleRemoveFromWallet}
                            disabled={inWalletCheckedItems.length === 0 || processingAction}
                        />

                        <SwitchableIcon
                            className='transfer-button'
                            staticIcon={<MoveRightArrowSvg className='icon-svg' />}
                            onToggle={handleMoveToWallet}
                            disabled={outWalletCheckedItems.length === 0 || processingAction}
                        />
                    </Column>

                    <Column className='monitored-cryptos' fill='width'>
                        <Title txt={t('monitored-cryptocurrencies')} variation='tertiary' />

                        <Row className='header' fill='width' j='between'>
                            <Row g='1'>
                                <Checkbox
                                    checked={allInWalletSelect === 'checked'}
                                    indeterminate={allInWalletSelect === 'indeterminate'}
                                    onChange={handleToggleSelectAllInWallet}
                                >
                                    <p><b>{t('cryptocurrency.s')}</b></p>
                                </Checkbox>
                            </Row>

                            <p className='b'>{t('exchanges')}</p>
                        </Row>

                        <Column className='coins-list' fill='width' scroll>
                            {coinsSearched
                                .filter(coin => coin.inWallet)
                                .map(coin => (
                                    <Row key={coin.symbol} className='coin-item' fill='width' j='between'>
                                        <Row g='1'>
                                            <Checkbox
                                                checked={coin.checked}
                                                onChange={() => handleToggleCoinCheck(coin.symbol)}
                                            />

                                            <Label
                                                variation='symbol-and-name'
                                                value={{ symbol: coin.symbol, name: coin.name }}
                                            />
                                        </Row>

                                        <ExchangesPill exchanges={coin.exchanges} />
                                    </Row>
                                ))}
                        </Column>

                        <Row g='2'>
                            <Row g='1' className='selected-count'>
                                <p className='side-label'>{t('selected')}</p>

                                <p><b>{t('x-of-y').replace('{x}', inWalletCheckedItems.length).replace('{y}', inWalletCount)}</b></p>
                            </Row>

                            <Row g='1' className='total-count'>
                                <p className='side-label'>{t('total')}</p>

                                <p><b>{t('x-of-y').replace('{x}', inWalletCount).replace('{y}', inWalletLimit)}</b></p>
                            </Row>
                        </Row>
                    </Column>
                </Row>
            </Column>

            <Row fill='width' j='center'>
                <Button
                    onClick={onContinue}
                    className='continue-button w-12'
                    state={coins.filter(coin => coin.inWallet).length > 0 ? 'enabled' : 'disabled'}
                >
                    {t('continue')}
                </Button>
            </Row>
        </>
    );
};

WalletStep.propTypes = {
    coins: PropTypes.array.isRequired,
    setCoins: PropTypes.func.isRequired,
    fetchPortfolio: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    onContinue: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func,
    setPopUpDuration: PropTypes.func
};

export default WalletStep;