/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import { Switch, Select, Radio } from 'antd';

import {
    Page, Layout, Loading, MessagePopup, Row, Column, Panel,
    SwitchableIcon, Title, Label, Input, Button
} from 'components/imports';
import { StrategiesModal } from 'components/imports';
import { StrategyOverviewCard } from 'components/imports';

import { RefreshSvg, DeleteSvg } from 'icons/imports';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getGlobalAssetsQuotes } from 'utils/assets';

import { getLanguage, getTheme } from 'utils/cookies';

import {
    atsModesPairsGet, atsModesPairsPatch, atsModesPairsPost, atsModesPairsDelete,
    atsPresetsTypesIdsGet, exchangesPairsLimitsGet, portfoliosMeGet, atsInterruptPost
} from 'apis/imports';

import Skeleton from 'react-loading-skeleton';

import './AtsSettings.css';


const AtsSettings = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'ats-settings';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [userExchanges,] = useState(getUserConnectedExchanges({}));
    const [userBaseAssets, setUserBaseAssets] = useState([]);

    const [globalAssetsQuotes,] = useState(getGlobalAssetsQuotes({}));

    const [pairAllocationMode, setPairAllocationMode] = useState('default');

    const [pairAllocationsList, setPairAllocationsList] = useState([]);
    const [filteredPairAllocationsList, setFilteredPairAllocationsList] = useState([]);
    const [filteredPairAllocationByExchange, setFilteredPairAllocationByExchange] = useState(null);

    const [isNewPairAllocation, setIsNewPairAllocation] = useState(false);
    const [pairAllocation, setPairAllocation] = useState(null);
    const [pairAllocationStrategy, setPairAllocationStrategy] = useState(null);
    const [pairAllocationLimits, setPairAllocationLimits] = useState(null);

    const [baseSymbol, setBaseSymbol] = useState(null);
    const [quoteSymbol, setQuoteSymbol] = useState(null);

    const [searchPairAllocation, setSearchPairAllocation] = useState('');
    const [isLoadingPairAllocations, setIsLoadingPairAllocations] = useState(false);
    const [isLoadingDetailStrategy, setIsLoadingDetailStrategy] = useState(false);
    const [isLoadingDetailsLimits, setIsLoadingDetailsLimits] = useState(false);

    const [newPairAllocationButtonState, setNewPairAllocationButtonState] = useState('enabled');
    const [savePairAllocationButtonState, setSavePairAllocationButtonState] = useState('disabled');
    const [interruptAtsButtonState, setInterruptAtsButtonState] = useState('enabled');

    const [strategiesModalMode, setStrategiesModalMode] = useState(null);

    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (isLoadingPairAllocations ? ' spinning' : '')} />;
    const deleteIcon = <DeleteSvg className='icon-svg no' />;


    useEffect(() => {

        if (location.pathname === `/${pageId}`) {
            document.title = `${t('ats-settings')} - Smart Trade`;
            fetchPairAllocations();
            fetchUserPortfolioAssets();
        }

    }, [location.pathname]);


    useEffect(() => {

        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);

    }, [appTheme, appLang]);


    useEffect(() => setFilteredPairAllocationsList(pairAllocationsList), [pairAllocationsList]);


    useEffect(() => {

        let pairAllocations = [...pairAllocationsList];

        if (filteredPairAllocationByExchange)
            pairAllocations = pairAllocations
                .filter((pos) => pos.exchange_slug === filteredPairAllocationByExchange);

        if (searchPairAllocation !== '')
            pairAllocations = pairAllocations
                .filter((pa) => pa.pair.toLowerCase()
                    .includes(searchPairAllocation
                        .trim()
                        .toLowerCase()
                    )
                );

        setFilteredPairAllocationsList(pairAllocations);

    }, [pairAllocationsList, searchPairAllocation, filteredPairAllocationByExchange]);


    useEffect(() => {

        if (pairAllocation?.pair) {
            const [baseSymbol, quoteSymbol] = pairAllocation.pair.split('/');
            setBaseSymbol(baseSymbol);
            setQuoteSymbol(quoteSymbol);
        }

        if (pairAllocation?.exchange_slug &&
            pairAllocation?.pair &&
            pairAllocation?.config?.quote?.allocation &&
            pairAllocation?.preset?.id &&
            pairAllocation?.preset?.timeframes?.length > 0 &&
            (pairAllocation?.config?.base?.allocation &&
                pairAllocationLimits?.base?.allocation?.min &&
                pairAllocation.config.base.allocation >= pairAllocationLimits?.base?.allocation?.min) &&
            (pairAllocation?.config?.quote?.allocation &&
                pairAllocationLimits?.quote?.allocation?.min &&
                pairAllocation.config.quote.allocation >= pairAllocationLimits?.quote?.allocation?.min)
        )
            setSavePairAllocationButtonState('enabled');
        else setSavePairAllocationButtonState('disabled');

    }, [pairAllocation]);


    useEffect(() => {

        if (baseSymbol && quoteSymbol) {
            const pairAllocationAux = { ...pairAllocation };
            pairAllocationAux.pair = `${baseSymbol}/${quoteSymbol}`;
            setPairAllocation(pairAllocationAux);
            fetchExchangePairLimits(pairAllocationAux);
        }

    }, [baseSymbol, quoteSymbol]);


    useEffect(() => {

        const condition = isLoadingPairAllocations ||
            isNewPairAllocation ||
            isLoadingDetailsLimits ||
            isLoadingDetailStrategy;

        setNewPairAllocationButtonState(condition ? 'disabled' : 'enabled');

    }, [isLoadingPairAllocations, isNewPairAllocation, isLoadingDetailsLimits, isLoadingDetailStrategy]);


    useEffect(() => {

        if (!pairAllocationStrategy) return;

        const { type, id, timeframes } = pairAllocationStrategy;

        setPairAllocation({ ...pairAllocation, preset: { type, id, timeframes } });

    }, [pairAllocationStrategy]);


    const fetchPairAllocations = async () => {

        if (!isUserAuthenticated) return;

        setIsLoadingPairAllocations(true);

        const { isSuccess, response } = await atsModesPairsGet({ mode: pairAllocationMode });

        if (isSuccess) {
            let { items } = response;

            items.sort((a, b) => b.pair_id - a.pair_id);

            setPairAllocationsList(items);

            if (items.length == 0) {
                setPairAllocation(null);
                setPairAllocationStrategy(null);
                setPairAllocationLimits(null);
            }

            if (pairAllocation == null) {
                const newPairAllocation = items[0];
                setPairAllocation(newPairAllocation);
                fetchPairAllocationStrategy(newPairAllocation);
            }
        }

        setIsLoadingPairAllocations(false);
    };


    const fetchPairAllocationStrategy = async (pairAllocation) => {

        if (!isUserAuthenticated) return;

        setIsLoadingDetailStrategy(true);

        const { type, id } = pairAllocation?.preset ?? {};

        if (!type || !id) {
            setIsLoadingDetailStrategy(false);
            return;
        }

        const result = await atsPresetsTypesIdsGet({
            type,
            id,
            metadataOnly: true
        });

        if (result.isSuccess) {
            const strategy = result.response;
            setPairAllocationStrategy(strategy);
        }

        setIsLoadingDetailStrategy(false);
    };


    const fetchExchangePairLimits = async (pairAllocation) => {

        if (!isUserAuthenticated ||
            !pairAllocation ||
            !pairAllocation.exchange_slug ||
            !pairAllocation.pair) return;

        setIsLoadingDetailsLimits(true);

        const result = await exchangesPairsLimitsGet({
            exchange: pairAllocation.exchange_slug,
            pair: pairAllocation.pair
        });

        if (result.isSuccess) setPairAllocationLimits(result.response);

        setIsLoadingDetailsLimits(false);
    };


    const postPairAllocation = async () => {

        if (!isUserAuthenticated) return;

        let data = { ...pairAllocation };

        setSavePairAllocationButtonState('loading');

        const result = await atsModesPairsPost({
            mode: pairAllocationMode,
            exchangeSlug: data.exchange_slug,
            pair: data.pair,
            data
        });

        if (result.isSuccess) {
            setSavePairAllocationButtonState('success');
            setIsNewPairAllocation(false);
            fetchPairAllocations();
        }
        else setSavePairAllocationButtonState('error');
    };


    const patchPairAllocation = async () => {

        if (!isUserAuthenticated) return;

        let data = { ...pairAllocation };

        setSavePairAllocationButtonState('loading');

        const result = await atsModesPairsPatch({
            mode: pairAllocationMode,
            pairId: pairAllocation.pair_id,
            data
        });

        if (result.isSuccess) {
            setSavePairAllocationButtonState('success');
            fetchPairAllocations();
        }
        else setSavePairAllocationButtonState('error');
    };


    const fetchUserPortfolioAssets = async () => {

        if (!isUserAuthenticated) return;

        const result = await portfoliosMeGet();

        if (result.isSuccess) {
            setUserBaseAssets(result.response.assets.monitored.reduce((assets, asset) => {
                const [symbol, details] = Object.entries(asset)[0];
                assets.push({
                    symbol,
                    ...details
                });
                return assets;
            }, []));
        }
    };


    const handleNewPairAllocation = async () => {

        setIsNewPairAllocation(true);

        setPairAllocation({
            enabled: false,
            sells_only: false,
            config: {
                base: {
                    type: 'percentage',
                    allocation: 100
                },
                quote: {
                    type: 'balance',
                    allocation: 10
                }
            },
            exchange_slug: userExchanges?.[0].slug,
            pair: null,
            preset: null
        });

        setBaseSymbol(null);
        setQuoteSymbol(null);
        setPairAllocationStrategy(null);
        setPairAllocationLimits(null);
    };


    const handleDeletePairAllocation = async (pairAllocationAux) => {

        if (!isUserAuthenticated) return;

        const confirm = window.confirm(t('confirm-delete-pair-allocation'));

        if (!confirm) return;

        const result = await atsModesPairsDelete({
            mode: pairAllocationMode,
            pairId: pairAllocationAux.pair_id
        });

        if (result.isSuccess) {

            if (pairAllocationAux.pair_id == pairAllocation?.pair_id) {
                setPairAllocation(null);
                setPairAllocationStrategy(null);
                setPairAllocationLimits(null);
            }

            fetchPairAllocations();
        }
    };


    const handleSearchPairAllocation = (event) => {
        event.preventDefault();
        setSearchPairAllocation(event.target.value);
    };


    const handleChangePairAllocationEnabled = (checked) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.enabled = checked;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationSellsOnly = (checked) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.sells_only = checked;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationExchangeSlug = (exchangeSlug) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.exchange_slug = exchangeSlug;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationBaseSymbol = (value) => setBaseSymbol(value);


    const handleChangePairAllocationBaseType = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.config.base.type = event.target.value;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationBaseAllocation = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };

        let value = event.target.value;

        if (pairAllocationAux.config.base.type === 'percentage')
            value = +(value.replace(['%', ' ', '.', ','], '')) / 100;

        pairAllocationAux.config.base.allocation = value;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationQuoteSymbol = (value) => setQuoteSymbol(value);


    const handleChangePairAllocationQuoteType = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.config.quote.type = event.target.value;
        setPairAllocation(pairAllocationAux);
    };


    const buildFilterExchangesOptions = () => {
        let options = [{ label: t('all'), value: null }];

        options = options.concat(userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug
        })));

        return options;
    };


    const handleChangePairAllocationQuoteAllocation = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        let value = event.target.value;

        if (pairAllocationAux.config.quote.type === 'percentage')
            value = (value / 100).toFixed(2);

        pairAllocationAux.config.quote.allocation = value;

        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationTimeframe = (values) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.preset.timeframes = values;
        setPairAllocation(pairAllocationAux);
    };


    const formatAssetPercentage = (asset) => {

        let { allocation } = asset;
        allocation *= 100;

        if (allocation > 100) allocation = 100;
        if (allocation < 0) allocation = 0;

        return (allocation).toFixed(0);
    };


    const buildBasesFilter = () => {
        if (!userBaseAssets || !pairAllocation?.exchange_slug) return [];

        return userBaseAssets.map(asset => {
            const { symbol, exchanges } = asset;
            return exchanges.includes(pairAllocation.exchange_slug) ? { label: symbol, value: symbol } : null;
        }).filter(symbol => symbol !== null).sort((a, b) => a.label.localeCompare(b.label));
    };


    const buildQuotesFilter = () => {
        if (!globalAssetsQuotes) return [];

        return globalAssetsQuotes.map((asset) => ({
            label: asset.symbol,
            value: asset.symbol
        }));
    };


    const onInterrupAts = async () => {
        setInterruptAtsButtonState('loading');

        const dialog = confirm(t('ask-confirm-interrupt-ats'));

        if (!dialog) {
            setInterruptAtsButtonState('enabled');
            return;
        }

        const result = await atsInterruptPost();

        if (result.isSuccess)
            setInterruptAtsButtonState('success');
        else
            setInterruptAtsButtonState('error');

        window.location.reload();
    };


    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <StrategiesModal
                setMode={setStrategiesModalMode}
                mode={strategiesModalMode}
                pairAllocationMode={pairAllocationMode}
                selectedStrategy={pairAllocationStrategy}
                pairAllocation={pairAllocation}
                setPairAllocationStrategy={setPairAllocationStrategy}
            />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='basic'
                showToolbar={true}
                setIsUserAuthenticated={setIsUserAuthenticated}
                showHeader={true}
                scrollable
            >
                <Row
                    className='main-row'
                    fill='all'
                >

                    <Panel
                        id='pair-allocations'
                        fill='all'
                        w='10'
                    >
                        <Row
                            fill='width'
                            j='between'
                        >
                            <Title txt={t('pair-allocation.p')} variation='tertiary' />

                            <Button
                                id='interrupt-ats'
                                onClick={() => onInterrupAts()}
                                variation='quaternary'
                                state={interruptAtsButtonState}
                            >
                                <p>{t('interrupt-ats')}</p>
                            </Button>

                            <Button
                                onClick={handleNewPairAllocation}
                                variation='primary'
                                state={newPairAllocationButtonState}
                            >
                                {t('new-pair-allocation')}
                            </Button>

                        </Row>

                        <hr />

                        <Row
                            fill='width'
                            j='between'
                            g='1'
                        >
                            <Select
                                className='w-8'
                                options={buildFilterExchangesOptions()}
                                onChange={(option) => setFilteredPairAllocationByExchange(option)}
                                value={filteredPairAllocationByExchange}
                                placeholder={t('exchange.s')}
                            />

                            <Input
                                id='search-coin-trend'
                                className='w-8'
                                onChange={handleSearchPairAllocation}
                                placeholder={t('search.coin')}
                                variation='searchbar'
                                value={searchPairAllocation}
                            />

                            <Row w='8' j='end'>
                                <SwitchableIcon
                                    id='refresh-pair-allocations'
                                    onToggle={() => fetchPairAllocations()}
                                    staticIcon={refreshIcon}
                                />
                            </Row>
                        </Row>

                        <Column fill='all'>
                            <Column
                                id='pair-allocations-list'
                                fill='all'
                                g='0'
                                scroll
                            >
                                {isLoadingPairAllocations &&
                                    <>
                                        <hr />

                                        <Skeleton
                                            borderRadius='var(--border-radius)'
                                            width={'100%'}
                                            height={'4rem'}
                                        />

                                        <Skeleton
                                            borderRadius='var(--border-radius)'
                                            width={'100%'}
                                            height={'4rem'}
                                        />

                                        <Skeleton
                                            borderRadius='var(--border-radius)'
                                            width={'100%'}
                                            height={'4rem'}
                                        />
                                    </>}

                                {!isLoadingPairAllocations && filteredPairAllocationsList.length == 0 &&
                                    <>
                                        <hr />

                                        <Column
                                            fill='all'
                                            a='center'
                                            j='center'
                                        >
                                            <p>{t('no-results')}</p>
                                        </Column>
                                    </>}

                                {!isLoadingPairAllocations &&
                                    filteredPairAllocationsList.length > 0 &&
                                    filteredPairAllocationsList.map((pa, index) => {
                                        const { pair, exchange_slug, pair_id, enabled, sells_only, config } = pa;
                                        const [base, quote] = pair.split('/');

                                        return (
                                            <div key={index}>
                                                <hr />

                                                <Row
                                                    className={'pair-allocation-item' + (!enabled ? ' disabled' : '') + (pair_id === pairAllocation?.pair_id ? ' selected' : '')}
                                                    fill='width'
                                                    onClick={() => {
                                                        setIsNewPairAllocation(false);
                                                        setPairAllocation(pa);
                                                        fetchPairAllocationStrategy(pa);
                                                    }}
                                                >
                                                    <Column fill='width' g='0'>
                                                        {!enabled && <label className='side-label'>{t('disabled')}</label>}

                                                        {enabled && sells_only && <label className='side-label'>{t('sells-only')}</label>}

                                                        <Row fill='width'>
                                                            <Label variation='exchange' value={{ exchange: userExchanges.find(exc => exc.slug == exchange_slug) }} />

                                                            <p><b>:</b></p>

                                                            <Label variation='pair-and-name' value={{ pair }} />
                                                        </Row>
                                                    </Column>

                                                    <Column fill='width' w='8'>
                                                        <Row fill='width' j='between'>
                                                            <p className='side-label'>{t('sell.s')}</p>

                                                            <Row>
                                                                <p><b>{config.base.type == 'percentage' ? formatAssetPercentage(config.base) + '%' : config.base.allocation}</b></p>

                                                                <p className='side-label'>{base}</p>
                                                            </Row>
                                                        </Row>

                                                        <Row fill='width' j='between'>
                                                            <p className='side-label'>{t('buy.v')}</p>

                                                            <Row>
                                                                <p><b>{config.quote.type == 'percentage' ? formatAssetPercentage(config.quote) + '%' : config.quote.allocation}</b></p>

                                                                <p className='side-label'>{quote}</p>
                                                            </Row>
                                                        </Row>
                                                    </Column>

                                                    <SwitchableIcon
                                                        onToggle={() => handleDeletePairAllocation(pa)}
                                                        staticIcon={deleteIcon}
                                                    />
                                                </Row>
                                            </div>
                                        );
                                    })
                                }
                            </Column>
                        </Column>
                    </Panel>

                    <Panel
                        id='pair-allocation-details'
                        fill='height'
                        a='center'
                        w='14'
                    >
                        <Row fill='width' j='between'>
                            <Title txt={t('pair-allocation-details')} variation='tertiary' />

                            <Row>

                                {isNewPairAllocation &&
                                    <Button
                                        onClick={() => {
                                            setIsNewPairAllocation(false);
                                            setPairAllocation(null);
                                            setPairAllocationStrategy(null);
                                            setPairAllocationLimits(null);
                                        }}
                                        variation='tertiary'
                                    >
                                        {t('cancel')}
                                    </Button>
                                }

                                <Button
                                    onClick={() => {
                                        if (isNewPairAllocation) postPairAllocation();
                                        else patchPairAllocation();
                                    }}
                                    variation='primary'
                                    state={savePairAllocationButtonState}
                                >
                                    {t('save')}
                                </Button>

                            </Row>
                        </Row>

                        <hr />

                        {!pairAllocation &&
                            <Column
                                fill='all'
                                a='center'
                                j='center'
                            >
                                <p>{t('no-results')}</p>
                            </Column>
                        }

                        {pairAllocation &&
                            <Column
                                a='center'
                                fill='all'
                                scroll
                            >
                                <Column
                                    fill='all'
                                    w='20'
                                >
                                    <Row
                                        a='start'
                                        fill='width'
                                        j='between'
                                    >
                                        <Column fill='width' w='10'>
                                            <Title variation='tertiary' txt={t('exchange.s')} />

                                            {isNewPairAllocation &&
                                                <Select
                                                    value={pairAllocation?.exchange_slug}
                                                    placeholder={t('select-exchange')}
                                                    onChange={handleChangePairAllocationExchangeSlug}
                                                    options={userExchanges.map((exchange) => ({ label: exchange.name, value: exchange.slug }))}
                                                />}

                                            {!isNewPairAllocation &&
                                                <Select
                                                    disabled={true}
                                                    value={userExchanges.find((exchange) => exchange.slug === pairAllocation?.exchange_slug).name}
                                                />}
                                        </Column>

                                        <Column fill='width' w='10'>
                                            <Title variation='tertiary' txt={t('settings')} />

                                            <Row g='1'>
                                                <Switch
                                                    checked={pairAllocation?.enabled}
                                                    onChange={handleChangePairAllocationEnabled}
                                                />

                                                <p>{t('enabled')}</p>
                                            </Row>

                                            <Row g='1'>
                                                <Switch
                                                    checked={pairAllocation?.sells_pnly}
                                                    onChange={handleChangePairAllocationSellsOnly}
                                                />

                                                <p>{t('sells-only')}</p>
                                            </Row>
                                        </Column>
                                    </Row>

                                    <hr />

                                    <Row
                                        a='start'
                                        fill='width'
                                        j='between'
                                    >
                                        {/* Base */}
                                        <Column
                                            a='start'
                                            fill='width'
                                            g='2'
                                            w='10'
                                        >
                                            <Column fill='width' g='1'>
                                                <Title variation='tertiary' txt={t('base-asset.s')} />

                                                {isNewPairAllocation &&
                                                    <Select
                                                        value={baseSymbol}
                                                        placeholder={t('select-base-asset')}
                                                        onChange={handleChangePairAllocationBaseSymbol}
                                                        options={buildBasesFilter()}
                                                    />
                                                }

                                                {!isNewPairAllocation &&
                                                    <Select
                                                        disabled={true}
                                                        value={baseSymbol}
                                                    />
                                                }

                                                {baseSymbol &&
                                                    <>
                                                        <Title variation='tertiary' txt={t('allocation.s')} />

                                                        <Row fill='width' j='center'>
                                                            <Radio.Group
                                                                className='fill-width'
                                                                onChange={handleChangePairAllocationBaseType}
                                                                value={pairAllocation?.config?.base.type}
                                                            >
                                                                <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                                                <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                                            </Radio.Group>
                                                        </Row>

                                                        <Row fill='width' j='between'>
                                                            <Row w='8'>
                                                                <p className='side-label'>{t('min')}</p>

                                                                {isLoadingDetailsLimits &&
                                                                    <Skeleton width='3rem' height='1rem' />
                                                                }

                                                                {!isLoadingDetailsLimits &&
                                                                    <p className='b'>
                                                                        {pairAllocationLimits?.base?.allocation?.min || '-'} {baseSymbol}
                                                                    </p>
                                                                }
                                                            </Row>

                                                            <Column w='16'>
                                                                {pairAllocation?.config?.base?.type == 'percentage' &&
                                                                    <Input
                                                                        variation='asset-percentage'
                                                                        value={formatAssetPercentage(pairAllocation?.config?.base)}
                                                                        onChange={handleChangePairAllocationBaseAllocation}
                                                                        placeholder={t('allocation.s')}
                                                                        currency={'% ' + baseSymbol}
                                                                    />}

                                                                {pairAllocation?.config?.base?.type == 'balance' &&
                                                                    <Input
                                                                        variation='asset-balance'
                                                                        value={pairAllocation?.config?.base.allocation}
                                                                        onChange={handleChangePairAllocationBaseAllocation}
                                                                        placeholder={t('allocation.s')}
                                                                        currency={baseSymbol}
                                                                        min={pairAllocation?.config?.base?.allocation?.min}
                                                                        max={pairAllocation?.config?.base?.allocation?.max}
                                                                    />}
                                                            </Column>
                                                        </Row>
                                                    </>}
                                            </Column>
                                        </Column>

                                        {/* Quote */}
                                        <Column
                                            a='start'
                                            fill='width'
                                            g='2'
                                            w='10'
                                        >
                                            <Column fill='width' g='1'>
                                                <Title variation='tertiary' txt={t('quote-asset.s')} />

                                                {isNewPairAllocation &&
                                                    <Select
                                                        value={quoteSymbol}
                                                        placeholder={t('select-quote-asset')}
                                                        onChange={handleChangePairAllocationQuoteSymbol}
                                                        options={buildQuotesFilter()}
                                                    />
                                                }

                                                {!isNewPairAllocation &&
                                                    <Select
                                                        disabled={true}
                                                        value={quoteSymbol}
                                                    />
                                                }

                                                {quoteSymbol &&
                                                    <>
                                                        <Title variation='tertiary' txt={t('allocation.s')} />

                                                        <Row fill='width' j='center'>
                                                            <Radio.Group
                                                                className='fill-width'
                                                                onChange={handleChangePairAllocationQuoteType}
                                                                value={pairAllocation?.config?.quote.type}
                                                            >
                                                                <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                                                <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                                            </Radio.Group>
                                                        </Row>

                                                        <Row fill='width' j='between'>
                                                            <Row w='8'>
                                                                <p className='side-label'>{t('min')}</p>

                                                                {isLoadingDetailsLimits &&
                                                                    <Skeleton width='3rem' height='1rem' />
                                                                }

                                                                {!isLoadingDetailsLimits &&
                                                                    <p className='b'>{pairAllocationLimits?.quote?.allocation?.min || '-'} {quoteSymbol}</p>
                                                                }
                                                            </Row>

                                                            <Column w='16'>
                                                                {pairAllocation?.config?.quote?.type == 'percentage' && <Input
                                                                    variation='asset-percentage'
                                                                    value={formatAssetPercentage(pairAllocation?.config?.quote)}
                                                                    onChange={handleChangePairAllocationBaseAllocation}
                                                                    placeholder={t('allocation.s')}
                                                                    currency={'%' + quoteSymbol}
                                                                />}

                                                                {pairAllocation?.config?.quote?.type == 'balance' && <Input
                                                                    variation='asset-balance'
                                                                    value={pairAllocation?.config?.quote.allocation}
                                                                    onChange={handleChangePairAllocationQuoteAllocation}
                                                                    placeholder={t('allocation.s')}
                                                                    currency={quoteSymbol}
                                                                    min={pairAllocation?.config?.quote?.allocation?.min}
                                                                    max={pairAllocation?.config?.quote?.allocation?.max}
                                                                />}
                                                            </Column>
                                                        </Row>
                                                    </>}
                                            </Column>
                                        </Column>
                                    </Row>

                                    {baseSymbol && quoteSymbol &&
                                        <>
                                            <hr />

                                            <Column fill='width'>
                                                <Title variation='tertiary' txt={t('strategy.s')} />

                                                {isLoadingDetailStrategy &&
                                                    <Row m='pri-ver' g='2'>
                                                        <Skeleton
                                                            borderRadius={'var(--border-radius)'}
                                                            width={'36.5rem'}
                                                            height={'6.5rem'}
                                                        />

                                                        <Column fill='height' g='1'>
                                                            <Skeleton
                                                                borderRadius={'var(--border-radius)'}
                                                                width={'15rem'}
                                                                height={'1.25rem'}
                                                            />

                                                            <Skeleton
                                                                borderRadius={'var(--border-radius)'}
                                                                width={'15rem'}
                                                                height={'1.75rem'}
                                                            />
                                                        </Column>
                                                    </Row>
                                                }

                                                {!isLoadingDetailStrategy &&
                                                    !pairAllocationStrategy &&
                                                    <Row>
                                                        <Button
                                                            onClick={() => setStrategiesModalMode('all')}
                                                        >
                                                            {t('select-strategy')}
                                                        </Button>
                                                    </Row>
                                                }

                                                {!isLoadingDetailStrategy &&
                                                    pairAllocationStrategy &&
                                                    <>
                                                        <StrategyOverviewCard
                                                            strategy={pairAllocationStrategy}
                                                            basic={true}
                                                            setStrategiesModalMode={setStrategiesModalMode}
                                                        />

                                                        <hr />

                                                        <Title variation='tertiary' txt={t('timeframe.s')} />

                                                        <Select
                                                            style={{ width: '15rem' }}
                                                            value={pairAllocation?.preset?.timeframes ?? []}
                                                            mode='multiple'
                                                            options={(pairAllocationStrategy?.timeframes ?? []).map((timeframe) => ({
                                                                label: timeframe,
                                                                value: timeframe
                                                            }))}
                                                            placeholder={t('select-timeframe')}
                                                            onChange={handleChangePairAllocationTimeframe}
                                                        />
                                                    </>
                                                }
                                            </Column>

                                        </>}
                                </Column>
                            </Column>
                        }
                    </Panel>
                </Row>
            </Layout>
        </Page >
    );
};

export default AtsSettings;