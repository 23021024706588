import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

import { UserDefaultPic128Png } from 'icons/imports';
import { BnxLogoSvg, SmartTradeLogoSvg, BgAuthentication } from 'images/imports';

import { Page, Column, Layout, MessagePopup, Panel, StepsFlow, Row } from 'components/imports';

import {
    usersMeGet,
    usersMeAvatarGet,
    exchangesConnectionsMeGet,
    portfoliosMeGet
} from 'apis/imports';

import { getGlobalExchanges } from 'utils/exchanges';
import { getAssetCurrency } from 'utils/assets-currencies';

// Store 
import useGlobalStore from 'stores/GlobalStore';

import PersonalInfoStep from './components/personal-info-step/PersonalInfoStep';
import ConnectExchanges from './components/connect-exchange-step/ConnectExchangesStep';
import WalletStep from './components/wallet-step/WalletStep';
import AtsStep from './components/ats-step/AtsStep';
import WelcomeStep from './components/welcome-screen/WelcomeStep';
import FinishStep from './components/finish-screen/FinishStep';
import SecurePasswordStep from './components/secure-password-step/SecurePasswordStep';
import MultiFactorStep from './components/multi-factor-step/MultiFactorStep';
import TermsPoliciesStep from './components/terms-policies-step/TermsPoliciesStep';

import './Onboarding.css';


const Onboarding = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const pageId = 'onboarding';

    const {
        appLang,
        setAppLang
    } = useGlobalStore();

    const [showWelcome, setShowWelcome] = useState(true);

    const [tempToken,] = useState('');

    // Personal Info
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [avatar, setAvatar] = useState(UserDefaultPic128Png);
    const [country, setCountry] = useState('BR');
    const [enableTelegram, setEnableTelegram] = useState(false);
    const [isTelegramVerified, setIsTelegramVerified] = useState(false);
    const [investorProfile, setInvestorProfile] = useState(null);

    // Connect Exchanges
    const [availableExchanges, setAvailableExchanges] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState('');
    const [connectedExchanges, setConnectedExchanges] = useState([]);

    // Wallet
    const [coins, setCoins] = useState([]);

    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [popUpDuration, setPopUpDuration] = useState(5000);

    const steps = [
        t('step-personal-info'),
        t('step-multi-factor-smartphone'),
        t('step-connect-exchanges'),
        t('step-smart-trade-wallet'),
        t('step-configure-ats'),
        t('step-terms-policies'),
        t('step-secure-password'),
    ];

    const [currentStep, setCurrentStep] = useState(+localStorage.getItem('onboarding-last-step') || null);
    const [lastStep, setLastStep] = useState(0);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = 'Onboading - Smart Trade';

            const onboardingStatus = localStorage.getItem('onboarding') || false;

            if (onboardingStatus === 'true') {
                navigate('/overview');
            }

            fetchAvatar();
            fetchUser();
            fetchExchanges();
            fetchExchangeConnections();
            fetchPortfolio();

            $('#mobile-imgs img').hide();

            const quickSettings = document.querySelector('.quick-settings');
            const signInPanel = document.querySelector('#onboarding-parent');
            const smartTradeLogo = document.querySelector('#smart-trade-logo');

            if (quickSettings && signInPanel && smartTradeLogo) {
                signInPanel.insertBefore(quickSettings, signInPanel.firstChild);
                quickSettings.insertBefore(smartTradeLogo, quickSettings.firstChild);

                quickSettings.style.position = 'relative';
                quickSettings.style.width = '100%';
                quickSettings.style.marginBottom = 'var(--margin-secondary)';

                const themeSwitchStep = quickSettings.querySelector('#theme-switch-step');
                if (themeSwitchStep) {
                    themeSwitchStep.remove();
                }
            }

            return () => {
                const quickSettings = document.querySelector('.quick-settings');
                const smartTradeLogo = document.querySelector('#smart-trade-logo');
                if (quickSettings) {
                    document.querySelector('.layout')?.appendChild(quickSettings);
                }
                if (smartTradeLogo) {
                    document.querySelector('.layout')?.appendChild(smartTradeLogo);
                }
            };
        }

    }, [location.pathname]);


    useEffect(() => {
        i18n.changeLanguage(appLang);
    }, [appLang]);


    useEffect(() => {
        if (currentStep > lastStep) {
            localStorage.setItem('onboarding-last-step', currentStep);
            setLastStep(currentStep);
        }
    }, [currentStep]);


    useEffect(() => {

        $('.steps-flow-step').hide();
        $(`#step-${currentStep}`).show();

    }, [currentStep]);


    const fetchUser = async () => {
        const result = await usersMeGet();

        if (result.isSuccess) {
            const user = result.response;

            setFullname(user.fullname);
            setUsername(user.username);
            setEnableTelegram(user?.telegram?.chat_id != null);
            setIsTelegramVerified(user?.telegram?.verified || false);
            setInvestorProfile(user?.investor_profile);
        }
        else navigate('/unauthorized');
    };


    const fetchAvatar = async () => {
        const userAvatarResult = await usersMeAvatarGet();

        if (userAvatarResult && userAvatarResult.response) {
            setAvatar(userAvatarResult.response.avatar_url);
        }
    };


    const fetchExchanges = () => {
        const exchanges = getGlobalExchanges({});
        setAvailableExchanges(exchanges.sort((a, b) => a.name.localeCompare(b.name)));
        setSelectedExchange(exchanges[0].slug);
    };


    const fetchExchangeConnections = async () => {
        const result = await exchangesConnectionsMeGet({});

        if (result.isSuccess)
            setConnectedExchanges(result.response.items);
        else
            console.error('Failed to fetch exchanges:', result);
    };


    const fetchPortfolio = async () => {

        const result = await portfoliosMeGet();

        if (result.isSuccess) {
            const { available, monitored } = result.response.assets || {};

            if (!available || !monitored) return;

            const availableCoins = available.map(item => {
                const symbol = Object.keys(item)[0];
                const currency = getAssetCurrency(symbol) || { name: symbol };
                return {
                    symbol: symbol,
                    name: currency.name,
                    exchanges: item[symbol].exchanges,
                    inWallet: false,
                    checked: false,
                };
            });

            const monitoredCoins = monitored.map(item => {
                const symbol = Object.keys(item)[0];
                const currency = getAssetCurrency(symbol) || { name: symbol };

                return {
                    symbol: symbol,
                    name: currency.name,
                    exchanges: item[symbol].exchanges,
                    inWallet: true,
                    checked: false,
                };
            });

            const allCoins = [...monitoredCoins, ...availableCoins];
            setCoins(allCoins);
        }
    };


    const goBackToStep = (step) => {
        if (step < 0 || step >= steps.length) {
            setPopUpLevel('warning');
            setPopUpText(t('invalid-step-navigation'));
            return;
        }

        setCurrentStep(step);
    };


    // Onboarding handlers
    const handleStartOnboarding = () => {
        setShowWelcome(false);
        setCurrentStep(currentStep ?? 0);
    };


    const handleStepContinue = (step) => {
        const nextStep = (+step) + 1;
        setCurrentStep(nextStep);
        setPopUpLevel('success');
        setPopUpText(t('step-completed'));
        setPopUpDuration(1500);

        if (nextStep === steps.length) {
            localStorage.setItem('onboarding', 'true');
        }
    };


    return (
        <Page id='onboarding-page-base' backgroundImage={BgAuthentication}>

            <div id="background-overlay"></div>

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={false}
                page={pageId}
                showVersion={true}
                setAppLang={setAppLang}
            >
                <img id='smart-trade-logo' src={SmartTradeLogoSvg} />
                <img id='bnx-logo' src={BnxLogoSvg} />

                <Column
                    a='center'
                    fill='all'
                    j='center'
                >
                    <div id='onboarding-parent'>
                        <Panel
                            className='browser'
                            g='2'
                            id='onboarding-panel'
                            p='ter'
                        >
                            {showWelcome &&
                                <WelcomeStep
                                    currentStep={currentStep}
                                    onStartOnboarding={handleStartOnboarding}
                                />}

                            {!showWelcome &&
                                <Column fill='all'>
                                    <div id='onboarding-form'>
                                        <Column
                                            fill='width'
                                            g='1'
                                        >

                                            <Row
                                                fill='width'
                                                j='center'
                                            >
                                                <div style={{ width: '36rem' }}>
                                                    <StepsFlow
                                                        currentStep={lastStep ? lastStep : currentStep}
                                                        steps={steps}
                                                        onClick={goBackToStep}
                                                    />
                                                </div>
                                            </Row>

                                            <Column fill='width' id='step-content'>

                                                {/* STEP 1 */}
                                                {currentStep === 0 && (
                                                    <PersonalInfoStep
                                                        avatar={avatar}
                                                        setAvatar={setAvatar}
                                                        username={username}
                                                        fullname={fullname}
                                                        setFullname={setFullname}
                                                        country={country}
                                                        setCountry={setCountry}
                                                        enableTelegram={enableTelegram}
                                                        setEnableTelegram={setEnableTelegram}
                                                        isTelegramVerified={isTelegramVerified}
                                                        setIsTelegramVerified={setIsTelegramVerified}
                                                        investorProfile={investorProfile}
                                                        setInvestorProfile={setInvestorProfile}
                                                        onContinue={() => handleStepContinue(0)}
                                                        setPopUpLevel={setPopUpLevel}
                                                        setPopUpText={setPopUpText}
                                                        setPopUpDuration={setPopUpDuration}
                                                    />
                                                )}

                                                {/* STEP 2 */}
                                                {currentStep === 1 && (
                                                    <MultiFactorStep
                                                        onContinue={() => handleStepContinue(1)}
                                                        username={username}
                                                        tempToken={tempToken}
                                                        country={country}
                                                        setPopUpLevel={setPopUpLevel}
                                                        setPopUpText={setPopUpText}
                                                        setPopUpDuration={setPopUpDuration}
                                                    />
                                                )}

                                                {/* STEP 3 */}
                                                {currentStep === 2 && (
                                                    <ConnectExchanges
                                                        availableExchanges={availableExchanges}
                                                        setAvailableExchanges={setAvailableExchanges}
                                                        selectedExchange={selectedExchange}
                                                        setSelectedExchange={setSelectedExchange}
                                                        connectedExchanges={connectedExchanges}
                                                        onContinue={() => handleStepContinue(2)}
                                                        setConnectedExchanges={setConnectedExchanges}
                                                        setPopUpLevel={setPopUpLevel}
                                                        setPopUpText={setPopUpText}
                                                        setPopUpDuration={setPopUpDuration}
                                                    />
                                                )}

                                                {/* STEP 4 */}
                                                {currentStep === 3 && (
                                                    <WalletStep
                                                        coins={coins}
                                                        setCoins={setCoins}
                                                        fetchPortfolio={fetchPortfolio}
                                                        currentStep={currentStep}
                                                        onContinue={() => handleStepContinue(3)}
                                                        setPopUpLevel={setPopUpLevel}
                                                        setPopUpText={setPopUpText}
                                                        setPopUpDuration={setPopUpDuration}
                                                    />
                                                )}

                                                {/* STEP 5 */}
                                                {currentStep === 4 && (
                                                    <AtsStep
                                                        pageId={pageId}
                                                        location={location}
                                                        currentStep={currentStep}
                                                        connectedExchanges={connectedExchanges}
                                                        onContinue={() => handleStepContinue(4)}
                                                    />
                                                )}

                                                {/* STEP 6 */}
                                                {currentStep === 5 && (
                                                    <TermsPoliciesStep
                                                        appLang={appLang}
                                                        onContinue={() => handleStepContinue(5)}
                                                    />
                                                )}

                                                {currentStep === 6 && (
                                                    <SecurePasswordStep
                                                        onContinue={() => handleStepContinue(6)}
                                                        setPopUpLevel={setPopUpLevel}
                                                        setPopUpText={setPopUpText}
                                                        setPopUpDuration={setPopUpDuration}
                                                    />
                                                )}

                                                {currentStep === 7 && (
                                                    <FinishStep
                                                        onFinishOnboarding={async () => {

                                                            // Do not update because after set the password the firebase token is invalid
                                                            // const { isSuccess } = await usersMePatch({
                                                            //     body: {
                                                            //         onboarding: false
                                                            //     }
                                                            // });

                                                            localStorage.removeItem('onboarding-last-step');
                                                            navigate('/overview');

                                                        }}
                                                    />
                                                )}
                                            </Column>
                                        </Column>
                                    </div>
                                </Column>}
                        </Panel>
                    </div>
                </Column>
            </Layout>
        </Page>
    );
};

export default Onboarding;
