/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
// React-i18next
import { useTranslation } from 'react-i18next';

import { Select, Tooltip } from 'antd';

import Skeleton from 'react-loading-skeleton';

import { InfoSvg, RefreshSvg, TrendUpSvg, TrendDownSvg } from 'icons/imports'; // Helpers

import { Column, Link, Panel, Row, Title, SwitchableIcon, Button } from 'components/imports';

import { LineChart } from 'components/imports';

// API Endpoints
import { atsInterruptPost } from 'apis/imports';


const AtsOverviewPanel = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { pageId, isUserAuthenticated, showLink } = props;

    const [pnl, setPnl] = useState({});
    const [loadingPnl, setLoadingPnl] = useState(true);

    const [pnlPeriod, setPnlPeriod] = useState('week');
    const [chartData, setChartData] = useState([]);

    const [showWinningInfo, setShowWinningInfo] = useState(false);
    const [showLosingInfo, setShowLosingInfo] = useState(false);

    const [interruptAtsButtonState, setInterruptAtsButtonState] = useState('enabled');

    const infoIcon = <InfoSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg refresh' + (loadingPnl ? ' spinning' : '')} />;
    const trendUpIcon = <TrendUpSvg className={'icon-svg'} />;
    const trendDownIcon = <TrendDownSvg className={'icon-svg'} />;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            getPnl();
        }
    }, [location.pathname]);


    useEffect(() => {
        setChartData((pnl[pnlPeriod] ?? []).reduce((acc, item) => {
            acc.push({ timestamp: item.timestamp, value: item.target, type: 'target', percent: true });
            acc.push({ timestamp: item.timestamp, value: item.pnl, type: 'pnl', percent: true });
            return acc;
        }, []));
    }, [pnl, pnlPeriod]);


    const onInterrupAts = async () => {
        setInterruptAtsButtonState('loading');

        const dialog = confirm(t('ask-confirm-interrupt-ats'));

        if (!dialog) {
            setInterruptAtsButtonState('enabled');
            return;
        }

        const result = await atsInterruptPost();

        if (result.isSuccess)
            setInterruptAtsButtonState('success');
        else
            setInterruptAtsButtonState('error');

        window.location.reload();
    };


    const getPnl = async () => {
        setLoadingPnl(true);
        // const result = await pnlGet({ limit: 10 });
        setLoadingPnl(false);

        setPnl({
            all: [
                // { timestamp: 1735786800, pnl: 1.5, target: 2.0 },
                // { timestamp: 1735873200, pnl: -0.7, target: 2.0 },
                // { timestamp: 1735959600, pnl: 2.1, target: 2.0 },
                // { timestamp: 1736046000, pnl: 0.5, target: 2.0 },
                // { timestamp: 1736132400, pnl: -1.2, target: 2.0 },
                // { timestamp: 1736218800, pnl: 0.0, target: 2.0 },
                // { timestamp: 1736305200, pnl: 1.8, target: 2.0 },
                // { timestamp: 1736391600, pnl: -0.3, target: 2.0 },
            ],
            month: [
                // { timestamp: 1735786800, pnl: 1.5, target: 2.0 },
                // { timestamp: 1735873200, pnl: -0.7, target: 2.0 },
                // { timestamp: 1735959600, pnl: 2.1, target: 2.0 },
                // { timestamp: 1736046000, pnl: 0.5, target: 2.0 },
                // { timestamp: 1736132400, pnl: -1.2, target: 2.0 },
                // { timestamp: 1736218800, pnl: 0.0, target: 2.0 },
                // { timestamp: 1736305200, pnl: 1.8, target: 2.0 },
                // { timestamp: 1736391600, pnl: -0.3, target: 2.0 },
            ],
            week: [
                // { timestamp: 1735786800, pnl: 1.5, target: 2.0 },
                // { timestamp: 1735873200, pnl: -0.7, target: 2.0 },
                // { timestamp: 1735959600, pnl: 2.1, target: 3.0 },
                // { timestamp: 1736046000, pnl: 0.5, target: 3.0 },
                // { timestamp: 1736132400, pnl: -1.2, target: 5.0 },
                // { timestamp: 1736218800, pnl: 0.0, target: 5.0 },
                // { timestamp: 1736305200, pnl: 1.8, target: 5.0 },
                // { timestamp: 1736391600, pnl: 4, target: 5.0 },
            ]
        });
    };


    const buildPnlPeriodsFilter = () => {
        return [
            { label: t('last.day.p').replace('{}', '7'), value: 'week' },
            { label: t('last.day.p').replace('{}', '30'), value: 'month' },
            { label: t('all-time'), value: 'all' },
        ];
    };


    return (
        <Panel
            id='ats-panel'
            fill='all'
            g='0'
        >
            <Row fill='width' j='between'>
                <Row fill='width'>
                    <Column g='0'>
                        <Title variation='tertiary' txt={t('ats-overview')} />

                        {showLink &&
                            <Link
                                id='go-to-news-page'
                                onClick={() => {
                                    const result = confirm(t('confirm-redirect-to-ats-reports'));
                                    if (!result) return;
                                    navigate('/ats-reports');
                                }}
                                variation='primary redirect'
                            >
                                <p>{t('ats-reports-link')}</p>
                            </Link>
                        }
                    </Column>
                </Row>

                <Row fill='width' j='center'>
                    <Button
                        id='interrupt-ats'
                        onClick={() => onInterrupAts()}
                        variation='quaternary'
                        state={interruptAtsButtonState}
                    >
                        <p>{t('interrupt-ats')}</p>
                    </Button>
                </Row>

                <Row fill='width' j='end'>
                    <SwitchableIcon
                        id='refresh-pnl'
                        onToggle={getPnl}
                        staticIcon={refreshIcon}
                    />
                </Row>
            </Row>

            <hr />

            {loadingPnl || !isUserAuthenticated ? (
                <div id='last-articles-skeleton'>
                    <hr />
                    <Column p='pri'>
                        <Skeleton height='5rem' width='100%' />

                        <Row>
                            <Skeleton height='1.5rem' width='1.5rem' />
                            <Skeleton height='1.25rem' width='5rem' />
                        </Row>

                        <Column g='0'>
                            <Skeleton height='1.25rem' width='100%' />
                            <Skeleton height='1.25rem' width='10rem' />
                        </Column>
                    </Column>
                </div>
            ) :
                <Row fill='all' g='1'>
                    <Column style={{ width: '15rem' }}>
                        <Select
                            defaultValue={pnlPeriod}
                            style={{ flex: 1 }}
                            options={buildPnlPeriodsFilter()}
                            onChange={(value) => setPnlPeriod(value)}
                        />

                        <Row g='1' fill='all' j='between'>
                            {trendUpIcon}

                            <Title variation='tertiary' txt='-' />

                            <Tooltip
                                open={showWinningInfo}
                                title={<b>{t('winning-trades-on-period')}</b>}
                            >
                                <Row id='icon-container' onClick={() => setShowWinningInfo(!showWinningInfo)}>
                                    {infoIcon}
                                </Row>
                            </Tooltip>
                        </Row>

                        <Row g='1' fill='all' j='between'>
                            {trendDownIcon}

                            <Title variation='tertiary' txt='-' />

                            <Tooltip
                                open={showLosingInfo}
                                title={<b>{t('losing-trades-on-period')}</b>}
                            >
                                <Row id='icon-container' onClick={() => setShowLosingInfo(!showLosingInfo)}>
                                    {infoIcon}
                                </Row>
                            </Tooltip>
                        </Row>
                    </Column>

                    {pnl[pnlPeriod].length === 0 &&
                        <Column fill='all' j='center' a='center'>
                            <p>{t('no-results')}</p>
                        </Column>}

                    {pnl[pnlPeriod].length > 0 &&
                        <LineChart
                            values={chartData}
                            isLoading={loadingPnl}
                            isUserAuthenticated={isUserAuthenticated}
                        />
                    }
                </Row>
            }
        </Panel >
    );
};


AtsOverviewPanel.propTypes = {
    appLang: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    pageId: PropTypes.string,
    showLink: PropTypes.bool
};


export default AtsOverviewPanel;
