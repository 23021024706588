/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Column, Button, Input, Row, Title } from 'components/imports';
import { LoadingImg } from 'icons/imports';
import { Tabs } from 'antd';
import { parsePhoneNumber, isValidPhoneNumber, getExampleNumber } from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/examples.mobile.json';
import { qrCodesOpenGetEndpoint } from 'apis/imports';

import './MultiFactorStep.css';

const MultiFactorStep = ({ onContinue, username, tempToken, country, setPopUpLevel, setPopUpText, setPopUpDuration }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('auth_app');

    const [mfaQRCode, setMfaQRCode] = useState(null);
    const [smartphoneCode, setSmartphoneCode] = useState(Array(6).fill(''));
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isSmartphoneMfaValid, setIsSmartphoneMfaValid] = useState(false);
    const [phonePlaceholder, setPhonePlaceholder] = useState('');

    const [buttonState, setButtonState] = useState('disabled');


    useEffect(() => {
        if (country) {
            try {
                const example = getExampleNumber(country, examples);
                if (example) {
                    setPhonePlaceholder(example.formatInternational());
                }
            } catch (error) {
                console.error('Error generating phone placeholder:', error);
            }
        }
    }, [country]);


    useEffect(() => {
        if (activeTab === 'auth_app') {
            const qrCodeEndpoint = qrCodesOpenGetEndpoint(username);
            setMfaQRCode(qrCodeEndpoint);
        }
    }, [username, activeTab]);

    useEffect(() => {
        const isCodeComplete = smartphoneCode.every(digit => digit !== '');
        setButtonState(isCodeComplete ? 'enabled' : 'disabled');
    }, [smartphoneCode]);


    const validatePhoneNumber = (number) => {
        try {
            if (!country) {
                setPhoneError(t('select-country-first'));
                return false;
            }

            const cleanNumber = number.replace(/\s+/g, '');

            if (!cleanNumber) {
                setPhoneError(t('phone-required'));
                return false;
            }

            const parsedNumber = parsePhoneNumber(cleanNumber, country);

            if (!parsedNumber) {
                setPhoneError(t('invalid-phone-format'));
                return false;
            }

            if (!isValidPhoneNumber(cleanNumber, country)) {
                setPhoneError(t('invalid-phone-number'));
                return false;
            }

            setPhoneError('');
            return true;
        } catch (error) {
            console.error('Phone validation error:', error);
            setPhoneError(t('invalid-phone-number'));
            return false;
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        validatePhoneNumber(value);
    };

    const validateSmartphoneMfa = async () => {
        const oauthmfa = document.getElementById('oauth-input-2-0-result')?.value;

        if (!oauthmfa || oauthmfa.length !== 6) {
            setButtonState('error');
            setPopUpText(t('mfa-6-digits'));
            setPopUpLevel('warning');
            return;
        }

        try {
            setButtonState('loading');

            // const result = await sessionsMeTempMfaGet({
            //     oauthmfa,
            //     mfa_type: activeTab.toUpperCase(),
            //     tempToken
            // });
            const result = {
                isSuccess: true
            };

            if (result.isSuccess) {
                setButtonState('success');
                setPopUpLevel('success');
                setPopUpText(t('valid-smartphone-mfa'));
                setIsSmartphoneMfaValid(true);

                setTimeout(() => {
                    onContinue();
                }, 1000);
            } else {
                setButtonState('error');
                setPopUpLevel('error');
                setPopUpText(t('invalid-code'));
            }
        } catch (error) {
            setButtonState('error');
            setPopUpLevel('error');
            setPopUpText(t('api_codes.default.500_0'));
        }
    };

    const renderAuthAppContent = () => (
        <Column g='2' j='center' a='center' className='mfa-qr'>
            <Row a='center' j='center' fill='width'>
                <p id='mfa-message'>{t('qr-code')}</p>
                {mfaQRCode
                    ? <img id='mfa-code-img' src={mfaQRCode} alt='Multi-Factor Authentication QR Code' />
                    : <div id='mfa-code-img-loading'>
                        <img className='spinning' src={LoadingImg} />
                    </div>
                }
            </Row>

            <Column j='center' a='center' g='2'>
                <p>{t('validate-auth-smartphone-code').replace('{username}', username)}</p>
                <Input
                    code={smartphoneCode}
                    id='oauth-input-2-0'
                    setCode={setSmartphoneCode}
                    variation='mfa-code'
                />
            </Column>
        </Column>
    );

    const renderPhoneContent = () => (
        <Column g='1' j='center' a='center'>
            <p>{t(`mfa-${activeTab}-description`)}</p>
            <Row fill='width' g='1' className='phone-input-container'>
                <Column g='0.5' fill='width'>
                    <Input
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder={phonePlaceholder}
                        variation="tel"
                        status={phoneError ? 'error' : undefined}
                    />
                    {phoneError && (
                        <p className="error-text">{phoneError}</p>
                    )}
                </Column>
            </Row>

            <p>{t('mfa-insert-code')}</p>
            <Input
                code={smartphoneCode}
                id='oauth-input-2-0'
                setCode={setSmartphoneCode}
                variation='mfa-code'
            />
        </Column>
    );

    const items = [
        {
            key: 'auth_app',
            label: t('Auth app'),
            children: renderAuthAppContent()
        },
        // {
        //     key: 'sms',
        //     label: t('SMS'),
        //     children: renderPhoneContent()
        // },
        // {
        //     key: 'telegram',
        //     label: t('Telegram'),
        //     children: renderPhoneContent()
        // },
        // {
        //     key: 'whatsapp',
        //     label: t('WhatsApp'),
        //     children: renderPhoneContent()
        // }
    ];

    return (
        <>
            <Column
                className='multi-factor-step'
                g='2'
                a='center'
                j='center'
                m='sec-ver'
            >
                <Title
                    txt={t('multi-factor-authentication')}
                    variation='primary'
                />

                {/* <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    items={items}
                    className="mfa-tabs"
                /> */}

                <Column g='2' j='center' a='center' className='mfa-qr' w='12'>
                    <Row a='center' j='center' fill='width'>
                        <p id='mfa-message'>{t('qr-code')}</p>
                        {mfaQRCode
                            ? <img id='mfa-code-img' src={mfaQRCode} alt='Multi-Factor Authentication QR Code' />
                            : <div id='mfa-code-img-loading'>
                                <img className='spinning' src={LoadingImg} />
                            </div>
                        }
                    </Row>

                    <Column j='center' a='center' g='2'>
                        <p>{t('validate-auth-smartphone-code').replace('{username}', username)}</p>
                        <Input
                            code={smartphoneCode}
                            id='oauth-input-2-0'
                            setCode={setSmartphoneCode}
                            variation='mfa-code'
                        />
                    </Column>
                </Column>
            </Column>

            <Row fill='width' j='center'>
                <Button
                    id='step-2-0-btn-validate'
                    variation='primary'
                    onClick={validateSmartphoneMfa}
                    className='continue-button w-12'
                    state={buttonState}
                >
                    {t('validate-code')}
                </Button>
            </Row>
        </>
    );
};

MultiFactorStep.propTypes = {
    onContinue: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    tempToken: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func,
    setPopUpDuration: PropTypes.func
};

export default MultiFactorStep;