
import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Column, Link, Row } from 'components/imports';

import './UserCard.css';


const UserCard = (props) => {

    const { t } = useTranslation();

    const { username, showButton } = props;
    const onClick = props.onClick || (() => { });


    return (
        <Row className='user-card' fill='width' >
            <Column fill='width' >
                <p>{username}</p>
            </Column>

            {showButton &&
                <Link onClick={onClick}>{t('change-email')}</Link>
            }
        </Row>
    );
};


UserCard.propTypes = {
    onClick: PropTypes.func,
    username: PropTypes.string,
    showButton: PropTypes.bool
};


export default UserCard;
