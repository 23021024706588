import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {
    Integrity, NotFound, Overview, ResetPassword, Settings, SignIn, SignUp,
    News, Unauthorized, Wallet, AtsReports, AtsSettings, Trades, Trends, EventsCalendar, 
    Onboarding, Users
} from 'pages/imports';

import { firebaseConfig } from 'constants';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

import { getAnalytics } from 'firebase/analytics';

import './Tokens.css';
import 'styles/Animations.css';
import 'styles/Utils.css';
import './App.css';
import './Antd.css';


export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const microsoftProvider = new OAuthProvider('microsoft.com');

export const firestoreDB = getFirestore(firebaseApp);
export { doc, getDoc, setDoc, serverTimestamp };

export const analytics = getAnalytics(firebaseApp);


export const lastVisitedPage = localStorage.getItem('last_visited_page') || '/overview';


function App() {

    return (
        <Router>
            <Routes>
                <Route path='/' element={lastVisitedPage ? <Navigate to={lastVisitedPage} /> : <SignIn />} />
                <Route path='/home' element={<Navigate to='/' />} />
                <Route path='/integrity' element={<Integrity />} />
                <Route path='/not-found' element={<NotFound />} />
                <Route path='/overview' element={<Overview />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/trades' element={<Trades />} />
                <Route path='/trends' element={<Trends />} />
                <Route path='/sign-in' element={<SignIn />} />
                <Route path='/sign-up' element={<SignUp />} />
                <Route path='/news' element={<News />} />
                <Route path='/unauthorized' element={<Unauthorized />} />
                <Route path='/wallet' element={<Wallet />} />
                <Route path='/ats-reports' element={<AtsReports />} />
                <Route path='/ats-settings' element={<AtsSettings />} />
                <Route path='/events-calendar' element={<EventsCalendar />} />
                <Route path='/onboarding' element={<Onboarding />} />
                <Route path='/users' element={<Users />} />
                <Route path='*' element={<Navigate to='/not-found' />} />
            </Routes>
        </Router>
    );
}

export default App;