import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';

import { Stock } from '@antv/g2plot';


const StockChart = (props) => {

    const chartRef = useRef(null);

    const {
        values,
        annotations,
        isLoading,
        isUserAuthenticated,
        onCrosshairMove
    } = props;

    var stockPlot;


    useEffect(() => {

        const renderPlot = async () => {
            if (chartRef.current) {
                console.log(values);
                stockPlot = new Stock(chartRef.current, {
                    data: values,
                    xField: 'timestamp',
                    yField: ['close', 'open', 'high', 'low' ],
                    yAxis: {
                        grid: null,
                        line: null,
                        tickLine: null
                    },
                    xAxis: {
                        grid: null,
                        tickLine: null,
                        line: null,
                        label: null
                    },
                    legend: false,
                    slider: null,
                    animation: false,
                    autoFit: true,
                    annotations: [
                        ...(annotations || []).map(annotation => {
                            switch (annotation?.type) {
                                case 'dataMarker':
                                    return {
                                        type: 'dataMarker',
                                        position: [annotation.timestamp, annotation.annotationPrice],
                                        text: {
                                            content: `${annotation.side === 'buy' ? '\n▲\nBUY' : 'SELL\n▼\n'}`,
                                            style: {
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                fill: annotation.side === 'buy' ? '#52C41A' : '#EB5353',
                                            },
                                        },
                                        line: null,
                                        point: null,
                                        autoAdjust: true,
                                        direction: annotation.side === 'buy' ? 'downward' : 'upward',
                                    };
                                case 'region':
                                    return {
                                        type: 'region',
                                        start: annotation.start,
                                        end: annotation.end,
                                        style: {
                                            fill: annotation.isProfit ? '#52C41A' : '#EB5353',
                                            fillOpacity: annotation.isRealized ? .3 : .15
                                        },
                                    };
                                default:
                                    return null;
                            }
                        }).filter(annotation => annotation), // Filter out null annotations
                    ],
                });

                stockPlot.render();

                stockPlot.on('plot:mousemove', (evt) => {
                    const { x, y } = evt;
                    const tooltipData = stockPlot.chart.getTooltipItems({ x, y });
                    if (tooltipData.length) {
                        const { data: pointData } = tooltipData[0];
                        if (onCrosshairMove) onCrosshairMove(pointData.timestamp / 1000);
                    }
                });
            }
        };

        renderPlot();

        // Resize listener
        const handleResize = () => {
            if (stockPlot) stockPlot.destroy();
            renderPlot();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup
            if (stockPlot) stockPlot.destroy();
            window.removeEventListener('resize', handleResize);
        };

    }, [[values, isLoading, isUserAuthenticated]]);

    return <div ref={chartRef} className='stock-chart fill-width' style={{ height: '100%' }} />;
};


StockChart.propTypes = {
    values: Proptypes.array,
    annotations: Proptypes.array,
    isLoading: Proptypes.bool,
    isUserAuthenticated: Proptypes.bool,
    onCrosshairMove: Proptypes.func
};


export default StockChart;
