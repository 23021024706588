import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Column, Button, Title } from 'components/imports';

import './FinishStep.css';

const FinishStep = ({
    onFinishOnboarding
}) => {
    const { t } = useTranslation();

    return (
        <Column
            className='steps-flow-step welcome-step'
            id='welcome-step'
            a='center'
            j='center'
            g='2'
        >
            <Column a='center' g='1'>
                <Title
                    txt={t('well-done')}
                    variation='primary'
                />
                <Title
                    txt={t('now-everything-is-ready-to-start')}
                    variation='thertiary'
                />
                <p className='welcome-description'>
                    {t('track-your-orders-performance-and-more-with-smart-trade')}
                </p>
            </Column>

            <Button
                variation='primary'
                className='start-button w-12'
                onClick={onFinishOnboarding}
            >
                <p>{t('start-trading')}</p>
            </Button>
        </Column>
    );
};

FinishStep.propTypes = {
    onFinishOnboarding: PropTypes.func.isRequired
};

export default FinishStep;