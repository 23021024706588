import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

import { Button, Column, Input, Row, Title } from 'components/imports';
import { UserExchangesList } from 'components/imports';

import { exchangesConnectionsMePost, exchangesConnectionsMeDelete } from 'apis/imports';

import { WalletCentralizerPng } from 'images/imports';

import { exchangesPasswordRequired } from 'constants';

import './ConnectExchangesStep.css';


const ConnectExchangesStep = ({
    availableExchanges,
    selectedExchange,
    setSelectedExchange,
    connectedExchanges,
    setConnectedExchanges,
    hidden,
    onContinue,
    setPopUpLevel,
    setPopUpText,
    setPopUpDuration
}) => {
    const { t } = useTranslation();

    const [apiKey, setApiKey] = useState('');
    const [secret, setSecret] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);


    const maskCredential = (credential) => {
        if (!credential || credential.length < 7) return '***';
        return `${credential.slice(0, 3)}***${credential.slice(-3)}`;
    };


    const handleAddExchange = async () => {
        if (!selectedExchange || !apiKey || !secret) {
            setPopUpLevel('warning');
            setPopUpText(t('fill-all-fields'));
            setPopUpDuration(3000);
            return;
        }

        setIsLoading(true);

        try {
            const exchange = availableExchanges.find((exchange) => exchange.slug === selectedExchange);

            const response = await exchangesConnectionsMePost({
                exchangeSlug: exchange.slug,
                apiKey,
                secret,
                password,
            });

            if (response.isSuccess) {
                setConnectedExchanges((prev) => [
                    ...prev,
                    {
                        exchange,
                        credentials: {
                            apiKey: maskCredential(apiKey),
                            secret: maskCredential(secret),
                            password: maskCredential(password),
                        }
                    },
                ]);
                setApiKey('');
                setSecret('');
                setPassword('');

                setPopUpLevel('success');
                setPopUpText(t('exchange-connect-success'));
                setPopUpDuration(3000);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('exchange-connect-error'));
                setPopUpDuration(3000);
            }
        } catch (error) {
            console.error(error);
            setPopUpLevel('error');
            setPopUpText(t('unexpected-error'));
            setPopUpDuration(3000);
        } finally {
            setIsLoading(false);
        }
    };


    const handleDeleteExchange = async (exchangeSlug) => {

        let dialog = confirm(t('ask-confirm-delete-exchange-connection'));

        if (!dialog) return;

        const response = await exchangesConnectionsMeDelete({ exchangeSlug });
        if (response.isSuccess) {
            setConnectedExchanges((prev) => prev.filter((conn) => conn.exchange.slug !== exchangeSlug));
            setPopUpLevel('success');
            setPopUpText(t('exchange-remove-success'));
            setPopUpDuration(3000);
        } else {
            setPopUpLevel('error');
            setPopUpText(t('exchange-remove-error'));
            setPopUpDuration(3000);
        }
    };


    return (
        <>
            <Column
                className={`connect-exchanges-step ${hidden ? 'hidden' : ''}`}
                g='1'
                m='sec-ver'
                scroll
            >
                <Title txt={t('connect-exchanges')} variation='primary' />

                <Row fill='width' g='2'>
                    <Column fill='width' style={{ flex: 1 }} g='1'>
                        <Column fill='width' g='1'>
                            <p>{t('select-exchange')}</p>
                            <Select
                                value={selectedExchange}
                                onChange={(_, option) => {
                                    setApiKey('');
                                    setSecret('');
                                    setPassword('');
                                    return setSelectedExchange(option.value);
                                }}
                                variant='borderless'
                                defaultValue={selectedExchange}
                                style={{ flex: 1 }}
                                options={availableExchanges.map((exchange) => ({
                                    value: exchange.slug,
                                    label: exchange.name,
                                }))}
                            />
                        </Column>

                        <Column fill='width' g='1'>
                            <p>{t('api-key')}</p>
                            <Input
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                                placeholder={t('api-key-placeholder')}
                            />
                        </Column>

                        <Column fill='width' g='1'>
                            <p>{t('secret.s')}</p>
                            <Input
                                value={secret}
                                onChange={(e) => setSecret(e.target.value)}
                                placeholder={t('secret-placeholder')}
                            />
                        </Column>

                        {exchangesPasswordRequired.includes(selectedExchange) && (
                            <Column fill='width' g='1'>
                                <p>{t('password.s')}</p>
                                <Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={t('password-placeholder')}
                                />
                            </Column>
                        )}

                        <Row fill='width' j='end' className='connect-button-container'>
                            <Button
                                onClick={handleAddExchange}
                                state={isLoading ? 'loading' : 'enabled'}
                                className='connect-exchange-button fit-width'
                                variation='secondary'
                            >
                                {t('connect')}
                            </Button>
                        </Row>
                    </Column>

                    <Column fill='width' style={{ flex: 1 }} g='2'>
                        {connectedExchanges.length === 0 ? (
                            <Column a='center' j='center' fill='all' g='2'>
                                <img
                                    src={WalletCentralizerPng}
                                    alt={t('no-connected-exchanges')}
                                    style={{ maxWidth: '50%', opacity: 0.8 }}
                                />
                                <p style={{ textAlign: 'center', color: 'var(--text-secondary)' }}>
                                    {t('no-exchanges-message')}
                                </p>
                            </Column>
                        ) : (
                            <UserExchangesList
                                id='linked-exchanges'
                                deleteExchangeConnection={handleDeleteExchange}
                                items={connectedExchanges}
                            />
                        )}
                    </Column>
                </Row>
            </Column>

            <Row fill='width' j='center' style={{ marginTop: '2rem' }}>
                <Button
                    onClick={onContinue}
                    className='continue-button w-12'
                    state={connectedExchanges.length > 0 ? 'enabled' : 'disabled'}
                >
                    {t('continue')}
                </Button>
            </Row>
        </>
    );
};


ConnectExchangesStep.propTypes = {
    availableExchanges: PropTypes.array,
    selectedExchange: PropTypes.string,
    setSelectedExchange: PropTypes.func,
    connectedExchanges: PropTypes.array,
    setConnectedExchanges: PropTypes.func,
    hidden: PropTypes.bool,
    onContinue: PropTypes.func.isRequired,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func,
    setPopUpDuration: PropTypes.func
};


export default ConnectExchangesStep;
