/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Modal, Select } from 'antd';

import { Column, Row, Title, Badge, Label, Button, SwitchableIcon } from 'components/imports';
import { StrategyOverviewCard } from 'components/imports';
import { AssetsPill } from 'components/imports';

import {
    CloseSvg, UserSvg, TrendUpSvg, TrendDownSvg, OperationsSvg,
    MoveLeftArrowSvg, MoveRightArrowSvg, RefreshSvg
} from 'icons/imports';

import { scoreFormatter, percentageFormatter } from 'utils/formatters';

import { atsPresetsTypesGet } from 'apis/imports';

import Skeleton from 'react-loading-skeleton';


const StrategiesModal = (props) => {

    const { t } = useTranslation();

    const {
        mode,
        setMode,
        pairAllocationMode,
        selectedStrategy,
        pairAllocation
    } = props;

    const [strategyIndex, setStrategyIndex] = useState();
    const [strategy, setStrategy] = useState(null);

    const [exchangesFilter, setExchangesFilter] = useState(null);
    const [pairsFilter, setPairsFilter] = useState([]);
    const [timeframesFilter, setTimeframesFilter] = useState();
    const [levelFilter, setLevelFilter] = useState();

    const [strategiesExchanges, setStrategiesExchanges] = useState([]);
    const [strategiesPairs, setStrategiesPairs] = useState([]);
    const [strategiesTimeframes, setStrategiesTimeframes] = useState([]);
    const [strategiesLevels, setStrategiesLevels] = useState([]);

    const copiers = strategy?.stats?.copiers ?? '-';
    const diaryReturn = strategy?.stats?.general?.avg?.diary_return ?? '-';

    const totalProfit = strategy?.stats?.general?.profit?.total ?? '-';
    const totalLoss = strategy?.stats?.general?.avg?.loss?.total ?? '-';

    const maxProfit = strategy?.stats?.general?.profit?.max ?? '-';
    const maxLoss = strategy?.stats?.general?.avg?.loss?.max ?? '-';

    const [strategies, setStrategies] = useState([]);
    const [filteredStrategies, setFilteredStrategies] = useState([]);

    const [isLoadingStrategies, setIsLoadingStrategies] = useState(true);

    const closeIcon = <CloseSvg className='icon-svg' />;
    const leftArrowIcon = <MoveLeftArrowSvg className='icon-svg' />;
    const rightArrowIcon = <MoveRightArrowSvg className='icon-svg' />;
    const userIcon = <UserSvg className='icon-svg' />;
    const trendUpIcon = <TrendUpSvg className='icon-svg trend-up-text' />;
    const trendDownIcon = <TrendDownSvg className='icon-svg trend-down-text' />;
    const operationsIcon = <OperationsSvg className='icon-svg' />;
    const refreshIcon = <RefreshSvg className={'icon-svg' + (isLoadingStrategies ? ' spinning' : '')} />;


    useEffect(() => {
        setStrategiesExchanges([...new Set([...strategies.map(strategy => strategy.exchanges).flat()])]);
        setStrategiesPairs([...new Set([...strategies.map(strategy => strategy.pairs).flat()])]);
        setStrategiesTimeframes([...new Set([...strategies.map(strategy => strategy.timeframes).flat()])]);
        setStrategiesLevels([...new Set([...strategies.map(strategy => strategy.level).flat()])]);
    }, [strategies]);


    useEffect(() => {
        if (pairAllocation) {
            if (pairAllocation.exchange_slug) setExchangesFilter(pairAllocation.exchange_slug);
            if (pairAllocation.pair) setPairsFilter([pairAllocation.pair]);
        }
    }, [pairAllocation]);


    useEffect(() => {
        let filteredList = [...strategies];

        if (exchangesFilter)
            filteredList = filteredList
                .filter(strategy => strategy.exchanges.includes(exchangesFilter));

        if (pairsFilter.length === 1)
            filteredList = filteredList
                .filter(strategy => strategy.pairs.includes(pairsFilter[0]));

        if (timeframesFilter)
            filteredList = filteredList
                .filter(strategy => strategy.timeframes.includes(timeframesFilter));

        if (levelFilter)
            filteredList = filteredList
                .filter(strategy => strategy.level === levelFilter);

        setFilteredStrategies(filteredList);

    }, [strategies, exchangesFilter, pairsFilter, timeframesFilter, levelFilter]);


    useEffect(() => {
        switch (mode) {
            case 'all':
                getStrategies();
                break;
            case 'selected':
                setStrategy(selectedStrategy);
                break;
            case 'select':
                if (strategyIndex in strategies)
                    setStrategy(strategies[strategyIndex]);
                break;
        }
    }, [mode, strategyIndex]);


    const getStrategies = async () => {

        setIsLoadingStrategies(true);

        const result = await atsPresetsTypesGet({
            mode: pairAllocationMode,
            enabled: true
        });

        if (result.isSuccess) setStrategies(result.response.items);

        setIsLoadingStrategies(false);
    };


    return (
        <Modal
            centered
            footer={null}
            closeIcon={null}
            open={mode !== null}
            width={700}
            onCancel={() => setMode(null)}
        >
            {
                mode === 'all' &&
                <Column fill='all' g='0'>
                    <Row fill='width' j='between'>
                        <Title
                            txt={t('strategy.p')}
                            variation='tertiary'
                        />

                        {selectedStrategy &&
                            <SwitchableIcon
                                className='w-8'
                                staticIcon={rightArrowIcon}
                                onToggle={() => setMode('selected')}
                                showText={true}
                                text={t('selected-strategy')}
                            />
                        }
                    </Row>

                    <hr />

                    {/* Filters */}
                    <Row fill='width' j='between'>
                        <Column fill='width'>
                            <p>{t('exchange.s')}</p>

                            <Select
                                value={exchangesFilter}
                                onChange={setExchangesFilter}
                                defaultValue={null}
                                disabled={pairAllocation}
                                placeholder={t('exchange.s')}
                                options={[
                                    { label: t('all'), value: null },
                                    ...strategiesExchanges
                                        .map(exchange => ({ label: exchange, value: exchange }))
                                ]}
                            />
                        </Column>

                        <Column fill='width'>
                            <p>{t('pair.s')}</p>

                            <Select
                                maxCount={1}
                                value={pairsFilter}
                                onChange={setPairsFilter}
                                mode='tags'
                                disabled={pairAllocation}
                                placeholder={t('pair.s')}
                                options={strategiesPairs
                                    .map(pair => ({ label: pair, value: pair }))}
                            />
                        </Column>

                        <Column fill='width'>
                            <p>{t('timeframe.s')}</p>

                            <Select
                                value={timeframesFilter}
                                onChange={setTimeframesFilter}
                                defaultValue={null}
                                placeholder={t('timeframe.s')}
                                options={[
                                    { label: t('all'), value: null },
                                    ...strategiesTimeframes
                                        .map(timeframe => ({ label: timeframe, value: timeframe }))
                                ]}
                            />
                        </Column>

                        <Column fill='width'>
                            <p>{t('level')}</p>

                            <Select
                                value={levelFilter}
                                onChange={setLevelFilter}
                                defaultValue={null}
                                placeholder={t('level')}
                                options={[
                                    { label: t('all'), value: null },
                                    ...strategiesLevels
                                        .map(level => ({ label: t(level), value: level }))
                                ]}
                            />
                        </Column>

                        <SwitchableIcon
                            staticIcon={refreshIcon}
                            onToggle={() => getStrategies()}
                        />
                    </Row>

                    <hr />

                    {isLoadingStrategies &&
                        <Column
                            fill='all'
                            a='center'
                            style={{ height: '40rem' }}
                            g='1'
                        >
                            <Skeleton
                                borderRadius='var(--border-radius)'
                                height={230}
                                width={584}
                            />

                            <Skeleton
                                borderRadius='var(--border-radius)'
                                height={200}
                                width={584}
                            />
                        </Column>
                    }

                    {!isLoadingStrategies && filteredStrategies.length === 0 &&
                        <Column
                            fill='all'
                            a='center'
                            j='center'
                            style={{ height: '40rem' }}
                        >
                            <p>{t('no-results')}</p>
                        </Column>
                    }

                    {!isLoadingStrategies && filteredStrategies.length > 0 &&
                        <Column
                            a='center'
                            fill='width'
                            style={{ height: '40rem' }}
                            g='1'
                            scroll
                        >
                            {
                                filteredStrategies &&
                                filteredStrategies.map((iteratedStrategy, index) => {

                                    const isSelected = iteratedStrategy.id === selectedStrategy?.id;

                                    return (<Row
                                        key={index}
                                        onClick={() => {
                                            setMode(isSelected ? 'selected' : 'select');
                                            setStrategyIndex(strategies.indexOf(iteratedStrategy));
                                        }}
                                    >
                                        <StrategyOverviewCard
                                            strategy={iteratedStrategy}
                                            setStrategiesModalMode={setMode}
                                            isSelected={isSelected}
                                        />
                                    </Row>);
                                })
                            }
                        </Column>
                    }
                </Column>
            }

            {
                (mode === 'select' || mode === 'selected') && strategy &&
                <Column fill='all'>
                    <Row fill='width' j='between'>
                        <SwitchableIcon
                            className='w-8'
                            staticIcon={leftArrowIcon}
                            showText={true}
                            text={t('strategy.p')}
                            onToggle={() => setMode('all')}
                        />

                        <SwitchableIcon
                            staticIcon={closeIcon}
                            onToggle={() => setMode(null)}
                        />
                    </Row>

                    <Row fill='width' j='between' g='1'>
                        <Title
                            txt={strategy?.name ?? '-'}
                            variation='secondary'
                        />

                        <Badge variation={strategy?.level == 'aggressive' ? 'warning' : 'primary'}>
                            <p>{t(strategy?.level ?? '-')}</p>
                        </Badge>
                    </Row>

                    <hr />

                    <Row fill='all' g='2' a='start'>
                        <Row>
                            {userIcon}

                            <Column g='0'>
                                <p className='emphasis'>{copiers !== '-' ? scoreFormatter(copiers) : copiers}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('user.p')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendUpIcon}

                            <Column g='0'>
                                <p className='emphasis'>{diaryReturn !== '-' ? percentageFormatter({ v: diaryReturn, multiply: true }) : diaryReturn}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('avg-diary-return')}</p>
                            </Column>
                        </Row>

                        <Column w='12'>

                            <p className='side-label'>{t('description')}</p>

                            <p>{strategy.description ?? '-'}</p>
                        </Column>
                    </Row>

                    <hr />

                    <Row fill='width' j='between'>
                        <Column fill='width'>
                            <p>{t('base.p')}</p>

                            <AssetsPill symbols={strategy?.bases ?? []} type='bases' />
                        </Column>

                        <Column fill='width'>
                            <p>{t('quote.p')}</p>

                            <AssetsPill symbols={strategy?.quotes ?? []} type='quotes' />
                        </Column>

                        <Column fill='width'>
                            <p>{t('timeframe.p')}</p>

                            <Row
                                fill='width'
                                j='between'
                                flex='wrap'
                            >
                                {(strategy?.timeframes ?? []).map((timeframe) => <Badge key={timeframe}><p>{timeframe}</p></Badge>)}
                            </Row>
                        </Column>
                    </Row>

                    <hr />

                    <p className='side-label'>{t('overview')}</p>

                    <Row
                        fill='width'
                        j='center'
                        g='3'
                    >
                        <Row>
                            {trendUpIcon}

                            <Column g='0'>
                                <p className='emphasis'>{totalProfit !== '-' ? percentageFormatter({ v: totalProfit, multiply: true }) : totalProfit}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('total-profit')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendDownIcon}

                            <Column g='0'>
                                <p className='emphasis'>{totalLoss !== '-' ? percentageFormatter({ v: totalLoss, multiply: true }) : totalLoss}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('total-loss')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendUpIcon}

                            <Column g='0'>
                                <p className='emphasis'>{maxProfit !== '-' ? percentageFormatter({ v: maxProfit, multiply: true }) : maxProfit}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('max-profit')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendDownIcon}

                            <Column g='0'>
                                <p className='emphasis'>{maxLoss !== '-' ? percentageFormatter({ v: maxLoss, multiply: true }) : maxLoss}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('max-loss')}</p>
                            </Column>
                        </Row>
                    </Row>

                    <hr />

                    <p className='side-label'>{t('performance')}</p>

                    <Row
                        fill='width'
                        j='center'
                        g='3'
                    >
                        <Row>
                            {operationsIcon}

                            <Column g='0'>
                                <p className='emphasis'>{totalLoss !== '-' ? percentageFormatter({ v: totalLoss, multiply: true }) : totalLoss}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('total-trades')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendUpIcon}

                            <Column g='0'>
                                <p className='emphasis'>{maxProfit !== '-' ? percentageFormatter({ v: maxProfit, multiply: true }) : maxProfit}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('winning-trades')}</p>
                            </Column>
                        </Row>

                        <Row>
                            {trendDownIcon}

                            <Column g='0'>
                                <p className='emphasis'>{maxLoss !== '-' ? percentageFormatter({ v: maxLoss, multiply: true }) : maxLoss}</p>

                                <p style={{ fontSize: 'x-small' }}>{t('losing-trades')}</p>
                            </Column>
                        </Row>
                    </Row>

                    <Column
                        fill='width'
                        style={{ height: '15rem' }}
                        g='0'
                        scroll
                    >
                        {
                            Object.entries(strategy?.stats?.per_pair ?? {})
                                .map(([pair, stats], index) => {

                                    const { profit, trades } = stats;

                                    return (
                                        <div key={index}>
                                            <hr />

                                            <Row fill='width' g='1'>
                                                <Label
                                                    variation='pair-and-name'
                                                    value={{ pair }}
                                                />

                                                <Row>
                                                    {operationsIcon}

                                                    <Column g='0'>
                                                        <p className='emphasis'>{trades.losing + trades.winning}</p>

                                                        <p className='side-label'>{
                                                            percentageFormatter({
                                                                v: trades.rate ?? 0,
                                                                multiply: true
                                                            })}
                                                        </p>
                                                    </Column>
                                                </Row>

                                                <Row>
                                                    {trendUpIcon}

                                                    <Column g='0'>
                                                        <p className='emphasis'>{trades.winning}</p>

                                                        <p className='trend-up-text'>{
                                                            percentageFormatter({
                                                                v: profit.max ?? 0,
                                                                multiply: true
                                                            })}
                                                        </p>
                                                    </Column>
                                                </Row>

                                                <Row>
                                                    {trendDownIcon}

                                                    <Column g='0'>
                                                        <p className='emphasis'>{trades.losing}</p>

                                                        <p className='trend-down-text'>{
                                                            percentageFormatter({
                                                                v: profit.min ?? 0,
                                                                multiply: true
                                                            })}
                                                        </p>
                                                    </Column>
                                                </Row>
                                            </Row>
                                        </div>);
                                })
                        }
                    </Column>

                    {mode === 'select' &&
                        <Row fill='width' j='center'>
                            <Button
                                className='w-12'
                                variation='primary'
                                onClick={() => {
                                    setMode('selected');

                                    if (props.setPairAllocationStrategy)
                                        props.setPairAllocationStrategy(strategy);

                                    setStrategyIndex(null);
                                    setMode(null);
                                }}
                            >
                                {t('use-strategy')}
                            </Button>
                        </Row>
                    }

                    {mode === 'selected' &&
                        <Row fill='width' j='center'>
                            <Button
                                className='w-12'
                                variation='primary'
                                state='disabled'
                            >
                                {t('in-use-strategy')}
                            </Button>
                        </Row>
                    }
                </Column>
            }
        </Modal >
    );
};


StrategiesModal.propTypes = {
    mode: PropTypes.string,
    setMode: PropTypes.func.isRequired,
    pairAllocationMode: PropTypes.string.isRequired,
    pairAllocation: PropTypes.object,
    setPairAllocationStrategy: PropTypes.func,
    selectedStrategy: PropTypes.object
};


export default StrategiesModal;
