/* eslint-disable @typescript-eslint/no-unused-vars */
// src/pages/Users.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

import { Page, Column, Layout, Panel, Row } from 'components/imports';

// Store 
import useGlobalStore from 'stores/GlobalStore';

// Charts
import { DonutChart } from 'components/imports';
import { LineChart } from 'components/imports';

import { Button, Input, Title, SwitchableIcon, Link } from 'components/imports';

import { usersMeGet, usersGet, usersInvitePost } from 'apis/imports';

import { DeleteSvg, PlanAdminSvg, Plan0Svg } from 'icons/imports';

import Skeleton from 'react-loading-skeleton';



const Users = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const pageId = 'users';

    const {
        isUserAuthenticated,
    } = useGlobalStore();

    const [isAdmin, setIsAdmin] = useState(false);
    const [userPerRoleOverview, setUserPerRoleOverview] = useState({
        admin: { total: 2, percent: .33 },
        basic: { total: 2, percent: .33 },
        free: { total: 2, percent: .33 },
    });

    const [activeUsersChartData, setActiveUsersChartData] = useState([]);

    const [inviteEmail, setInviteEmail] = useState('');

    const [users, setUsers] = useState([]);
    const [searchUsers, setSearchUsers] = useState('');

    const [isLoadingActiveUsersChart, setIsLoadingActiveUsersChart] = useState(false);
    const [isLoadingUserPerRoleOverview, setIsLoadingUserPerRoleOverview] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);

    const [inviteEmailButtonState, setInviteEmailButtonState] = useState('disabled');
    const [searchUsersButtonState, setSearchUsersButtonState] = useState('enabled');


    const deleteIcon = <DeleteSvg className='icon-svg no' />;
    const plan0Icon = <Plan0Svg className='icon-svg'></Plan0Svg>;
    const planAdminIcon = <PlanAdminSvg className='icon-svg'></PlanAdminSvg>;


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            fetchUsers();
        }
    }, [location.pathname]);


    useEffect(() => {
        const checkAdminRole = async () => {
            try {
                const response = await usersMeGet();
                if (response.data.role === 'ADMIN') {
                    setIsAdmin(true);
                } else {
                    const lastVisitedPage = localStorage.getItem('last_visited_page');
                    navigate(lastVisitedPage);
                }
            } catch (error) {
                console.error(error);
            }
        };
        checkAdminRole();

        setActiveUsersChartData([
            { timestamp: 1738983600, value: 2, type: 'active' },
            { timestamp: 1739070000, value: 2, type: 'active' },
            { timestamp: 1739156400, value: 2, type: 'active' },
            { timestamp: 1739242800, value: 2, type: 'active' },
            { timestamp: 1739329200, value: 2, type: 'active' },
            { timestamp: 1739415603, value: 3, type: 'active' },
        ]);
    }, []);


    useEffect(() => {
        if (inviteEmail.length == 0) setInviteEmailButtonState('disabled');
        else setInviteEmailButtonState('enabled');
    }, [inviteEmail]);


    const handleInviteEmailChange = (event) => setInviteEmail(event.target.value);

    const handleSearchUsersChange = (event) => setSearchUsers(event.target.value);


    const sendInviteToUser = async () => {
        setInviteEmailButtonState('loading');

        try {
            const { isSuccess } = await usersInvitePost(inviteEmail);

            if (isSuccess) {
                setInviteEmail('');
                setInviteEmailButtonState('success');
                return;
            }

            setInviteEmailButtonState('error');
        } catch (error) {
            setInviteEmailButtonState('error');
            console.error(error);
        }
    };


    const fetchUsers = async (showMore = false) => {

        setSearchUsersButtonState('loading');
        setIsLoadingUsers(true);

        try {
            var props = { search: searchUsers };

            if (showMore) props['offset'] = users.offset + users.limit;

            const { isSuccess, response } = await usersGet(props);

            if (isSuccess) {
                setSearchUsersButtonState('success');
                setUsers(response);
            }
            else setSearchUsersButtonState('error');

        } catch (error) {
            setSearchUsersButtonState('error');
            console.error(error);
        }

        setIsLoadingUsers(false);
    };


    const buildUserRoleOptions = () => {
        return [
            { value: 'ADMIN', label: t('admin') },
            { value: 'BASIC', label: t('basic') },
            { value: 'FREE', label: t('free') },
        ];
    };



    return (
        <Page id='users'>
            <Layout
                checkAuth={true}
                page={pageId}
                showToolbar={true}
                showHeader={true}
                quickSettings='basic'
                setIsUserAuthenticated={setIsAdmin}
            >
                <Row
                    className='main-row'
                    fill='all'
                    a='start'
                    j='center'
                >
                    <Row
                        fill='all'
                        a='start'
                        style={{ width: '80rem' }}
                    >
                        <Column fill='height' w='6'>
                            <Panel
                                id='users-overview'
                            >
                                <Title variation='secondary' txt={t('users-overview')} />

                                <hr />

                                <Row fill='width' j='between'>
                                    <Column fill='width'>
                                        <p className='side-label'>{t('total')}</p>

                                        <p className='huge b'>9</p>
                                    </Column>

                                    <Column fill='width'>
                                        <p className='side-label'>{t('admin')}</p>

                                        <p className='huge b'>3</p>
                                    </Column>

                                    <Column fill='width'>
                                        <p className='side-label'>{t('paid')}</p>

                                        <p className='huge b'>3</p>
                                    </Column>

                                    <Column fill='width'>
                                        <p className='side-label'>{t('free')}</p>

                                        <p className='huge b'>6</p>
                                    </Column>
                                </Row>
                            </Panel>

                            <Panel id='users-distribution'>
                                <Title variation='secondary' txt={t('users-distribution')} />

                                <hr />

                                <DonutChart
                                    data={[
                                        { type: t('admin'), value: userPerRoleOverview.admin.percent },
                                        { type: t('basic'), value: userPerRoleOverview.basic.percent },
                                        { type: t('free'), value: userPerRoleOverview.free.percent }
                                    ]}
                                />
                            </Panel>
                        </Column>

                        <Column fill='height' w='12'>
                            <Panel id='active-users-per-day'>
                                <Title variation='secondary' txt={t('active-users-per-day')} />

                                <hr />

                                <Row
                                    fill='width'
                                    j='center'
                                    style={{ height: '3.5rem' }}
                                >
                                    <LineChart
                                        values={activeUsersChartData}
                                        isLoading={isLoadingActiveUsersChart}
                                        isUserAuthenticated={isUserAuthenticated}
                                    />
                                </Row>
                            </Panel>

                            <Panel
                                id='manage-users'
                                fill='width'
                            >
                                <Row
                                    fill='width'
                                    j='between'
                                >
                                    <Title variation='secondary' txt={t('manage-users')} />

                                    <Row>
                                        <Input
                                            id='search-users'
                                            onChange={handleSearchUsersChange}
                                            placeholder={t('search-users')}
                                            variation='searchbar'
                                            value={searchUsers}
                                        />

                                        <Button
                                            onClick={() => fetchUsers()}
                                            state={searchUsersButtonState}
                                        >
                                            {t('apply')}
                                        </Button>
                                    </Row>
                                </Row>

                                {isLoadingUsers &&
                                    <>
                                        <hr />

                                        <Row fill='width' j='between'>
                                            <Skeleton
                                                style={{ borderRadius: 'var(--border-radius)' }}
                                                height={40}
                                                width={'15rem'}
                                            />

                                            <Skeleton
                                                style={{ borderRadius: 'var(--border-radius)' }}
                                                height={40}
                                                width={'5rem'}
                                            />

                                            <Skeleton
                                                style={{ borderRadius: 'var(--border-radius)' }}
                                                height={40}
                                                width={'5rem'}
                                            />
                                        </Row>
                                    </>
                                }

                                {!isLoadingUsers && users?.data?.length === 0 &&
                                    <>
                                        <hr />

                                        <Row fill='width' j='center'>
                                            <p>{t('no-results')}</p>
                                        </Row>
                                    </>
                                }

                                {!isLoadingUsers && users?.data?.length > 0 &&
                                    <>
                                        <Column
                                            fill='width'
                                            style={{ height: '30rem' }}
                                            scroll
                                        >
                                            {users.data.map((user) => (
                                                <>
                                                    <hr />

                                                    <Row
                                                        fill='width'
                                                        j='between'
                                                        key={user.id}
                                                    >
                                                        <Column>
                                                            <p>{user.fullname}</p>
                                                            <p className='side-label'>{user.username}</p>
                                                        </Column>

                                                        <Row g='2'>
                                                            <Row g='0'>
                                                                {user?.role === 'ADMIN' ? planAdminIcon : plan0Icon}

                                                                <Select
                                                                    disabled={true}
                                                                    defaultValue={user.role}
                                                                    options={buildUserRoleOptions()}
                                                                    style={{ width: '7rem' }}
                                                                    onChange={(value) => { }}
                                                                />
                                                            </Row>

                                                            <SwitchableIcon
                                                                staticIcon={deleteIcon}
                                                                onClick={() => { }}
                                                            />
                                                        </Row>
                                                    </Row>
                                                </>
                                            ))}
                                        </Column>

                                        <Row fill='width' j='center'>
                                            <Link
                                                id='load-more-users'
                                                onClick={() => fetchUsers(true)}
                                                variation='primary'
                                            >
                                                <p>{t('show.more')}</p>
                                            </Link>
                                        </Row>

                                        <Row>
                                            <p>
                                                {
                                                    t('showing-x-of-y')
                                                        .replace('{x}', users?.data?.length ?? '-')
                                                        .replace('{y}', users?.total ?? '-')
                                                }
                                            </p>
                                        </Row>
                                    </>
                                }
                            </Panel>
                        </Column>

                        <Column fill='height' w='6'>
                            <Panel
                                id='invite-user'
                                fill='width'
                            >
                                <Row fill='width' j='between'>
                                    <Title variation='secondary' txt={t('invite-user')} />

                                    <Button
                                        state={inviteEmailButtonState}
                                        onClick={() => sendInviteToUser()}
                                    >
                                        {t('send')}
                                    </Button>
                                </Row>

                                <hr />

                                <Input
                                    id='invite-email-input'
                                    value={inviteEmail}
                                    placeholder={t('email')}
                                    onChange={handleInviteEmailChange}
                                    type='email'
                                />
                            </Panel>
                        </Column>
                    </Row>
                </Row>
            </Layout>
        </Page >
    );
};

Users.propTypes = {
    appLang: PropTypes.string,
};
export default Users;
