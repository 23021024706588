import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Base Components
import { Panel, Column, Row, Title } from 'components/imports';

// Icon Buttons
import { SwitchableIcon } from 'components/imports';

// Tables Components
import { OrdersTable } from 'components/imports';

import { RefreshSvg } from 'icons/imports';
import useGlobalStore from 'stores/GlobalStore';


const OrdersPanel = (props) => {

    const { t } = useTranslation();

    const {
        appLang
    } = useGlobalStore();


    const { isUserAuthenticated, userExchanges, ordersData, fetchOrders, isLoading } = props;

    const refreshOrdersIcon = <RefreshSvg className={'icon-svg refresh' + (isLoading ? ' spinning' : '')} />;


    return (
        <Panel id='orders-panel'
            fill='all'
            g='0'
        >
            <Row fill='width' j='between'>
                <Title id='orders-title' txt={t('order.p')} variation='tertiary' />

                <SwitchableIcon
                    id='refresh-ordewrs'
                    onToggle={() => fetchOrders()}
                    staticIcon={refreshOrdersIcon}
                />
            </Row>

            <hr />

            {
                isLoading|| !isUserAuthenticated
                    ? (<Row
                        fill='width'
                        j='between'
                    >
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                    </Row>)
                    : (ordersData.length === 0
                        ? (<Row
                            a='center'
                            fill='all'
                            j='center'
                        >
                            <Column
                                a='center'
                                fit='all'
                                j='center'
                            >
                                <p>{t('no-results')}</p>
                            </Column>
                        </Row>)
                        : <OrdersTable id='orders'
                            exchanges={userExchanges}
                            items={ordersData}
                            lang={appLang}
                        />
                    )
            }
        </Panel>);
};


OrdersPanel.propTypes = {
    isUserAuthenticated: PropTypes.bool.isRequired,
    userExchanges: PropTypes.array.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    ordersData: PropTypes.array.isRequired,
};


export default OrdersPanel;
