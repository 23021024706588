import PropTypes from 'prop-types';
import $ from 'jquery';

import { appEnv, userService as users } from 'constants';

import qs from 'qs';

import { getAuthorization } from 'utils/cookies';


const usersGet = async (props) => {

    const { search, offset } = props;

    const method = 'get';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    var query = {};

    if (search && search !== '') query['search'] = search;
    if (offset) query['offset'] = offset;

    const url = `${host}/api/${version}/users?${qs.stringify(query)}`;

    const auth = await getAuthorization();

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        contentType: 'application/json',
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = response instanceof String ? JSON.parse(response) : response;

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';

        if (error && error.status) {

            status = error.status;
            try {
                const response = JSON.parse(error.responseText);
                if ('detail' in response && 'type' in response.detail)
                    errorType = response.detail.type;
            } catch (e) { errorType = 'InternalServerError'; }
        }

        return {
            isSuccess: false,
            errorType,
            status,
        };
    }
};


usersGet.propTypes = {
    search: PropTypes.string,
    offset: PropTypes.number,
};


export { usersGet };
