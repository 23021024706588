import PropTypes from 'prop-types';
import $ from 'jquery';

import { appEnv, userService as users } from 'constants';

import { getAccessToken, getAuthorization } from 'utils/cookies';


const exchangesPairsLimitsGet = async (props) => {

    const { exchange, pair } = props;

    if (!exchange || !pair) return { isSuccess: false, response: {} };

    const method = 'get';
    const { hosts, version } = users;

    const host = hosts[appEnv];

    const url = `${host}/api/${version}/exchanges/${exchange}/pairs/${pair.replace('/', '_')}/limits`;

    var auth = await getAccessToken();

    if (auth == 'undefined') auth = await getAuthorization();

    if (auth == 'undefined') return { isSucess: false, response: {}, errorType: 'Unauthorized', status: 401 };

    var settings = {
        url: url,
        method: method,
        timeout: 0,
        processData: false,
        contentType: false,
        beforeSend: (xhr) => xhr.setRequestHeader('Authorization', auth)
    };

    try {
        const response = await $.ajax(settings);
        const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;

        return { isSuccess: true, response: parsedResponse };

    } catch (error) {
        let status = 0;
        let errorType = '';


        const response = JSON.parse(error.responseText);

        if (error && error.status) {

            status = error.status;

            try {
                if ('detail' in response && 'type' in response.detail) {
                    errorType = response.detail.type;
                }
            } catch (e) {
                errorType = 'InternalServerError';
            }
        }

        let detail = response.detail;

        return {
            isSuccess: false,
            errorType,
            detail,
            status,
        };
    }
};


exchangesPairsLimitsGet.propTypes = {
    exchange: PropTypes.string.isRequired,
    pair: PropTypes.string.isRequired,
};


export { exchangesPairsLimitsGet };
