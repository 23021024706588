import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Column, Button, Title } from 'components/imports';

import './WelcomeStep.css';

const WelcomeStep = ({
    currentStep,
    onStartOnboarding
}) => {
    const { t } = useTranslation();

    return (
        <Column 
            className='steps-flow-step welcome-step' 
            id='welcome-step'
            a='center'
            j='center'
            g='1'
        >
            <Column a='center' g='1'>
                <Title 
                    txt={t('welcome-title')}
                    variation='primary'
                />
                <Title 
                    txt={t('welcome-subtitle')}
                    variation='thertiary'
                />
                <p className='welcome-description'>
                    {t('welcome-description')}
                </p>
                <p className='welcome-time'>
                    {t('welcome-time')}
                </p>
            </Column>

            <Button
                variation='primary'
                className='start-button w-12'
                onClick={onStartOnboarding}
                state='enabled'
            >
                <p>{currentStep == 0 ? t('welcome-start-button') : t('continue')}</p>
            </Button>
        </Column>
    );
};

WelcomeStep.propTypes = {
    currentStep: PropTypes.number.isRequired,
    onStartOnboarding: PropTypes.func.isRequired
};

export default WelcomeStep;