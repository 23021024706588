/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from 'locales/i18n';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';

import { handleProviderSignIn } from 'providers/signInProvider';

import { BnxLogoSvg, SmartTradeLogoByBnxSvg, BgAuthentication } from 'images/imports';
import { AppleSvg, GoogleSvg, MicrosoftSvg, XSvg } from 'icons/imports';

import { sessionsMePost, sessionsTempGet, emailsPost, sessionsMeTempMfaGet } from 'apis/imports';

import { Column, Layout, Page, Panel, Row } from 'components/imports';
import { Button, Input, Link, MessagePopup, Title, SwitchableIcon, UserCard } from 'components/imports';

import { getLanguage, getTheme } from 'utils/cookies';

import { checkHealth } from 'utils/healthCheck';

import './SignIn.css';


const SignIn = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'sign-in';
    const [appLang,] = useState(getLanguage());
    const [appTheme,] = useState(getTheme());

    const [tempToken, setTempToken] = useState('');
    const [isCodeAuth, setIsCodeAuth] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const $username = $('input[type=email]');
    const $password = $('input[type=password]');

    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    const [popUpDuration, setPopUpDuration] = useState(5000);

    const [enterCredentialButtonState, setEnterCredentialButtonState] = useState('disabled');
    const [emailSendCodeButtonState, setEmailSendCodeButtonState] = useState('disabled');
    const [submitButtonState, setSubmitButtonState] = useState('disabled');

    const [emailCode, setEmailCode] = useState(Array(6).fill(''));

    const appleIcon = <AppleSvg className='icon-svg' />;
    const googleIcon = <GoogleSvg className='icon-svg' />;
    const microsoftIcon = <MicrosoftSvg className='icon-svg' />;
    const xIcon = <XSvg className='icon-svg ' />;


    useEffect(() => {
        const quickSettings = document.querySelector('.quick-settings');
        const signInPanel = document.querySelector('#sign-in-parent');

        if (quickSettings && signInPanel) {
            signInPanel.insertBefore(quickSettings, signInPanel.firstChild);

            quickSettings.style.position = 'relative';
            quickSettings.style.width = '100%';
            quickSettings.style.marginBottom = 'var(--margin-secondary)';
        }

        return () => {
            const quickSettings = document.querySelector('.quick-settings');
            if (quickSettings) {
                document.querySelector('.layout')?.appendChild(quickSettings);
            }
        };
    }, []);


    useEffect(() => {
        checkHealth().then((result) => {
            if (!result) alert('Service Unavailable');
        });

        if (location.pathname === `/${pageId}`) {
            document.title = `${t('sign.in.v')} - Smart Trade`;

            $('#intro-logo-img').fadeIn();

            if (location?.state?.newSession) {
                setPopUpLevel('warning');
                setPopUpDuration(3500);
                setPopUpText(t('api_codes.default.401_page'));
            }
        }

    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {
        let emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setEnterCredentialButtonState(username.match(emailRegex) ? 'enabled' : 'disabled');
    }, [username]);


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        $username.removeClass('required');
    };


    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        $password.removeClass('required');
    };


    const goToSignup = () => navigate('/sign-up');


    const sendCodeToEmail = async () => {


        setEmailSendCodeButtonState('loading');
        setPopUpText(t('send-code-email'));
        setPopUpLevel('info');

        const tempTokenResult = await sessionsTempGet({ email: username });

        if (tempTokenResult.isSuccess) {

            var { access_token, token_type } = tempTokenResult.response;

            setTempToken(token_type + ' ' + access_token);

            const sendEmailresult = await emailsPost({
                email: username,
                language: appLang
            });

            if (sendEmailresult.isSuccess) {
                setEmailSendCodeButtonState('disabled');
                setTimeout(() => setEmailSendCodeButtonState('enabled'), 5000);
                setPopUpText(t('sent-code-email'));
                setPopUpLevel('success');
                return;
            }
        }

        setEmailSendCodeButtonState('error');
        setPopUpText(t('send-code-email-fail'));
        setPopUpLevel('error');
    };


    const handleSubmit = async (event) => {

        event.preventDefault();

        if (isCodeAuth) {
            // Submit when sign-in with email and code
            handleSubmitCodeAuth();
            return;
        }

        // Submit when sign-in with email and password

        if (username === '') $username.addClass('required');
        else $username.removeClass('required');

        if (password === '') $password.addClass('required');
        else $password.removeClass('required');

        if (username === '' || password === '') return;

        setSubmitButtonState('loading');

        const result = await sessionsMePost({
            username,
            password
        });

        if (result.isSuccess) {

            setSubmitButtonState('success');

            let delayToRedirect = 1500;

            setPopUpText(t('api_codes.sign-in.200_0'));
            setPopUpLevel('success');
            setPopUpDuration(delayToRedirect);

            setTimeout(() => {
                navigate('/integrity', { state: { ...result.response, username } });
            }, delayToRedirect);
        }
        else {

            setSubmitButtonState('error');

            let { status, errorType } = result;

            switch (errorType) {
                case 'UserNotFoundError':
                    setPopUpText(t('api_codes.sign-in.404_1'));
                    break;
                case 'NoMFAInformed':
                    setPopUpText(t('api_codes.sign-in.400_1'));
                    break;
                case 'InvalidMFA':
                    setPopUpText(t('api_codes.sign-in.401_1'));
                    break;
                default:
                    setPopUpText(t(`api_codes.sign-in.${status}_0`));
                    break;
            }
            setPopUpLevel('warning');
        }
    };


    const handleSubmitCodeAuth = async () => {

        setSubmitButtonState('loading');

        var oauthmfa = emailCode;

        if (oauthmfa.length != 6) {
            setPopUpText(t('mfa-6-digits'));
            setPopUpLevel('warning');
            return;
        }

        const result = await sessionsMeTempMfaGet({
            oauthmfa,
            mfa_type: 'HOTP',
            tempToken
        });

        if (result.isSuccess) {
            setSubmitButtonState('success');
            setPopUpLevel('success');
            setPopUpText(t('api_codes.sign-in.200_0'));
            setPopUpDuration(1500);

            navigate('/onboarding');
        }
    };


    return (
        <Page id='sign-in-page-base' backgroundImage={BgAuthentication}>

            <div id='background-overlay'></div>

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={false}
                page={pageId}
                showVersion={true}
                quickSettings={'minimal'}
            >
                <img id='smart-trade-logo' src={SmartTradeLogoByBnxSvg} />
                <img id='bnx-logo' src={BnxLogoSvg} />

                <Column
                    a='center'
                    fill='all'
                    j='center'
                >
                    <div id='sign-in-parent'>
                        <Panel
                            className='browser'
                            id='sign-in-panel'
                            g='2'
                            j='between'
                            p='ter'
                        >
                            <Row fill='width' g='0'>
                                <p>{t('new-here')}&nbsp;</p>

                                <Link
                                    onClick={() => goToSignup()}
                                >
                                    <p>{t('sign.up.v')}</p>
                                </Link>
                            </Row>

                            <Row fill='width' j='center'>
                                <Title variation='secondary' txt={t('sign.in.v')} />
                            </Row>

                            {submitButtonState !== 'enabled' &&
                                <form name='sign-in-form' onSubmit={() => setSubmitButtonState('enabled')}>

                                    <Row
                                        id='social-media-logins'
                                        fill='width'
                                        j='center'
                                        g='2'
                                    >
                                        {/* <SwitchableIcon
                                            id='apple'
                                            staticIcon={appleIcon}
                                        /> */}

                                        <SwitchableIcon
                                            id='google'
                                            staticIcon={googleIcon}
                                            onToggle={() => handleProviderSignIn(
                                                'GOOGLE',
                                                t,
                                                navigate,
                                                setPopUpLevel,
                                                setPopUpDuration,
                                                setPopUpText
                                            )}
                                        />

                                        <SwitchableIcon
                                            id='microsoft'
                                            staticIcon={microsoftIcon}
                                            onToggle={() => handleProviderSignIn(
                                                'MICROSOFT',
                                                t,
                                                navigate,
                                                setPopUpLevel,
                                                setPopUpDuration,
                                                setPopUpText
                                            )}
                                        />

                                        {/* <SwitchableIcon
                                            id='x'
                                            staticIcon={xIcon}
                                        /> */}
                                    </Row>

                                    <p>{t('or-continue-with-your-email')}</p>

                                    <Input
                                        id='username-input'
                                        onChange={handleUsernameChange}
                                        placeholder={t('username.s')}
                                        variation='email'
                                        value={username}
                                    />

                                    <hr />

                                    <Button
                                        className='fill-all'
                                        id='enter-credential-button'
                                        name='sign-in-form'
                                        state={enterCredentialButtonState}
                                        type='submit'
                                    >{t('sign.in.v')}</Button>
                                </form>
                            }

                            {submitButtonState === 'enabled' &&
                                <form name='sign-in-enter-credential-form' onSubmit={handleSubmit}>
                                    <UserCard
                                        onClick={() => setSubmitButtonState('disabled')}
                                        username={username}
                                        showButton={true}
                                    />

                                    {!isCodeAuth &&
                                        <>
                                            <p>{t('enter-password')}</p>

                                            <Input
                                                id='password-input'
                                                onChange={handlePasswordChange}
                                                placeholder={t('password.s')}
                                                variation='password'
                                                value={password}
                                            />
                                        </>
                                    }

                                    {isCodeAuth &&
                                        <>
                                            <p>{t('validate-email-code')}</p>

                                            <Column
                                                fill='width'
                                                a='end'
                                                g='0'
                                            >
                                                <Input
                                                    code={emailCode}
                                                    setCode={setEmailCode}
                                                    variation='mfa-code'
                                                />

                                                <Button
                                                    className='tertiary'
                                                    onClick={() => sendCodeToEmail()}
                                                    state={emailSendCodeButtonState}
                                                >{t('resend-code')}</Button>
                                            </Column>
                                        </>
                                    }

                                    <hr />

                                    <Button
                                        className='fill-all'
                                        id='submit-button'
                                        name='sign-in-form'
                                        state={submitButtonState}
                                        type='submit'
                                    >{t('sign.in.v')}</Button>

                                    {!isCodeAuth &&
                                        <>
                                            <Row a='center' g='0'>
                                                <p>{t('password.forgot')}&nbsp;</p>

                                                <Link
                                                    onClick={() => navigate('/integrity', { state: { forgotPassword: true, username: username } })}
                                                >
                                                    <p>{t('click-here')}</p>
                                                </Link>
                                            </Row>


                                            <Row a='center' g='0'>
                                                <p>{t('password.havent')}&nbsp;</p>

                                                <Link
                                                    onClick={() => {
                                                        setEmailSendCodeButtonState('enabled');
                                                        setIsCodeAuth(true);
                                                        sendCodeToEmail();
                                                    }}
                                                >
                                                    <p>{t('click-here')}</p>
                                                </Link>
                                            </Row>
                                        </>
                                    }

                                    {isCodeAuth &&
                                        <Row a='center' g='0'>
                                            <p>{t('password.have')}&nbsp;</p>

                                            <Link
                                                onClick={() => {
                                                    setEmailSendCodeButtonState('disabled');
                                                    setIsCodeAuth(false);
                                                }}
                                            >
                                                <p>{t('click-here')}</p>
                                            </Link>
                                        </Row>
                                    }
                                </form>
                            }
                        </Panel>
                    </div>
                </Column>
            </Layout>
        </Page >
    );
};

export default SignIn;
