import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { DatePicker, Select } from 'antd';

import { Panel, Row, Button, Title } from 'components/imports';

import { timeframes } from 'constants';


const FiltersPanel = (props) => {

    const { t } = useTranslation();

    const {
        userExchanges,
        exchangesFilter,
        setExchangesFilter,
        pairsFilter,
        setPairsFilter,
        sideFilter,
        setSideFilter,
        timeframesFilter,
        setTimeframesFilter,
        dateRangeFilter,
        setDateRangeFilter,
        fetchEndpoints
    } = props;

    const { RangePicker } = DatePicker;

    const [filterButtonState, setFilterButtonState] = useState('enabled');


    useEffect(() => {
        if (!dateRangeFilter || dateRangeFilter.length !== 2 || !dateRangeFilter[0] || !dateRangeFilter[1])
            setFilterButtonState('disabled');
        else
            setFilterButtonState('enabled');

    }, [dateRangeFilter]);


    const buildExchangesFilter = () =>
        userExchanges.map((exchange) => ({
            label: exchange.name,
            value: exchange.slug,
        }));


    const buildPairsFilter = () => [
        { value: 'SUI/USDT', label: 'SUI/USDT' },
        { value: 'STX/USDT', label: 'STX/USDT' },
        { value: 'JUP/USDT', label: 'JUP/USDT' },
        { value: 'WLD/USDT', label: 'WLD/USDT' },
    ];


    const buildTimeframesFilter = () =>
        timeframes.map((timeframe) => ({
            label: timeframe,
            value: timeframe,
        }));


    const buildSideFilter = () => [
        { label: t('all-sides'), value: null },
        { label: t('buy.v'), value: 'buy' },
        { label: t('sell.s'), value: 'sell' },
    ];


    return (
        <Panel id='filters-panel'
            fill='width'
            fit='height'
            g='0'
        >
            <Title variation='tertiary' txt={t('general-filters')} />

            <hr />

            <Row fill='width'>
                <Select
                    mode='multiple'
                    maxCount={2}
                    defaultValue={exchangesFilter}
                    placeholder={t('exchange.p')}
                    style={{ flex: 1 }}
                    options={buildExchangesFilter()}
                    onChange={(value) => setExchangesFilter(value)}
                />

                <Select
                    mode='tags'
                    maxCount={5}
                    defaultValue={pairsFilter}
                    placeholder={t('pair.p')}
                    style={{ flex: 1 }}
                    options={buildPairsFilter()}
                    onChange={(value) => setPairsFilter(value)}
                />

                <Select
                    mode='multiple'
                    maxCount={2}
                    defaultValue={timeframesFilter}
                    placeholder={t('timeframe.p')}
                    style={{ flex: 1 }}
                    options={buildTimeframesFilter()}
                    onChange={(value) => setTimeframesFilter(value)}
                />

                <Select
                    placeholder={t('side.s')}
                    defaultValue={sideFilter}
                    style={{ flex: 1 }}
                    options={buildSideFilter()}
                    onChange={(value) => setSideFilter(value == 'all' ? null : value)}
                />

                <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    onChange={(value) => setDateRangeFilter(value)}
                    format="YYYY-MM-DD HH:mm"
                    defaultValue={dateRangeFilter}
                />

                <Button
                    id='apply-filters'
                    variation='primary'
                    state={filterButtonState}
                    onClick={() => fetchEndpoints()}
                >
                    {t('apply')}
                </Button>
            </Row>
        </Panel>
    );
};


FiltersPanel.propTypes = {
    userExchanges: PropTypes.array,
    exchangesFilter: PropTypes.array,
    setExchangesFilter: PropTypes.func,
    pairsFilter: PropTypes.array,
    setPairsFilter: PropTypes.func,
    timeframesFilter: PropTypes.array,
    setTimeframesFilter: PropTypes.func,
    sideFilter: PropTypes.string,
    setSideFilter: PropTypes.func,
    dateRangeFilter: PropTypes.array,
    setDateRangeFilter: PropTypes.func,
    fetchEndpoints: PropTypes.func
};


export default FiltersPanel;