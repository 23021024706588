/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';

import { handleProviderSignIn } from 'providers/signInProvider';

import { BnxLogoSvg, SmartTradeLogoByBnxSvg, BgAuthentication } from 'images/imports';
import { AppleSvg, GoogleSvg, MicrosoftSvg, XSvg } from 'icons/imports';

import { Page, Column, Layout, MessagePopup, Panel, Row, SwitchableIcon } from 'components/imports';
import { Button, Input, Link, Title } from 'components/imports';

import { emailsPost, sessionsMeTempMfaGet, sessionsTempGet, usersMePost } from 'apis/imports';

import { UserCard } from 'components/imports';

// Store 
import useGlobalStore from 'stores/GlobalStore';

import './SignUp.css';


const SignUp = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'sign-up';

    const {
        appLang,
        setAppLang,
    } = useGlobalStore();

    const [tempToken, setTempToken] = useState('');

    const [username, setUsername] = useState('');

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isEmailMfaValid, setIsEmailMfaValid] = useState(false);

    const $username = $('#sign-up-username-input');

    // From MessagePopup component
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');
    const [popUpDuration, setPopUpDuration] = useState(5000);

    const [currentSubstep, setCurrentSubstep] = useState(0);
    const [lastSubstepsPerStep, setlastSubstepsPerStep] = useState({});

    const [emailCode, setEmailCode] = useState(Array(6).fill(''));

    const [emailSendCodeButtonState, setEmailSendCodeButtonState] = useState('disabled');
    const [validateCodeButtonState, setValidateCodeButtonState] = useState('disabled');
    const [signupButtonState, setSignupButtonState] = useState('disabled');

    const appleIcon = <AppleSvg className='icon-svg' />;
    const googleIcon = <GoogleSvg className='icon-svg' />;
    const microsoftIcon = <MicrosoftSvg className='icon-svg' />;
    const xIcon = <XSvg className='icon-svg ' />;


    useEffect(() => {
        const quickSettings = document.querySelector('.quick-settings');
        const signInPanel = document.querySelector('#sign-up-parent');

        if (quickSettings && signInPanel) {
            signInPanel.insertBefore(quickSettings, signInPanel.firstChild);

            quickSettings.style.position = 'relative';
            quickSettings.style.width = '100%';
            quickSettings.style.marginBottom = 'var(--margin-secondary)';
        }

        return () => {
            const quickSettings = document.querySelector('.quick-settings');
            if (quickSettings) {
                document.querySelector('.layout')?.appendChild(quickSettings);
            }
        };
    }, []);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('sign.up.v')} - Smart Trade`;
        }
    }, [location.pathname]);


    useEffect(() => {
        let emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setIsEmailValid(username.match(emailRegex));
    }, [username]);


    useEffect(() => {
        let filteredCode = emailCode.filter(item => item !== '');
        setValidateCodeButtonState(filteredCode.length === 6 ? 'enabled' : 'disabled');
    }, [emailCode]);


    useEffect(() => {
        setEmailCode(Array(6).fill(''));

        let lastSubstepsPerStepAux = lastSubstepsPerStep;

        if (!('0' in lastSubstepsPerStepAux) || currentSubstep != lastSubstepsPerStepAux['0']) {
            lastSubstepsPerStepAux['0'] = currentSubstep;
            setlastSubstepsPerStep(lastSubstepsPerStepAux);
        }

    }, [currentSubstep]);


    useEffect(() => {
        const isUserElegible = isEmailValid && isEmailMfaValid;
        setEmailSendCodeButtonState(isEmailValid ? 'enabled' : 'disabled');
        setValidateCodeButtonState(isUserElegible ? 'enabled' : 'disabled');
    }, [isEmailValid, isEmailMfaValid]);


    const resetSignUp = () => {
        // Browser dialog
        let result = confirm(t('confirm-reset-sign-up'));

        if (!result) { return; }

        setIsCodeSent(false);
        setCurrentSubstep(0);

        $username.prop('disabled', false);
    };


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        $username.removeClass('required');
    };


    const goToSignin = () => {
        setTimeout(() => navigate('/sign-in'));
    };


    const sendCodeToEmail = async () => {

        if (isEmailValid) {
            setEmailSendCodeButtonState('loading');
            setPopUpText(t('send-code-email'));
            setPopUpLevel('info');

            const tempTokenResult = await sessionsTempGet({ email: username });

            if (tempTokenResult.isSuccess) {

                var { access_token, token_type } = tempTokenResult.response;

                setTempToken(token_type + ' ' + access_token);

                const sendEmailresult = await emailsPost({
                    email: username,
                    language: appLang
                });

                if (sendEmailresult.isSuccess) {
                    setIsCodeSent(true);
                    setEmailSendCodeButtonState('disabled');
                    setTimeout(() => setEmailSendCodeButtonState('enabled'), 5000);
                    setPopUpText(t('sent-code-email'));
                    setPopUpLevel('success');
                    setCurrentSubstep(1);
                    return;
                }
            }

            setEmailSendCodeButtonState('error');
            setPopUpText(t('send-code-email-fail'));
            setPopUpLevel('error');
        }
        else {
            setPopUpText(t('invalid-email-try-again'));
            setPopUpLevel('warning');
        }
    };


    const handleSubmit = async (id) => {

        setValidateCodeButtonState('loading');

        var oauthmfa = $(id).val();

        if (oauthmfa.length != 6) {
            setPopUpText(t('mfa-6-digits'));
            setPopUpLevel('warning');
            return;
        }

        const result = await sessionsMeTempMfaGet({
            oauthmfa,
            mfa_type: 'HOTP',
            tempToken
        });

        if (result.isSuccess) {
            $username.prop('disabled', true);

            setIsEmailMfaValid(true);
            setCurrentSubstep(0);

            if (username === '') {
                setValidateCodeButtonState('error');
                $username.addClass('required');
            }
            else $username.removeClass('required');

            const result = await usersMePost({ username });

            let { isSuccess, status, errorType } = result;

            if (isSuccess) {
                setValidateCodeButtonState('success');
                setSignupButtonState('enabled');
                setPopUpLevel('success');
                setPopUpText(t('api_codes.sign-up.201_0'));
                setPopUpDuration(1500);
            }
            else {
                setValidateCodeButtonState('error');
                switch (errorType) {
                    case 'AlreadyExistsError':
                        setPopUpText(t(`api_codes.sign-up.${status}_1`));
                        break;
                    default:
                        setPopUpText(t(`api_codes.default.${status}_0`));
                        break;
                }
                setPopUpLevel('warning');
            }
        }
    };


    


    return (
        <Page id='sign-up-page-base' backgroundImage={BgAuthentication}> {/** className={isLeaving ? 'leaving' : ''} */}

            <div id="background-overlay"></div>

            <MessagePopup
                duration={popUpDuration}
                level={popUpLevel}
                text={popUpText}
            />

            <Layout
                checkAuth={false}
                page={pageId}
                showVersion={true}
                quickSettings='minimal'
                setAppLang={setAppLang}
            >
                <img id='smart-trade-logo' src={SmartTradeLogoByBnxSvg} />
                <img id='bnx-logo' src={BnxLogoSvg} />

                <Column
                    a='center'
                    fill='all'
                    j='center'
                >
                    <div id='sign-up-parent'>
                        <Panel
                            className='browser'
                            g='2'
                            id='sign-up-panel'
                            p='ter'
                        >
                            <Row a='center' g='0'>
                                <p>{t('already-sign-uped')}&nbsp;</p>

                                <Link
                                    onClick={() => {
                                        let result = confirm(t('confirm-reset-sign-up'));
                                        if (result) goToSignin();
                                    }}
                                >
                                    <p>{t('sign.in.v')}</p>
                                </Link>
                            </Row>

                            <Row fill='width' j='center'>
                                <Title variation='secondary' txt={t('sign.up.v')} />
                            </Row>

                            <Column fill='width'>
                                <div id='sign-up-form'>
                                    <Column
                                        fill='width'
                                        g='1'
                                    >

                                        {isCodeSent &&
                                            <UserCard
                                                onClick={() => resetSignUp()}
                                                username={username}
                                                showButton={signupButtonState !== 'enabled'}
                                            />
                                        }

                                        <Column fill='width' id='step-content'>

                                            {!isCodeSent &&
                                                <form
                                                    className='steps-flow-step g-1'
                                                    name='send-email-code'
                                                    id='step-0-0'
                                                >
                                                    <Row
                                                        id='social-media-logins'
                                                        fill='width'
                                                        j='center'
                                                        g='2'
                                                    >
                                                        {/* <SwitchableIcon
                                                            id='apple'
                                                            staticIcon={appleIcon}
                                                        /> */}

                                                        <SwitchableIcon
                                                            id='google'
                                                            staticIcon={googleIcon}
                                                            onToggle={() => handleProviderSignIn(
                                                                'GOOGLE',
                                                                t,
                                                                navigate,
                                                                setPopUpLevel,
                                                                setPopUpDuration,
                                                                setPopUpText
                                                            )}
                                                        />

                                                        <SwitchableIcon
                                                            id='microsoft'
                                                            staticIcon={microsoftIcon}
                                                            onToggle={() => handleProviderSignIn(
                                                                'MICROSOFT',
                                                                t,
                                                                navigate,
                                                                setPopUpLevel,
                                                                setPopUpDuration,
                                                                setPopUpText
                                                            )}
                                                        />

                                                        {/* <SwitchableIcon
                                                            id='x'
                                                            staticIcon={xIcon}
                                                        /> */}
                                                    </Row>

                                                    <p>{t('or-continue-with-your-email')}</p>

                                                    <Input

                                                        id='sign-up-username-input'
                                                        onChange={handleUsernameChange}
                                                        placeholder={t('username.s')}
                                                        variation='email'
                                                        value={username}
                                                    />

                                                    <hr />

                                                    <Button
                                                        name='send-email-code'
                                                        id='step-0-0-btn-send'
                                                        state={emailSendCodeButtonState}
                                                        type='submit'
                                                        onClick={() => sendCodeToEmail()}
                                                    >
                                                        {t('send-code')}
                                                    </Button>
                                                </form>
                                            }

                                            <form
                                                className='steps-flow-step g-1'
                                                name='validate-email'
                                                hidden
                                                id='step-0-1'
                                            >
                                                {isCodeSent && signupButtonState !== 'enabled' &&
                                                    <>
                                                        <p>{t('validate-email-code')}</p>

                                                        <Column
                                                            fill='width'
                                                            a='end'
                                                            g='0'
                                                        >
                                                            <Input
                                                                code={emailCode}
                                                                id='oauth-input-0-1'
                                                                setCode={setEmailCode}
                                                                variation='mfa-code'
                                                            />

                                                            <Button
                                                                id='step-0-0-btn-resend'
                                                                className='tertiary'
                                                                onClick={() => sendCodeToEmail()}
                                                                state={emailSendCodeButtonState}
                                                            >{t('resend-code')}</Button>
                                                        </Column>

                                                        <hr />

                                                        <Button
                                                            id='step-0-1-btn-validate'
                                                            className='primary fill-width'
                                                            disabled={!(currentSubstep == 1)}
                                                            name='validate-email'
                                                            onClick={() => handleSubmit('#oauth-input-0-1-result')}
                                                            state={validateCodeButtonState}
                                                        >
                                                            {t('sign.up.i')}
                                                        </Button>
                                                    </>
                                                }

                                                {signupButtonState === 'enabled'
                                                    && <Column id='step-4-0' hidden>
                                                        <p>{t('done-sign-up')}</p>

                                                        <hr />

                                                        <Button
                                                            className='primary fill-all'
                                                            id='sign-up-button'
                                                            onClick={() => navigate('/onboarding')}
                                                            state={signupButtonState}
                                                            type='submit'
                                                        >{t('go-to-onboarding')}</Button>
                                                    </Column>
                                                }
                                            </form>
                                        </Column>
                                    </Column>
                                </div>
                            </Column>
                        </Panel>
                    </div>
                </Column>
            </Layout>
        </Page>
    );
};

export default SignUp;
