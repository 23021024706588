import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Switch, Radio } from 'antd';

import { Column, Row, Title, Input, Button } from 'components/imports';
import { StrategiesModal } from 'components/imports';
import { StrategyOverviewCard } from 'components/imports';

import { getGlobalAssetsQuotes } from 'utils/assets';

import {
    atsModesPairsGet,
    atsModesPairsPost,
    atsModesPairsPatch,
    atsPresetsTypesIdsGet,
    exchangesPairsLimitsGet,
    portfoliosMeGet,
} from 'apis/imports';

import Skeleton from 'react-loading-skeleton';

import './AtsStep.css';

const AtsStep = ({
    pageId,
    location,
    currentStep,
    connectedExchanges,
    onContinue,
    hidden
}) => {

    const { t } = useTranslation();

    const [userBaseAssets, setUserBaseAssets] = useState([]);

    const [globalAssetsQuotes,] = useState(getGlobalAssetsQuotes({}));

    const [pairAllocationMode,] = useState('default');

    const [isNewPairAllocation, setIsNewPairAllocation] = useState(true);
    const [pairAllocation, setPairAllocation] = useState(null);
    const [pairAllocationStrategy, setPairAllocationStrategy] = useState(null);
    const [pairAllocationLimits, setPairAllocationLimits] = useState(null);

    const [baseSymbol, setBaseSymbol] = useState(null);
    const [quoteSymbol, setQuoteSymbol] = useState(null);

    const [isLoadingDetailsLimits, setIsLoadingDetailsLimits] = useState(false);

    const [savePairAllocationButtonState, setSavePairAllocationButtonState] = useState('disabled');

    const [strategiesModalMode, setStrategiesModalMode] = useState(null);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            fetchPairAllocation();
        }
    }, [location.pathname]);


    useEffect(() => {
        console.log(currentStep);
        if (currentStep === 4) fetchUserPortfolioAssets();
    }, [currentStep]);


    useEffect(() => {

        if (pairAllocation?.pair) {
            const [baseSymbol, quoteSymbol] = pairAllocation.pair.split('/');
            setBaseSymbol(baseSymbol);
            setQuoteSymbol(quoteSymbol);
        }

        if (pairAllocation?.exchange_slug &&
            pairAllocation?.pair &&
            pairAllocation?.config?.quote?.allocation &&
            pairAllocation?.preset?.id &&
            pairAllocation?.preset?.timeframes?.length > 0 &&
            (pairAllocation?.config?.base?.allocation &&
                pairAllocationLimits?.base?.allocation?.min &&
                pairAllocation.config.base.allocation >= pairAllocationLimits?.base?.allocation?.min) &&
            (pairAllocation?.config?.quote?.allocation &&
                pairAllocationLimits?.quote?.allocation?.min &&
                pairAllocation.config.quote.allocation >= pairAllocationLimits?.quote?.allocation?.min)
        )
            setSavePairAllocationButtonState('enabled');
        else setSavePairAllocationButtonState('disabled');

    }, [pairAllocation]);


    useEffect(() => {

        if (baseSymbol && quoteSymbol) {
            const pairAllocationAux = { ...pairAllocation };
            pairAllocationAux.pair = `${baseSymbol}/${quoteSymbol}`;
            setPairAllocation(pairAllocationAux);
            fetchExchangePairLimits(pairAllocationAux);
        }

    }, [baseSymbol, quoteSymbol]);

    useEffect(() => {

        if (!pairAllocationStrategy) return;

        const { type, id, timeframes } = pairAllocationStrategy;

        setPairAllocation({ ...pairAllocation, preset: { type, id, timeframes } });

    }, [pairAllocationStrategy]);


    const fetchPairAllocation = async () => {
        let pairAllocation = await atsModesPairsGet({ mode: pairAllocationMode });

        if (!pairAllocation.isSuccess || pairAllocation.response.items.length === 0) {

            setIsNewPairAllocation(true);

            setPairAllocation({
                enabled: false,
                sells_only: false,
                config: {
                    base: {
                        type: 'percentage',
                        allocation: 100
                    },
                    quote: {
                        type: 'balance',
                        allocation: 10
                    }
                },
                exchange_slug: null,
                pair: null,
                preset: null
            });

            setBaseSymbol(null);
            setQuoteSymbol(null);

        }

        else {
            setIsNewPairAllocation(false);

            setSavePairAllocationButtonState('enabled');

            const firstPairAllocation = pairAllocation.response.items[0];
            const [baseSymbol, quoteSymbol] = firstPairAllocation.pair.split('/');

            setPairAllocation(firstPairAllocation);

            setBaseSymbol(baseSymbol);
            setQuoteSymbol(quoteSymbol);

            let preset = await atsPresetsTypesIdsGet({
                type: firstPairAllocation.preset.type,
                id: firstPairAllocation.preset.id,
                metadataOnly: true
            });

            if (preset.isSuccess) setPairAllocationStrategy(preset.response);
        }

        fetchUserPortfolioAssets();
    };


    const fetchUserPortfolioAssets = async () => {

        const result = await portfoliosMeGet();

        if (result.isSuccess) {
            setUserBaseAssets(result.response.assets.monitored.reduce((assets, asset) => {
                const [symbol, details] = Object.entries(asset)[0];
                assets.push({
                    symbol,
                    ...details
                });
                return assets;
            }, []));
        }
    };

    const fetchExchangePairLimits = async (pairAllocation) => {

        if (!pairAllocation ||
            !pairAllocation.exchange_slug ||
            !pairAllocation.pair) return;

        setIsLoadingDetailsLimits(true);

        const result = await exchangesPairsLimitsGet({
            exchange: pairAllocation.exchange_slug,
            pair: pairAllocation.pair
        });

        if (result.isSuccess) setPairAllocationLimits(result.response);

        setIsLoadingDetailsLimits(false);
    };


    const postPairAllocation = async () => {

        let data = { ...pairAllocation };

        setSavePairAllocationButtonState('loading');

        const result = await atsModesPairsPost({
            mode: pairAllocationMode,
            exchangeSlug: data.exchange_slug,
            pair: data.pair,
            data
        });

        if (result.isSuccess) {
            setSavePairAllocationButtonState('success');
        }
        else setSavePairAllocationButtonState('error');
    };


    const patchPairAllocation = async () => {

        let data = { ...pairAllocation };

        setSavePairAllocationButtonState('loading');

        const result = await atsModesPairsPatch({
            mode: pairAllocationMode,
            pairId: pairAllocation.pair_id,
            data
        });

        if (result.isSuccess) {
            setSavePairAllocationButtonState('success');
        }
        else setSavePairAllocationButtonState('error');
    };


    const handleChangePairAllocationEnabled = (checked) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.enabled = checked;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationSellsOnly = (checked) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.sells_only = checked;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationExchangeSlug = (exchangeSlug) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.exchange_slug = exchangeSlug;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationBaseSymbol = (value) => setBaseSymbol(value);


    const handleChangePairAllocationBaseType = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.config.base.type = event.target.value;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationBaseAllocation = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };

        let value = event.target.value;

        if (pairAllocationAux.config.base.type === 'percentage')
            value = +(value.replace(['%', ' ', '.', ','], '')) / 100;

        pairAllocationAux.config.base.allocation = value;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationQuoteSymbol = (value) => setQuoteSymbol(value);


    const handleChangePairAllocationQuoteType = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.config.quote.type = event.target.value;
        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationQuoteAllocation = (event) => {
        event.preventDefault();
        let pairAllocationAux = { ...pairAllocation };
        let value = event.target.value;

        if (pairAllocationAux.config.quote.type === 'percentage')
            value = (value / 100).toFixed(2);

        pairAllocationAux.config.quote.allocation = value;

        setPairAllocation(pairAllocationAux);
    };


    const handleChangePairAllocationTimeframe = (values) => {
        let pairAllocationAux = { ...pairAllocation };
        pairAllocationAux.preset.timeframes = values;
        setPairAllocation(pairAllocationAux);
    };


    const formatAssetPercentage = (asset) => {

        let { allocation } = asset;
        allocation *= 100;

        if (allocation > 100) allocation = 100;
        if (allocation < 0) allocation = 0;

        return (allocation).toFixed(0);
    };


    const buildBasesFilter = () => {
        if (!userBaseAssets || !pairAllocation?.exchange_slug) return [];

        return userBaseAssets.map(asset => {
            const { symbol, exchanges } = asset;
            return exchanges.includes(pairAllocation.exchange_slug) ? { label: symbol, value: symbol } : null;
        }).filter(symbol => symbol !== null).sort((a, b) => a.label.localeCompare(b.label));
    };


    const buildQuotesFilter = () => {
        if (!globalAssetsQuotes) return [];

        return globalAssetsQuotes.map((asset) => ({
            label: asset.symbol,
            value: asset.symbol
        }));
    };


    return (
        <>
            <StrategiesModal
                setMode={setStrategiesModalMode}
                mode={strategiesModalMode}
                pairAllocationMode={pairAllocationMode}
                selectedStrategy={pairAllocationStrategy}
                pairAllocation={pairAllocation}
                setPairAllocationStrategy={setPairAllocationStrategy}
            />

            <Column
                className={`ats-step ${hidden ? 'hidden' : ''}`}
                g='2'
                fill='all'
                scroll
            >
                <Title txt={t('configure-the-advanced-trading-system')} variation='primary' />

                <p className='description'>
                    {t('advanced-trading-system-description')}
                </p>

                <Row
                    a='start'
                    fill='width'
                    j='between'
                >
                    <Column fill='width' w='10'>
                        <Title variation='tertiary' txt={t('exchange.s')} />

                        <Select
                            value={pairAllocation?.exchange_slug}
                            placeholder={t('select-exchange')}
                            onChange={handleChangePairAllocationExchangeSlug}
                            options={connectedExchanges.map((conn) => ({ label: conn.exchange.name, value: conn.exchange.slug }))}
                        />
                    </Column>

                    <Column fill='width' w='10'>
                        <Title variation='tertiary' txt={t('settings')} />

                        <Row g='1'>
                            <Switch
                                checked={pairAllocation?.enabled}
                                onChange={handleChangePairAllocationEnabled}
                            />

                            <p>{t('enabled')}</p>
                        </Row>

                        <Row g='1'>
                            <Switch
                                checked={pairAllocation?.sells_pnly}
                                onChange={handleChangePairAllocationSellsOnly}
                            />

                            <p>{t('sells-only')}</p>
                        </Row>
                    </Column>
                </Row>

                <hr />

                <Row
                    a='start'
                    fill='width'
                    j='between'
                >
                    {/* Base */}
                    <Column
                        a='start'
                        fill='width'
                        g='2'
                        w='10'
                    >
                        <Column fill='width' g='1'>
                            <Title variation='tertiary' txt={t('base-asset.s')} />

                            <Select
                                value={baseSymbol}
                                placeholder={t('select-base-asset')}
                                onChange={handleChangePairAllocationBaseSymbol}
                                options={buildBasesFilter()}
                            />

                            {baseSymbol &&
                                <>
                                    <Title variation='tertiary' txt={t('allocation.s')} />

                                    <Row fill='width' j='center'>
                                        <Radio.Group
                                            className='fill-width'
                                            onChange={handleChangePairAllocationBaseType}
                                            value={pairAllocation?.config?.base.type}
                                        >
                                            <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                            <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                        </Radio.Group>
                                    </Row>

                                    <Row fill='width' j='between'>
                                        <Row w='8'>
                                            <p className='side-label'>{t('min')}</p>

                                            {isLoadingDetailsLimits &&
                                                <Skeleton width='3rem' height='1rem' />
                                            }

                                            {!isLoadingDetailsLimits &&
                                                <p className='b'>
                                                    {pairAllocationLimits?.base?.allocation?.min || '-'} {baseSymbol}
                                                </p>
                                            }
                                        </Row>

                                        <Column w='16'>
                                            {pairAllocation?.config?.base?.type == 'percentage' &&
                                                <Input
                                                    variation='asset-percentage'
                                                    value={formatAssetPercentage(pairAllocation?.config?.base)}
                                                    onChange={handleChangePairAllocationBaseAllocation}
                                                    placeholder={t('allocation.s')}
                                                    currency={'% ' + baseSymbol}
                                                />}

                                            {pairAllocation?.config?.base?.type == 'balance' &&
                                                <Input
                                                    variation='asset-balance'
                                                    value={pairAllocation?.config?.base.allocation}
                                                    onChange={handleChangePairAllocationBaseAllocation}
                                                    placeholder={t('allocation.s')}
                                                    currency={baseSymbol}
                                                    min={pairAllocation?.config?.base?.allocation?.min}
                                                    max={pairAllocation?.config?.base?.allocation?.max}
                                                />}
                                        </Column>
                                    </Row>
                                </>}
                        </Column>
                    </Column>

                    {/* Quote */}
                    <Column
                        a='start'
                        fill='width'
                        g='2'
                        w='10'
                    >
                        <Column fill='width' g='1'>
                            <Title variation='tertiary' txt={t('quote-asset.s')} />

                            <Select
                                value={quoteSymbol}
                                placeholder={t('select-quote-asset')}
                                onChange={handleChangePairAllocationQuoteSymbol}
                                options={buildQuotesFilter()}
                            />

                            {quoteSymbol &&
                                <>
                                    <Title variation='tertiary' txt={t('allocation.s')} />

                                    <Row fill='width' j='center'>
                                        <Radio.Group
                                            className='fill-width'
                                            onChange={handleChangePairAllocationQuoteType}
                                            value={pairAllocation?.config?.quote.type}
                                        >
                                            <Radio.Button className='w-12' value='balance'>{t('balance.s')}</Radio.Button>
                                            <Radio.Button className='w-12' value='percentage'>{t('percentage.s')}</Radio.Button>
                                        </Radio.Group>
                                    </Row>

                                    <Row fill='width' j='between'>
                                        <Row w='8'>
                                            <p className='side-label'>{t('min')}</p>

                                            {isLoadingDetailsLimits &&
                                                <Skeleton width='3rem' height='1rem' />
                                            }

                                            {!isLoadingDetailsLimits &&
                                                <p className='b'>{pairAllocationLimits?.quote?.allocation?.min || '-'} {quoteSymbol}</p>
                                            }
                                        </Row>

                                        <Column w='16'>
                                            {pairAllocation?.config?.quote?.type == 'percentage' && <Input
                                                variation='asset-percentage'
                                                value={formatAssetPercentage(pairAllocation?.config?.quote)}
                                                onChange={handleChangePairAllocationBaseAllocation}
                                                placeholder={t('allocation.s')}
                                                currency={'%' + quoteSymbol}
                                            />}

                                            {pairAllocation?.config?.quote?.type == 'balance' && <Input
                                                variation='asset-balance'
                                                value={pairAllocation?.config?.quote.allocation}
                                                onChange={handleChangePairAllocationQuoteAllocation}
                                                placeholder={t('allocation.s')}
                                                currency={quoteSymbol}
                                                min={pairAllocation?.config?.quote?.allocation?.min}
                                                max={pairAllocation?.config?.quote?.allocation?.max}
                                            />}
                                        </Column>
                                    </Row>
                                </>}
                        </Column>
                    </Column>
                </Row>

                {baseSymbol && quoteSymbol &&
                    <>
                        <hr />

                        <Column fill='width'>
                            <Title variation='tertiary' txt={t('strategy.s')} />

                            {!pairAllocationStrategy &&
                                <Row>
                                    <Button
                                        onClick={() => setStrategiesModalMode('all')}
                                    >
                                        {t('select-strategy')}
                                    </Button>
                                </Row>
                            }

                            {pairAllocationStrategy &&
                                <>
                                    <StrategyOverviewCard
                                        strategy={pairAllocationStrategy}
                                        basic={true}
                                        setStrategiesModalMode={setStrategiesModalMode}
                                    />

                                    <hr />

                                    <Title variation='tertiary' txt={t('timeframe.s')} />

                                    <Select
                                        style={{ width: '15rem' }}
                                        value={pairAllocation?.preset?.timeframes ?? []}
                                        mode='multiple'
                                        options={(pairAllocationStrategy?.timeframes ?? []).map((timeframe) => ({
                                            label: timeframe,
                                            value: timeframe
                                        }))}
                                        placeholder={t('select-timeframe')}
                                        onChange={handleChangePairAllocationTimeframe}
                                    />
                                </>
                            }
                        </Column>

                    </>}
            </Column>

            <Row fill='width' j='center' style={{ marginTop: '1rem' }}>
                <Button
                    onClick={() => {
                        if (isNewPairAllocation) postPairAllocation();
                        else patchPairAllocation();

                        onContinue();
                    }}
                    className='continue-button w-12'
                    variation='primary'
                    state={savePairAllocationButtonState}
                >
                    {t('save')}
                </Button>
            </Row>
        </>
    );
};

AtsStep.propTypes = {
    pageId: PropTypes.string,
    location: PropTypes.object,
    currentStep: PropTypes.number.isRequired,
    connectedExchanges: PropTypes.array,
    onContinue: PropTypes.func.isRequired,
    hidden: PropTypes.bool
};

export default AtsStep;