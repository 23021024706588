import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Column, Button, Input, Bar, Row, Title } from 'components/imports';
import { Tooltip } from 'antd';
import { InfoSvg } from 'icons/imports';
import { passwordVerify } from 'utils/passwords';
import { usersMeCredentialsPatch } from 'apis/imports';

import './SecurePasswordStep.css';

const SecurePasswordStep = ({ 
    onContinue, 
    setPopUpLevel, 
    setPopUpText, 
    setPopUpDuration 
}) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [progressBar, setProgressBar] = useState(0);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

    const [buttonState, setButtonState] = useState('disabled');

    const [serviceConstants, setServiceConstants] = useState({});

    useEffect(() => {
        setServiceConstants({
            'UserModel': {
                'password': {
                    'length': {
                        'max': 30,
                        'min': 8
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        const $password = document.getElementById('sign-up-password-input');
        const $confirmPassword = document.getElementById('sign-up-confirm-password-input');
        const $hintText = document.querySelector('.password-hints');

        $password?.classList.remove('required');

        if (serviceConstants.UserModel) {
            const { min: passwordMinLength, max: passwordMaxLength } = serviceConstants.UserModel.password.length;

            const result = passwordVerify({
                value: password,
                passwordMinLength,
                passwordMaxLength,
                message: 'password.required',
            });

            const { barWidth, strongPass, message } = result;

            let translatedMessage = t(message);
            if (translatedMessage.includes('{}')) {
                const lengthValue = password.length < passwordMinLength ? passwordMinLength : passwordMaxLength;
                translatedMessage = translatedMessage.replace('{}', lengthValue);
            }

            if ($hintText) $hintText.textContent = translatedMessage;

            if (confirmPassword.length > 0) {
                $confirmPassword?.classList.remove('required');
                const passwordsMatch = password === confirmPassword;
                setIsPasswordValid(strongPass && passwordsMatch);
                setButtonState(strongPass && passwordsMatch ? 'enabled' : 'disabled');
            } else {
                setIsPasswordValid(false);
                setButtonState('disabled');
            }

            setProgressBar(barWidth * 10);
        }
    }, [password, confirmPassword, t, serviceConstants]);

    const handlePasswordChange = (event) => {
        const $password = document.getElementById('sign-up-password-input');
        setPassword(event.target.value);
        $password?.classList.remove('required');

        if (!event.target.value) {
            $password?.classList.add('required');
            setButtonState('error');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const $confirmPassword = document.getElementById('sign-up-confirm-password-input');
        setConfirmPassword(event.target.value);
        $confirmPassword?.classList.remove('required');

        if (!event.target.value) {
            $confirmPassword?.classList.add('required');
            setButtonState('error');
        }
    };

    const handleContinue = async () => {
        const $password = document.getElementById('sign-up-password-input');
        const $confirmPassword = document.getElementById('sign-up-confirm-password-input');

        let isValid = true;

        if (!password) {
            $password?.classList.add('required');
            isValid = false;
        }

        if (!confirmPassword) {
            $confirmPassword?.classList.add('required');
            isValid = false;
        }

        if (!isValid && !isPasswordValid) {
            setButtonState('error');
            return;
        }

        setButtonState('loading');

        try {
            const result = await usersMeCredentialsPatch({
                password
            });

            const { isSuccess, status, errorType } = result;

            if (isSuccess) {
                setButtonState('success');
                setPopUpLevel('success');
                setPopUpText(t('api_codes.sign-up.201_0'));
                setPopUpDuration(1500);

                onContinue();
            } else {
                setButtonState('error');
                switch (errorType) {
                    case 'AlreadyExistsError':
                        setPopUpText(t(`api_codes.sign-up.${status}_1`));
                        break;
                    default:
                        setPopUpText(t(`api_codes.default.${status}_0`));
                        break;
                }
                setPopUpLevel('warning');
            }
        } catch (error) {
            setButtonState('error');
            setPopUpLevel('error');
            setPopUpText(t('api_codes.default.500_0'));
        }
    };

    return (
        <>
            <Column className='step-secure-password' g='2'>
                <Title
                    txt={t('step-secure-password')}
                    variation='primary'
                />

                <Column g='1' j='center' a='center'>
                    <Input
                        id='sign-up-password-input'
                        onChange={handlePasswordChange}
                        placeholder={t('password.s')}
                        variation='password'
                        value={password}
                    />

                    <Input
                        id='sign-up-confirm-password-input'
                        onChange={handleConfirmPasswordChange}
                        placeholder={t('password.confirm')}
                        variation='password'
                        value={confirmPassword}
                    />

                    <Bar progress={progressBar} />

                    <Row>
                        <Tooltip
                            color='#1D96EE'
                            open={showPasswordRequirements}
                            title={
                                <span>
                                    {t('password.hints.0')}<br />
                                    {t('password.hints.1')}<br />
                                    {t('password.hints.2')}<br />
                                    {t('password.hints.3')}<br />
                                    {t('password.hints.4')}<br />
                                    {t('password.hints.5')}<br />
                                    {t('password.hints.6')}<br />
                                </span>
                            }
                        >
                            <Row id='icon-container' onClick={() => setShowPasswordRequirements(!showPasswordRequirements)}>
                                <InfoSvg className='icon-svg yes' />
                            </Row>
                        </Tooltip>

                        <p className='password-hints'>{t('password.required')}</p>
                    </Row>

                    <b id='password-msg' className="error-message">
                        {confirmPassword.length > 0 && password !== confirmPassword ? t('password.mismatch') : ''}
                    </b>
                </Column>
            </Column>

            <Row fill='width' j='center'>
                <Button
                    variation='primary'
                    onClick={handleContinue}
                    className='continue-button w-12'
                    state={buttonState}
                >
                    {t('continue')}
                </Button>
            </Row>
        </>
    );
};

SecurePasswordStep.propTypes = {
    onContinue: PropTypes.func.isRequired,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func,
    setPopUpDuration: PropTypes.func
};

export default SecurePasswordStep;