import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


// Base Components
import { Panel, Column, Row, Title } from 'components/imports';

// Icon Buttons
import { SwitchableIcon } from 'components/imports';

// Tables Components
import { SignalsTable } from 'components/imports';

import { RefreshSvg } from 'icons/imports';
import useGlobalStore from 'stores/GlobalStore';


const SignalsPanel = (props) => {

    const { t } = useTranslation();

    const {
        appLang
    } = useGlobalStore();


    const { isUserAuthenticated, userExchanges, signalsData, fetchSignals, isLoading } = props;

    const refreshSignalsIcon = <RefreshSvg className={'icon-svg refresh' + (isLoading ? ' spinning' : '')} />;


    return (
        <Panel id='signals-panel'
            fill='all'
            g='0'
        >
            <Row fill='width' j='between'>
                <Title id='signals-title' txt={t('signal.p')} variation='tertiary' />

                <SwitchableIcon
                    id='refresh-signals'
                    onToggle={() => fetchSignals()}
                    staticIcon={refreshSignalsIcon}
                />
            </Row>

            <hr />

            {
                isLoading || !isUserAuthenticated
                    ? (<Row
                        fill='width'
                        j='between'
                    >
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'10rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                        <Skeleton
                            className='skeleton'
                            count={2}
                            height={'2.5rem'}
                            style={{ marginBottom: '.75rem' }}
                            width={'5rem'}
                        />
                    </Row>)
                    : (signalsData.length === 0
                        ? (<Row
                            a='center'
                            fill='all'
                            j='center'
                        >
                            <Column
                                a='center'
                                fit='all'
                                j='center'
                            >
                                <p>{t('no-results')}</p>
                            </Column>
                        </Row>)
                        : <SignalsTable id='signals'
                            exchanges={userExchanges}
                            items={signalsData}
                            lang={appLang}
                        />
                    )
            }
        </Panel>
    );
};


SignalsPanel.propTypes = {
    isUserAuthenticated: PropTypes.bool.isRequired,
    userExchanges: PropTypes.array.isRequired,
    fetchSignals: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    signalsData: PropTypes.array.isRequired,
};


export default SignalsPanel;
