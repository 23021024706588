/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import i18n from 'locales/i18n';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

// Panels
import FiltersPanel from './components/FiltersPanel';
import PnLPerOperationPanel from './components/PnLPerOperation';
import SignalsPanel from './components/SignalsPanel';
import OrdersPanel from './components/OrdersPanel';

// Base Componetns
import {
    Page, Layout, Loading, MessagePopup, Row, Column
} from 'components/imports';


// Endpoints
import { signalsUsersMeGet, ordersMeGet, exchangesOhlcvGet } from 'apis/imports';

import { getAssetBase } from 'utils/assets-bases';

import { getUserConnectedExchanges } from 'utils/exchanges';
import { getTrendsMarketcaps } from 'utils/trends-marketcaps';

import {
    getSelectedCurrency, getSelectedExchange, getSelectedTimeframe, getLanguage,
    getTheme, getTop100Filter
} from 'utils/cookies';

import usePriceWatcher from 'hooks/usePriceWatcher';


import './Trades.css';


const Trades = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const pageId = 'trades';

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);

    const [appLang] = useState(getLanguage());
    const [appTheme] = useState(getTheme());
    const [appSelectedExchange, setAppSelectedExchange] = useState(getSelectedExchange());
    const [appSelectedCurrency, setAppSelectedCurrency] = useState(getSelectedCurrency());
    const [appSelectedTimeframe, setAppSelectedTimeframe] = useState(getSelectedTimeframe());
    const [appTop100Filter, setAppTop100Filter] = useState(getTop100Filter());

    const [changingFilters, setChangingFilters] = useState(false);

    const [popUpDuration,] = useState(3000);
    const [popUpLevel, setPopUpLevel] = useState('warning');
    const [popUpText, setPopUpText] = useState('-');

    const [userExchanges, setUserExchanges] = useState(getUserConnectedExchanges({}));

    const [tickers, setTickers] = useState({});

    const [isLoadingTickers, setIsLoadingTickers] = useState(true);
    const [isLoadingCandles, setIsLoadingCandles] = useState(true);
    const [isLoadingSignals, setIsLoadingSignals] = useState(true);
    const [isLoadingOrders, setIsLoadingOrders] = useState(true);

    const [candlesData, setCandlesData] = useState([]);
    const [signalsData, setSignalsData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);

    const [exchangesFilter, setExchangesFilter] = useState(['kucoin']);
    const [pairsFilter, setPairsFilter] = useState(['XRP/USDT']);
    const [timeframesFilter, setTimeframesFilter] = useState(['30m']);
    const [sideFilter, setSideFilter] = useState('all');
    const [dateRangeFilter, setDateRangeFilter] = useState([dayjs().startOf('day'), dayjs()]);

    const [timeframeMilliseconds, setTimeframeMilliseconds] = useState(null);

    const [areFiltersValid, setAreFiltersValid] = useState(false);


    // usePriceWatcher(isUserAuthenticated, userExchanges, appSelectedCurrency, appSelectedTimeframe, appTop100Filter, setTickers);


    useEffect(() => {
        if (location.pathname === `/${pageId}`) {
            document.title = `${t('trade.p')} - Smart Trade`;
            fetchEndpoints();
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.remove('bright', 'dark');
        document.body.classList.add(appTheme);
        i18n.changeLanguage(appLang);
    }, [appTheme, appLang]);


    useEffect(() => {

        if (!(appSelectedExchange in tickers)) return;

        let trendsMarketcaps = {};

        if (appTop100Filter) {
            trendsMarketcaps = getTrendsMarketcaps({ asObject: true });
        }

        let tickerList = Object.entries(tickers[appSelectedExchange]).reduce((tickers, [pair, ticker]) => {
            const [base,] = pair.split('/');

            if (base.includes('USDT')) return tickers;

            ticker.symbol = base;

            if (appTop100Filter && trendsMarketcaps) {
                let baseAsset = getAssetBase({ symbol: base });
                if (!(baseAsset.slug in trendsMarketcaps)) return tickers;
            }

            ticker.pnl = ticker.pnls[appSelectedTimeframe][1];

            tickers.push(ticker);

            return tickers;
        }, []);

        if (!tickerList.length) return;
    }, [tickers]);


    useEffect(() => {
        if (!changingFilters) setChangingFilters(true);
    }, [appTop100Filter, appSelectedExchange, appSelectedCurrency, appSelectedTimeframe]);


    useEffect(() => {
        if (!isLoadingTickers) setIsLoadingTickers(changingFilters);
    }, [changingFilters]);


    useEffect(() => {
        setAreFiltersValid(
            exchangesFilter.length === 1 &&
            pairsFilter.length === 1 &&
            timeframesFilter.length === 1 &&
            (dateRangeFilter ?? []).length === 2
        );
    }, [exchangesFilter, pairsFilter, timeframesFilter, sideFilter, dateRangeFilter]);


    useEffect(() => {
        if (timeframesFilter.length == 1) {

            let timeframe = timeframesFilter[0];

            setTimeframeMilliseconds(() => {
                switch (timeframe) {
                    case '30m': return 30 * 60 * 1000;
                    case '1h': return 60 * 60 * 1000;
                    case '4h': return 4 * 60 * 60 * 1000;
                    case '1d': return 24 * 60 * 60 * 1000;
                    default: return 1;
                }
            });
        }
    }, timeframesFilter);

    const fetchEndpoints = () => {
        fetchCandles();
        fetchSignals();
        fetchOrders();
    };


    const fetchCandles = async () => {

        if (dateRangeFilter === null) return;

        setIsLoadingCandles(true);

        const [since, until] = dateRangeFilter;

        try {
            const result = await exchangesOhlcvGet({
                exchange: exchangesFilter[0],
                symbol: pairsFilter[0],
                timeframe: timeframesFilter[0],
                since: since.valueOf(),
                until: until.valueOf(),
            });

            if (result.isSuccess) {
                const ohlcvData = result.response.items;

                setCandlesData(ohlcvData);
            }
        } catch (error) {
            console.error('Error fetching candles:', error);
        }
        finally {
            setIsLoadingCandles(false);
        }
    };


    const fetchSignals = async () => {

        if (dateRangeFilter === null) return;

        setIsLoadingSignals(true);

        const [since, until] = dateRangeFilter;

        const args = {
            exchanges: exchangesFilter,
            pairs: pairsFilter,
            timeframes: timeframesFilter,
            side: sideFilter,
            since: since.valueOf(),
            until: until.valueOf()
        };

        try {
            const result = await signalsUsersMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => ({
                    isAtsOrder: item.is_ats_order,
                    key: index,
                    timestamp: item.timestamp,
                    exchangeSlug: item.exchange_slug,
                    pair: item.pair,
                    side: item.side,
                    price: item.price,
                    timeframe: item.timeframe,
                    pnl: item.pnl
                }));

                setSignalsData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-signals'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('network-error'));
        } finally {
            setIsLoadingSignals(false);
        }
    };


    const fetchOrders = async () => {

        if (dateRangeFilter === null) return;

        setIsLoadingOrders(true);

        const [since, until] = dateRangeFilter;

        const args = {
            exchanges: exchangesFilter,
            pairs: pairsFilter,
            side: sideFilter,
            since: since.valueOf(),
            until: until.valueOf()
        };

        try {
            const result = await ordersMeGet(args);

            if (result.isSuccess) {
                const response = result.response.items.map((item, index) => {

                    let { timestamp, pair, side, price, pnl, status, amount, filled } = item;

                    return {
                        key: index,
                        isAtsOrder: item.is_ats_order,
                        orderId: item.order_id,
                        exchangeSlug: item.exchange_slug,
                        timestamp,
                        pair,
                        side,
                        price,
                        pnl,
                        status,
                        amount,
                        filled
                    };
                });

                setOrdersData(response);
            } else {
                setPopUpLevel('error');
                setPopUpText(t('error.fetch-orders'));
            }
        } catch (error) {
            setPopUpLevel('error');
            setPopUpText(t('network-error'));
        } finally {
            setIsLoadingOrders(false);
        }
    };


    return (
        <Page id={pageId}>
            <Loading id={'overview'} />

            <MessagePopup
                level={popUpLevel}
                text={popUpText}
                duration={popUpDuration}
            />

            <Layout
                checkAuth={true}
                page={pageId}
                quickSettings='full'
                setIsUserAuthenticated={setIsUserAuthenticated}
                setSelectedExchange={setAppSelectedExchange}
                setSelectedCurrency={setAppSelectedCurrency}
                setSelectedTimeframe={setAppSelectedTimeframe}
                setAppTop100Filter={setAppTop100Filter}
                setUserExchanges={setUserExchanges}
                showToolbar={true}
                showHeader={true}
            >
                <Row
                    a='start'
                    className='main-row'
                    fill='all'
                    j='center'
                >
                    <Column
                        fill='all'
                        scroll
                    >
                        <FiltersPanel
                            userExchanges={userExchanges}
                            exchangesFilter={exchangesFilter}
                            setExchangesFilter={setExchangesFilter}
                            pairsFilter={pairsFilter}
                            setPairsFilter={setPairsFilter}
                            timeframesFilter={timeframesFilter}
                            setTimeframesFilter={setTimeframesFilter}
                            sideFilter={sideFilter}
                            setSideFilter={setSideFilter}
                            dateRangeFilter={dateRangeFilter}
                            setDateRangeFilter={setDateRangeFilter}
                            fetchEndpoints={fetchEndpoints}
                        />

                        <PnLPerOperationPanel
                            isUserAuthenticated={isUserAuthenticated}
                            candlesData={candlesData}
                            signalsData={signalsData}
                            ordersData={ordersData}
                            areFiltersValid={areFiltersValid}
                            isLoading={isLoadingCandles}
                            timeframeMilliseconds={timeframeMilliseconds}
                        />

                        <SignalsPanel
                            isUserAuthenticated={isUserAuthenticated}
                            userExchanges={userExchanges}
                            signalsData={signalsData}
                            fetchSignals={fetchSignals}
                            isLoading={isLoadingSignals}
                        />

                        <OrdersPanel
                            isUserAuthenticated={isUserAuthenticated}
                            userExchanges={userExchanges}
                            ordersData={ordersData}
                            fetchOrders={fetchOrders}
                            isLoading={isLoadingOrders}
                        />
                    </Column>
                </Row>
            </Layout>
        </Page>
    );
};

export default Trades;
