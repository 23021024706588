import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Column, Title } from 'components/imports';

import { Select, Card, Switch } from 'antd';

import ImageCrop from '../Cropper/ImageCrop';
import ImageCropProvider from 'providers/ImageCropProvider';

import { PencilSvg, ConfirmSvg } from 'icons/imports';
import { SwitchableIcon } from 'components/imports';


import { usersMeAvatarPatch, usersMePatch, usersMeTelegramPatch } from 'apis/imports';

import { countries } from 'constants';

import './PersonalInfoStep.css';


const PersonalInfoStep = ({
    avatar,
    setAvatar,
    username,
    fullname,
    setFullname,
    country,
    setCountry,
    enableTelegram,
    setEnableTelegram,
    isTelegramVerified,
    setIsTelegramVerified,
    investorProfile,
    setInvestorProfile,
    onContinue,
    hidden,
    setPopUpLevel,
    setPopUpText,
    setPopUpDuration
}) => {
    const { t } = useTranslation();

    const [hotpCode, setHotpCode] = useState();
    const [isVerifyingHOTP, setIsVerifyingHOTP] = useState(false);

    const [editFullname, setEditFullname] = useState(false);

    const [verifyButtonState, setVerifyButtonState] = useState('disabled');
    const [continueButtonState, setContinueButtonState] = useState('disabled');


    useEffect(() => {
        patchAvatar();
    }, [avatar]);


    useEffect(() => {
        const validHOTP = (hotpCode ?? []).filter(x => x != '').length === 6;

        if (isVerifyingHOTP) setVerifyButtonState('loading');
        else if (validHOTP) setVerifyButtonState('enabled');
        else setVerifyButtonState('disabled');
    }, [hotpCode]);


    useEffect(() => {
        if (!enableTelegram) setIsTelegramVerified(false);

        if (!fullname || !country || (enableTelegram && !isTelegramVerified) || !investorProfile)
            setContinueButtonState('disabled');
        else
            setContinueButtonState('enabled');
    }, [fullname, country, enableTelegram, isTelegramVerified, investorProfile]);


    const handleToggleEditFullName = () => setEditFullname(!editFullname);


    const patchAvatar = async () => {
        if (!avatar || !(avatar instanceof File)) return;

        const formData = new FormData();
        formData.append('avatar', avatar);

        const result = await usersMeAvatarPatch({ image: avatar, imageType: avatar.type });

        if (result.isSuccess) {
            setPopUpLevel('success');
            setPopUpText(t('success-avatar-update'));
            setPopUpDuration(2000);
        }
        else {
            setPopUpLevel('error');
            setPopUpText(t('error-avatar-update'));
            setPopUpDuration(3500);
        }
    };


    const handleCountryChange = (value) => setCountry(value);


    const handleContinue = async () => {

        setContinueButtonState('loading');

        if (!fullname || !country || !investorProfile) {
            setContinueButtonState('error');

            setPopUpLevel('warning');
            setPopUpText(t('fill-required-fields'));
            setPopUpDuration(3000);
            return;
        }

        try {
            const result = await usersMePatch({
                body: {
                    fullname,
                    country,
                    investor_profile: investorProfile.toLowerCase()
                }
            });

            if (result.isSuccess) {
                setContinueButtonState('success');

                setPopUpLevel('success');
                setPopUpText(t('information-save-success'));
                setPopUpDuration(2000);

                onContinue();
            } else {
                setContinueButtonState('error');

                setPopUpLevel('error');
                setPopUpText(t('information-save-error'));
                setPopUpDuration(3000);
            }
        } catch (error) {
            setContinueButtonState('error');

            setPopUpLevel('error');
            setPopUpText(t('general-error'));
            setPopUpDuration(3000);
        }
    };


    const handleVerifyHOTP = async (event) => {

        event.preventDefault();

        setIsVerifyingHOTP(true);

        if ((hotpCode ?? []).filter(x => x != '').length == 6) {
            const result = await usersMeTelegramPatch({ hotp: hotpCode.join('') });

            if (result.isSuccess) {
                setIsTelegramVerified(true);
                setVerifyButtonState('success');

                setPopUpLevel('success');
                setPopUpText(t('telegram-verified'));
                setPopUpDuration(3000);
            }

            else {
                setIsTelegramVerified(false);
                setVerifyButtonState('error');

                setPopUpLevel('error');
                setPopUpText(t('telegram-verification-error'));
                setPopUpDuration(3000);
            }
        }

        setIsVerifyingHOTP(false);
    };


    return (
        <>
            <Column
                fill='all'
                className={`personal-info-step ${hidden ? 'hidden' : ''}`}
                g='2'
                m='sec-ver'
                scroll
            >
                <Row fill='width' j='center'>
                    <Title
                        txt={t('personal-information')}
                        variation='primary'
                    />
                </Row>

                <Row
                    fill='width'
                    a='start'
                    g='2'
                    m='pri-hor'
                >
                    <Column
                        className='profile-picture-section'
                        fit='width'
                    >
                        <p className='label b'>{t('profile-picture')}</p>

                        <ImageCropProvider>
                            <ImageCrop
                                avatar={avatar instanceof File ? URL.createObjectURL(avatar) : avatar}
                                setAvatar={setAvatar}
                            />
                        </ImageCropProvider>

                        <p style={{ width: '13rem' }}>{t('profile-pic-label')}</p>
                    </Column>

                    <Column
                        className='form-fields-section'
                        fill='width'
                        g='2'
                    >
                        <Column g='1'>
                            <p className='label b'>{t('full-name')}</p>

                            <Row fill='width' j='between' a='center'>
                                {editFullname &&
                                    <Input
                                        value={fullname}
                                        className='fill-width'
                                        onChange={(e) => setFullname(e.target.value)}
                                        variation='text-minimal'
                                    />}

                                {!editFullname && <p>{fullname}</p>}

                                <SwitchableIcon
                                    iconOff={<PencilSvg className='icon-svg' />}
                                    iconOn={<ConfirmSvg className='icon-svg' />}
                                    isOn={editFullname}
                                    onToggle={handleToggleEditFullName}
                                />
                            </Row>
                        </Column>

                        <Column g='1'>
                            <p className='label b'>{t('country-question')}</p>

                            <Select
                                value={country}
                                onChange={handleCountryChange}
                                defaultValue={['all']}
                                style={{ flex: 1 }}
                                options={countries}
                            />
                        </Column>

                        <Column g='1'>
                            <p className='label b'>{t('telegram')}</p>

                            <Row g='1'>
                                <Switch
                                    checked={enableTelegram}
                                    onChange={(checked) => setEnableTelegram(checked)}
                                />

                                <p>{t('enable-telegram')}</p>
                            </Row>

                            {enableTelegram && !isTelegramVerified &&
                                <>
                                    <p>{t('how-to-validate-telegram').replace('{username}', username)}</p>

                                    <Row>
                                        <Input
                                            code={hotpCode}
                                            id='oauth-input'
                                            setCode={setHotpCode}
                                            variation='mfa-code'
                                        />

                                        <Button
                                            onClick={handleVerifyHOTP}
                                            state={verifyButtonState}
                                        >
                                            {t('verify.v')}
                                        </Button>
                                    </Row>
                                </>}
                        </Column>
                    </Column>
                </Row>

                <Column g='1' m='pri-hor'>
                    <p className='label b'>{t('investor-profile-question')}</p>

                    <Row
                        fill='width'
                        g='1'
                        a='start'
                    >
                        {['conservative', 'moderate', 'aggressive'].map((profile) => (
                            <Card
                                key={profile}
                                className={`investor-profile-card ${investorProfile === profile ? 'selected' : ''}`}
                                onClick={() => setInvestorProfile(profile)}
                            >
                                <h3>{t(profile.toLowerCase())}</h3>
                                <p>{t(`${profile.toLowerCase()}-description`)}</p>
                            </Card>
                        ))}
                    </Row>
                </Column>
            </Column>

            <Row fill='width' j='center'>
                <Button
                    variation='primary'
                    onClick={handleContinue}
                    className='continue-button w-12'
                    state={continueButtonState}
                >
                    {t('continue')}
                </Button>
            </Row>
        </>
    );
};

PersonalInfoStep.propTypes = {
    avatar: PropTypes.object.isRequired || PropTypes.string.isRequired,
    setAvatar: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    fullname: PropTypes.string.isRequired,
    setFullname: PropTypes.func.isRequired,
    country: PropTypes.string.isRequired,
    setCountry: PropTypes.func.isRequired,
    enableTelegram: PropTypes.bool.isRequired,
    setEnableTelegram: PropTypes.func.isRequired,
    isTelegramVerified: PropTypes.bool.isRequired,
    setIsTelegramVerified: PropTypes.func.isRequired,
    investorProfile: PropTypes.string.isRequired,
    setInvestorProfile: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    setPopUpLevel: PropTypes.func,
    setPopUpText: PropTypes.func,
    setPopUpDuration: PropTypes.func
};

export default PersonalInfoStep;